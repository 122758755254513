<template>
  <div class="sign-up contact-form">
    <div class="loader" v-if="!dataLoaded">
      <LoadingPlaceholder :state="pageState" />
    </div>
    <div class="flex" v-else>
      <div class="flex-col-right">
        <div class="register-form" v-if="formContent">
          <div :class="['form-group', (formFields.name.showError ? 'error-field' : '')]">
            <label class="field-label" for="fullname">Name</label>
            <input
              :class="[!formFields.name.showError ? 'tick-icon' : null]"
              type="text"
              v-model="userInfo.first_name"
              name="fullname"
              id="fullname"
              placeholder=" "
              autocomplete="off"
              autofocus
            />
            <p v-if="formFields.name.showError" class="error-msg">{{formFields.name.errorMsg}}</p>
          </div>
          <div :class="['form-group', (formFields.email.showError ? 'error-field' : '')]">
            <label class="field-label" for="workemail">Work Email</label>
            <input
              :class="[!formFields.email.showError ? 'tick-icon' : null]"
              type="email"
              v-model="userInfo.work_email"
              name="workemail"
              id="workemail"
              placeholder=""
              autocomplete="off"
              autofocus
            />
            <p v-if="formFields.email.showError" class="error-msg">{{formFields.email.errorMsg}}</p>
          </div>
          <div :class="['form-group', (formFields.phone.showError ? 'error-field' : '')]">
            <label class="field-label" for="fullname">Work Phone</label>
            <input
              :class="[!formFields.phone.showError ? 'tick-icon' : null]"
              type="tel"
              v-model="userInfo.work_phone"
              name="phone"
              id="phone"
              placeholder=" "
              autocomplete="off"
              autofocus
            />
            <p v-if="formFields.phone.showError" class="error-msg">{{formFields.phone.errorMsg}}</p>
          </div>
          <div :class="['form-group']">
            <label class="field-label" for="fullname">Requirements<span>(Optional)</span></label>
            <textarea
              v-model="userInfo.query"
              name="query"
              id="query"
              placeholder=" "
              autocomplete="off"
              autofocus
            ></textarea>
          </div>
          <div :class="['form-group button-wrapper', (formFields.response.showError ? 'error-field' : '')]">
            <p v-if="formFields.response.showError" class="error-msg">{{formFields.response.errorMsg}}</p>
          </div>
          <button :disabled="showLoader" class="console-btn"
            type="submit" @click="submit()">Contact Sales
            <svg><use v-bind="{'xlink:href':'#arrow-icon'}"></use></svg>
          </button>
        </div>
        <div v-if="success" class="success-field email">
          <svg><use v-bind="{'xlink:href':'#success-illustration'}"></use></svg>
          <p>Your details have been sent to our sales team. One of our representatives will contact you soon</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contact-form {
  margin-top: size(150);
  margin-bottom: size(100);
  background: none;
  font-family: var(--rz-hero-font);
  .console-btn {
    font-size: size(15);
    line-height: size(21);
    width: 100%;
    background: var(--rs-black-color);
    svg {
      width: size(8);
      height: size(8);
      fill: transparent;
      display: inline-block;
    }
  }
  .hero-banner {
    .hero-banner--title {
      margin-top: 0;
      margin-bottom: size(10);
    }
    .hero-banner--desc {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .success-field {
    padding-top: size(80);
    p {
      font-size: size(18);
      line-height: size(24);
    }
  }
  .form-group {
    margin-bottom: size(20);
    &.error-field {
      textarea {
        border-color: var(--onboard-error-color);
      }
    }
    &.button-wrapper {
      margin-bottom: 0;
    }
    label {
      text-transform: uppercase;
      color: var(--rs-black-color);
      font-size: size(13);
      line-height: size(18);
      span {
        color: grey;
        padding-left: size(3);
      }
    }
  }
  .flex-col-right {
    box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
    padding: size(40);
    border-radius: size(6);
    min-height: size(570);
    background: var(--rs-white-color);
    border: size(1) solid rgba(0, 0, 0, 0.08);
    @media screen and (min-width: $breakpoint-md) {
      min-width: size(300);
      max-width: size(300);
    }
    @media screen and (min-width: $breakpoint-lg) {
      min-width: size(450);
      max-width: size(450);
    }
    textarea {
      padding: size(10);
      width: 100%;
      height: size(120);
      box-sizing: border-box;
      font-size: size(16);
      line-height: size(19);
      border: 0;
      border: size(2) solid #E4E8ED;
      resize: none;
      color: var(--ca-title-color);
      font-family: var(--regular-font);
      border-radius: size(3);
      background-color: var(--on-boarding-primary-backgroundColor);
      &:focus {
        outline: none;
        border: size(2) solid var(--rz-link-color);
      }
    }
  }
}
.check-box-group {
  .link-item {
    display: inline-block;
  }
}

.image-area {
  .show-container {
    img {
      max-height: size(435);
    }
  }
}
.form-group {
  display: flex;
  flex-flow: column;
}
</style>

<script>
import { mapState } from 'vuex';
import { WebPage } from '@/store/modules/page';
import { FieldHandler } from '@/core/utils/formModels';
import '@/assets/styles/views/form.scss';
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';

export default {
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
    }),
  },

  components: {
    LoadingPlaceholder,
  },

  data() {
    return {
      errorMessage: null,
      userInfo: {
        first_name: null,
        work_email: null,
        work_phone: null,
        query: null,
      },
      formContent: true,
      success: false,
      showLoader: false,
      formFields: {
        email: new FieldHandler({
          errorMsg: 'Please enter a valid email.',
          // eslint-disable-next-line max-len
          regExp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }),
        name: new FieldHandler({
          errorMsg: 'Please enter a full name.',
        }),
        phone: new FieldHandler({
          errorMsg: 'Please enter a valid phone number.',
        }),
        response: new FieldHandler({
          errorMsg: 'Something went wrong!',
        }),
      },
    };
  },
  methods: {
    submit() {
      if (this.validateForm()) {
        this.userInfo.action = 'request_contact_sales_team';
        WebPage.postContactDetails(this.$apiInstance, this.userInfo)
          .then((resp) => {
            if (resp) {
              this.success = true;
              this.formContent = false;
            } else {
              this.success = false;
              this.formContent = true;
              this.formFields.response.showError = true;
              this.formFields.response.errorMsg = 'Something went wrong.';
              this.showLoader = false;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    validateForm() {
      let count = 0;
      if (!this.formFields.name.validateField(this.userInfo.first_name)) {
        count += 1;
      }
      if (!this.formFields.email.validateField(this.userInfo.work_email)) {
        count += 1;
      }
      if (!this.formFields.phone.validateField(this.userInfo.work_phone)) {
        count += 1;
      }
      return (count === 0);
    },
  },
};
</script>
