<template>
  <div>
    <div class="rs-solution" v-if="pageState.status.isLoaded && page">
      <section v-for="(section, index) in page.sections" :key="index">
        <Banner v-if="section.static_section_id === 'solution_banner'"
         :banner="section" :maxWidth="'max-width-300'" :sectionType="'solution'"></Banner>
        <section
          class="solution-market-growth"
          v-if="section.static_section_id === 'market_growth'"
        >
          <rz-markdown class="prediction-mesg" :text="section.detail.desc"></rz-markdown>
          <rz-image-item v-if="section.detail.img" :image="section.detail.img" />
        </section>
        <section
          class="solution-feature-list"
          v-if="section.static_section_id === 'feature_list'"
        >
          <div class="list-content" v-for="(feature, index) in section.detail.items" :key="index" :class="feature.name">
            <div class="container">
              <h3 class="feature-header">{{ feature.name }}</h3>
              <p class="feature-para">{{ feature.desc }}</p>
              <div class="hero-links" v-if="feature.links.length">
                <div v-for="(link,index) in feature.links" :key="index">
                  <TextButton :buttonType="'rs-button'" :key="index" :link="link" />
                </div>
              </div>
              <div class="image-container" v-if="feature.img">
                <div class="bg-img"></div>
                <rz-image-item class="main-img" :image="feature.img" />
              </div>
            </div>
          </div>
        </section>
        <section class="solution-api-list" v-if="section.static_section_id === 'endpoint_list' &&
         resources && resourceLoaded">
          <div class="flex-box-outer container" v-if="section.detail && section.detail.items">
            <h3 class="endpoint-name" v-if="endpointTitle">{{ endpointTitle }} Endpoints</h3>
            <div class="flex-box-inner">
              <template v-for="(endpoint, i) in section.detail.items">
                <template v-for="(api, index) in resources">
                  <template v-if="api && endpoint && endpoint.name === api.name">
                    <div class="flex-item" :key="index || 'endpoint'+i">
                      <div class="block">
                        <div class="block-left">
                          <router-link
                            class="endpoint-link" v-if="endpoint.name"
                            :to="{name: 'rsResourcePage', params: { api: api.resourceKey.split('_').join('-') }}">
                              {{ endpoint.name }}
                          </router-link>
                          <p v-if="endpoint.desc">{{endpoint.desc}}</p>
                        </div>
                        <div class="block-right">
                          <!-- <div class="pricing-info endpoints-section">
                            <div class="make-bold">
                              <span v-if="api && api.detail && api.detail.MG100 &&
                                api.detail.MG100.price.normal_price.enterprise &&
                                api.detail.MG100.price.normal_price.enterprise[currency] >= 0" :key="key">
                                {{formatCurrency(api.detail.MG100.price.normal_price.enterprise[currency], currency)}}
                              </span>
                            </div>
                            <div class="per-month" v-if="api.starts_at">
                              <span class="tooltip">{{api.starts_at.unit_label}}</span>
                              <span class="tooltip"> with <a href="/v5/pricing">Enterprise Plan</a>
                              </span>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </div>
        </section>
      </section>
      <section class="container pad-100">
        <get-started-section />
      </section>
      <section class="rs-support-block">
          <div class="rs-container">
            <support-wrapper />
          </div>
      </section>
      <section v-for="(section) in page.sections" :key="section.static_section_id" class="discount-banner">
        <section v-if="section.static_section_id == 'discount_banner'">
          <div class="rs-container">
            <ContentImageWrap :section="section.detail" :sectionType="'discount'"
              :buttonType="'rs-button yellow-cta white'" :subText="section.name"
              :infoText="formatCurrency(minAmountForDiscount[currency], currency)"/>
          </div>
        </section>
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
    <div class="content placeholder" v-else>
      <CricketLoadingPlaceholder :state="pageState" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { minAmountForDiscount } from '@/store/modules/page';
import TextButton from '@/components/RZWebsite/TextButton.vue';
import { PayGoResources } from '@/store/modules/paygo';
import GetStartedSection from '@/components/RsCricket/GetStartedSection.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import ContentImageWrap from '@/components/RsCricket/ContentImageWrap.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';

export default {
  name: 'rsCricketSolution',
  components: {
    TextButton,
    GetStartedSection,
    Banner,
    PaymentSignupSection,
    CricketLoadingPlaceholder,
    SupportWrapper,
    ContentImageWrap,
  },
  props: {
    solutionType: String,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
      rsFooterLinks: (state) => state.link.rsFooterLinks,
    }),
  },
  serverPrefetch() {
    return this.fetchData().then(() => {
      return this.fetchResoucePricing();
    });
  },
  data() {
    return {
      resources: null,
      resourceLoaded: false,
      endpointTitle: '',
      minAmountForDiscount,
    };
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.solutionType}-solution`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      this.fetchResoucePricing();
    }
  },
  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  methods: {
    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchResoucePricing();
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.solutionType}-solution`;
        return this.$store.dispatch('page/fetchActivePage', {
          route,
          routeValue,
        });
      });
    },
    fetchResoucePricing() {
      return PayGoResources.getResourcePricing(this.$apiInstance, this.$inMemoryCache)
        .then((resp) => {
          switch (this.solutionType) {
            case 'fantasy':
              this.resources = resp.groupByEndpoints.fantasy;
              this.endpointTitle = 'Fantasy';
              break;
            case 'livescore':
              this.resources = [
                ...resp.groupByEndpoints.association,
                ...resp.groupByEndpoints.tournament,
                ...resp.groupByEndpoints.match,
              ];
              this.endpointTitle = 'Livescore';
              break;
            default:
          }
          this.resourceLoaded = true;
        })
        .catch((err) => {
          console.log('Error on Master Data', err.message);
        });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
  },
};
</script>
<style lang="scss" scoped>
.rs-solution {
  --rs-solution-pad-horizontal: #{size(15)};
  --rs-solution-green-color: #17A48A;
  --rs-solution-orange-color: #E88E62;

  @media screen and (min-width: $breakpoint-md) {
    --rs-solution-pad-horizontal: #{size(15)};
  }

  font-family: var(--rz-hero-font);
  img {
    max-width: 100%;
  }
  .rs-support-block {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(35);
      }
    }
  }
  .discount-banner {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(35);
      }
    }
  }
  .container {
    margin: auto;
    padding: 0 var(--rs-solution-pad-horizontal);
    box-sizing: border-box;
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(1080);
      padding: 0;
    }
  }
  .max-width-1440 {
    max-width: size(1440);
    margin: 0 auto;
    position: relative;
  }
  .flex-box {
    display: flex-box;
  }
  .pad-100 {
    padding-top: size(100);
  }
  .solution {
    &-market-growth {
      padding-left: var(--rs-solution-pad-horizontal);
      padding-right: var(--rs-solution-pad-horizontal);
      padding-bottom: size(45);
      border-bottom: size(1) dashed var(--rs-gray-color);
      text-align: center;
      .prediction-mesg {
        font-size: var(--rs-title-level1-font-size);
        line-height: var(--rs-title-level1-line-height);
        font-weight: 700;
        color: var(--rs-black-color);
        display: block;
        margin: auto;
      }
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(60);
        .prediction-mesg {
          font-size: size(36);
          max-width: size(700);
          line-height: var(--rs-title-level2-line-height);
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-left: unset;
        padding-right: unset;
        .prediction-mesg {
          max-width: size(900);
          font-size: var(--rs-title-level1-font-size);
          line-height: var(--rs-title-level1-line-height);
        }
      }
    }
    &-feature-list {
      .list-content {
        padding: size(55) 0 size(55) 0;
        @media screen and (min-width: $breakpoint-md) {
          padding: size(80) 0;
        }
        &.Intelligent, &.statistical {
          &.scouting, &.questions {
            border-bottom: size(1) dashed var(--rs-gray-color);
          }
        }
        &:not(:last-child) {
          border-bottom: size(1) dashed var(--rs-gray-color);
        }
        &:nth-child(odd) {
          .bg-img {
            background: var(--rs-solution-green-color);
          }
        }
        &:nth-child(even) {
          .bg-img {
            background:  var(--rs-solution-orange-color);
          }
        }
        .image-container {
          position: relative;
          margin-top: size(75);
          // margin-bottom: size(55);
          @media screen and (min-width: $breakpoint-md) {
            margin-top: size(100);
            // margin-bottom: size(80);
          }
          .main-img {
            position: absolute;
            top: size(-30);
            left: 50%;
            transform: translate(-50%, 0);
            width: 85%;
            @media screen and (min-width: $breakpoint-md) {
              width: 90%;
              top: size(-40);
            }
            @media screen and (min-width: $breakpoint-lg) {
              width: size(860);
              top: size(-60);
            }
          }
          .bg-img {
            height: size(220);
            border-radius: size(6);
            @media screen and (min-width: $breakpoint-md) {
              height: size(450);
            }
            @media screen and (min-width: $breakpoint-lg) {
              height: size(560);
            }
          }
        }
      }
      .feature {
        &-header {
          font-size: var(--rs-title-level0-font-size);
          line-height: var(--rs-title-level0-line-height);
          font-weight: 800;
          color: var(--rs-black-color);
          max-width: size(600);
          margin-bottom: size(10);
        }
        &-para {
          font-size: var(--rs-solution-para-font-size);
          line-height: var(--rs-solution-para-line-height);
          color: var(--rs-black-color);
          margin-bottom: size(20);
        }
      }
    }
    &-api-list {
      background: var(--rs-black-color);
      color: var(--rs-white-color);
      padding: size(50) 0;
      @media screen and (min-width: $breakpoint-md) {
        .container {
          max-width: size(700);
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(100) 0 size(85) 0;
        .container {
          max-width: size(1080);
        }
      }
      .flex-box-outer {
        display: flex;
        flex-direction: column;
        @media screen and (min-width: $breakpoint-md) {
          flex-direction: row;
          justify-content: space-between;
        }
        .endpoint-name {
          max-width: size(340);
          margin-right: size(30);
          font-weight: 800;
          color: var(--rs-white-color);
          font-size: size(40);
          line-height: size(54);
          @media screen and (min-width: $breakpoint-lg) {
            font-size: var(--rs-title-level0-font-size);
            line-height: var(--rs-title-level0-line-height);
            margin-top: size(-7);
          }
        }
        .flex-box-inner {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-top: size(30);
          width: 100%;
          @media screen and (min-width: $breakpoint-md) {
            padding-top: size(5);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(510);
            padding-top: size(0);
          }
          .flex-item {
            &:last-child {
              .block {
                border-bottom: none;
                padding-bottom: 0;
              }
            }
            &:nth-child(1) {
              .block {
                padding-top: 0;
              }
            }
            .block {
              padding-bottom: size(40);
              padding-top: size(40);
              display: block;
              // display: flex;
              // flex-direction: column;
              border-bottom: size(1) solid #424242;
              @media screen and (min-width: $breakpoint-md) {
                flex-direction: row;
              }
              &-left {
                @media screen and (min-width: $breakpoint-md) {
                  // padding-right: size(50);
                  width: 100%;
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: 100%;
                }
              }
              &-right {
                padding-top: size(30);
                padding-right: 0;
                @media screen and (min-width: $breakpoint-md) {
                  // padding-right: size(50);
                  width: size(120);
                  margin-left: auto;
                  align-self: start;
                  padding-top: 0;
                }
                .tooltip {
                  font-size: size(12);
                  line-height: size(17);
                }
                .pricing-info {
                  .make-bold, .per-month, .label {
                    min-width: size(130);
                    text-align: left;
                    color: var(--regular-text-inv);
                    @media screen and (min-width: $breakpoint-md) {
                      text-align: right;
                    }
                  }
                  &.endpoints-section {
                    .tooltip {
                      a {
                        color: var(--regular-text-inv);
                        font-weight: 600;
                        text-decoration: underline;
                        &:hover {
                          color: var(--regular-text-inv);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .endpoint-link {
        font-size: var(--rs-title-level4-font-size);
        line-height: var(--rs-title-level4-line-height);
        color: var(--rs-cta-green-color);
        font-weight: bold;
        &.color-blue {
          color: var(--rs-cta-blue-color);
        }
      }
      p {
        font-size: var(--rs-para-level0-font-size);
        line-height: var(--rs-para-level0-line-height);
        padding-top: size(10);
      }
      .pricing-info {
        // padding-top: size(30);
        .label {
          font-size: var(--rs-sm-label-font-size);
          line-height: var(--rs-sm-label-line-height);
          color: var(--rs-gray-color);
        }
      }
      .make-bold {
        font-size: var(--rs-title-level3-font-size);
        line-height: var(--rs-title-level3-line-height);
        font-weight: bold;
      }
    }
  }
}
</style>

<style lang="scss">
.rs-solution {
  .solution-banner {
    .flex-box {
      @media screen and (min-width: $breakpoint-lg) {
        .flex-item.two {
          display: unset;
        }
      }
    }
  }
  .solution-market-growth {
    min-height: size(285);
    .image-item {
      max-width: size(1000);
      margin: auto;
      margin-top: size(40);
      img {
        height: auto;
      }
    }
  }
  .markdown.prediction-mesg {
    span.p {
      margin: auto;
      strong {
        color: #54a757;
      }
    }
  }
  .rs-button {
    .link {
      padding-right: size(16);
      padding-left: size(16);
      @media screen and (min-width: $breakpoint-md) {
        padding-right: size(24);
        padding-left: size(24);
      }
    }
  }
}
.flex-box {
  display: flex;
  flex-wrap: wrap;
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
}
</style>
