<template>
  <!-- eslint-disable max-len -->
  <div id="landing-svg">
    <svg xmlns="http://www.w3.org/2000/svg">
      <symbol id="success-illustration" viewBox="0 0 108 65">
        <circle cx="52.8787" cy="36.9805" r="26.9578" fill="#4F37C8" fill-opacity="0.2"/>
        <circle cx="52.8786" cy="36.9805" r="21.7736" fill="#4F37C8" fill-opacity="0.5"/>
        <circle cx="52.8788" cy="36.9806" r="17.2806" fill="#4F37C8"/>
        <path d="M50.4285 42.5737C50.7255 42.8707 51.2305 42.8707 51.5275 42.5737L60.2596 33.8416C60.5566 33.5446 60.5566 33.0397 60.2596 32.7427L59.1903 31.6734C58.8933 31.3764 58.4181 31.3764 58.1211 31.6734L50.9928 38.8017L47.6366 35.4752C47.3396 35.1782 46.8644 35.1782 46.5674 35.4752L45.4981 36.5444C45.2011 36.8414 45.2011 37.3463 45.4981 37.6434L50.4285 42.5737Z" fill="white"/>
        <path d="M12.4143 26.3635C13.3502 26.1828 14.9546 24.4907 13.8853 19.1674C14.5269 21.2628 16.3995 25.2956 18.7572 24.6639C17.7376 24.9371 16.0385 26.8076 17.3525 32.1016C17.3599 32.1304 17.367 32.1589 17.3739 32.187C17.3667 32.1584 17.3596 32.13 17.3525 32.1016C16.8207 30.0159 15.1287 26.0788 12.4143 26.3635Z" fill="#D4CFF0"/>
        <path d="M0 40.8553C0.950725 40.8952 2.9384 40.1762 3.28332 36.9806C3.3607 38.2722 4.12571 40.8553 6.56664 40.8553C5.51102 40.8553 3.38574 41.6615 3.28477 44.8777C3.28441 44.8953 3.28392 44.9126 3.28332 44.9297C3.28374 44.9123 3.28423 44.895 3.28477 44.8777C3.31091 43.6085 2.69551 41.1074 0 40.8553Z" fill="#9283DC"/>
        <path d="M58.7212 3.52329C59.569 3.95522 61.6757 4.12853 63.3191 1.36625C62.852 2.57288 62.4727 5.24007 64.6923 6.25579C63.7324 5.81653 61.4644 5.66525 60.0342 8.54781C60.0266 8.56362 60.0189 8.57916 60.0113 8.59444C60.0189 8.5788 60.0265 8.56326 60.0342 8.54781C60.5862 7.40452 61.0673 4.87423 58.7212 3.52329Z" fill="#9283DC"/>
        <path d="M20.5986 54.8403C21.4917 55.1686 23.604 55.0909 24.9082 52.1532C24.5876 53.4067 24.5273 56.1 26.8516 56.8454C25.8464 56.523 23.5765 56.6418 22.4982 59.6736C22.4925 59.6902 22.4868 59.7066 22.481 59.7226C22.4867 59.7062 22.4925 59.6899 22.4982 59.6736C22.9107 58.4729 23.0884 55.9035 20.5986 54.8403Z" fill="#D4CFF0"/>
        <path d="M71.8773 60.9261C72.8285 60.9526 74.8059 60.2057 75.1057 57.0055C75.2013 58.2959 76.0026 60.8679 78.4433 60.8336C77.3878 60.8484 75.2741 61.6845 75.2184 64.9019C75.2183 64.9194 75.2181 64.9367 75.2177 64.9538C75.2179 64.9364 75.2181 64.9191 75.2184 64.9019C75.2267 63.6323 74.5761 61.1402 71.8773 60.9261Z" fill="#9283DC"/>
        <path d="M78.7998 15.6255C79.7505 15.6655 81.7382 14.9465 82.0831 11.7509C82.1605 13.0424 82.9255 15.6255 85.3664 15.6255C84.3108 15.6255 82.1855 16.4317 82.0846 19.648C82.0842 19.6655 82.0837 19.6829 82.0831 19.6999C82.0835 19.6826 82.084 19.6652 82.0846 19.648C82.1107 18.3787 81.4953 15.8777 78.7998 15.6255Z" fill="#D4CFF0"/>
        <path d="M85.8458 41.1371C86.3824 41.0131 87.405 40.3831 87.2023 38.8552C87.4055 39.4402 88.153 40.5189 89.518 40.1532C88.9277 40.3113 87.839 41.0022 88.1807 42.5032C88.1826 42.5113 88.1845 42.5194 88.1863 42.5274C88.1844 42.5193 88.1825 42.5112 88.1807 42.5032C88.0382 41.9129 87.3844 40.8497 85.8458 41.1371Z" fill="#4F37C8"/>
        <path d="M24.5529 15.5063C25.0201 15.7981 26.1886 16.0757 27.1257 14.8519C26.8556 15.4093 26.6215 16.7006 27.8453 17.4072C27.3161 17.1016 26.0577 16.8203 25.238 18.1232C25.2336 18.1304 25.2292 18.1374 25.2248 18.1443C25.2292 18.1372 25.2336 18.1302 25.238 18.1232C25.5546 17.6051 25.8441 16.391 24.5529 15.5063Z" fill="#4F37C8"/>
        <path d="M98.1524 24.6985C98.9419 25.2326 101.273 25.1706 104.281 20.6503C103.253 22.5856 101.725 26.7614 103.839 27.9819C102.925 27.4541 100.401 27.5753 97.5868 32.2478C97.5716 32.2734 97.5565 32.2986 97.5415 32.3234C97.5566 32.2981 97.5717 32.2729 97.5868 32.2478C98.6856 30.397 100.273 26.4166 98.1524 24.6985Z" fill="#9283DC"/>
      </symbol>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LandingSvg',
};
</script>

<style scoped lang="scss">
#landing-svg {
  display: none;
}
</style>
