<template>
  <div class="support-wrap-outer" >
    <div class="rs-flex-box">
      <div class="flex-item">
        <h2 class="rs-hero-title">We are here for you!</h2>
        <p>For your questions about pricing, features, integration and demo requests, we’re here to lend a hand.</p>
        <TextButton :link="link" :buttonType="'rs-button yellow-cta'"/>
      </div>
      <div class="support-wrap">
        <support-call-links :showSupportNums="true"/>
      </div>
    </div>
    <div class="image-wrap">
      <!-- <img class="arrow" src="@/assets/rsCricket/support-wrapper-img@3x.png" alt="artwork"/> -->
      <picture>
        <source media="(max-width: 767px)" srcset="@/assets/rsCricket/variations/support_hand.png">
        <source media="(min-width: 768px)" srcset="@/assets/rsCricket/variations/support_hand@2x.png">
        <source media="(min-width: 1194px)" srcset="@/assets/rsCricket/variations/support_hand@3x.png">
        <img class="arrow" src="@/assets/rsCricket/variations/support_hand@3x.png" alt="artwork">
      </picture>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .support-wrap-outer {
    background: var(--rs-dark-blue-color);
    color: var(--rs-white-color);
    padding: size(60) size(20);
    border-radius: size(6);
    .rs-hero-title {
      padding-bottom: size(30);
      color: var(--rs-white-color);
    }
    h3 {
      font-size: var(--rs-title-level0-font-size);
      line-height: var(--rs-title-level0-line-height);
      font-weight: bold;
      color: var(--rs-black-color);
      padding-right: size(30);
    }
    p {
      font-size: var(--rs-title-level4-font-size);
      line-height: var(--rs-title-level4-line-height);
      max-width: size(440);
      padding-bottom: size(20);
    }
    .rs-flex-box {
      justify-content: space-between;
    }
    .support-wrap {
      padding-top: size(30);
      flex: 0 0 100%;
    }
    .image-wrap {
      max-width: size(160);
      max-height: size(220);
      position: absolute;
      top: size(-100);
      right: size(40);
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      .image-wrap {
        max-width: size(240);
        max-height: size(300);
      }
      .flex-item {
        flex: 0 0 56%;
      }
      .support-wrap {
        flex: 0 0 44%;
        padding-top: size(100);
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60);
    }
  }
</style>
<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';
import SupportCallLinks from '@/components/RZWebsite/SupportCallLinks.vue';

export default {
  props: {
  },
  components: {
    TextButton,
    SupportCallLinks,
  },
  data() {
    return {
      link: {
        name: 'Get Started',
        routeParams: { name: 'planReview' },
      },
    };
  },
};
</script>
