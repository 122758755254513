<template>
  <div class="support-links" :class="showSupportNums ? 'show-support-nums' : ''">
    <div v-if="showSupportNums">
      <div class="button link">
        <span class="link-name">
          <span class="icon" :class="listExpand ? 'list-expanded': ''">
            <img src="@/assets/icon-support-phone-white.svg" alt="Phone"/>
          </span>
          <a class="link-text" title="US Tollfree Number" :href="supportNumbers.usTollfree.link">
            {{supportNumbers.usTollfree.label}}</a>
        </span>
      </div>
      <div class="button link">
        <span class="link-name">
          <span class="icon" :class="listExpand ? 'list-expanded': ''">
            <img src="@/assets/icon-support-mail-white.svg" alt="Message"/>
          </span>
          <a class="link-text" title="US Tollfree Number" :href="supportNumbers.supportMail.link">
            {{supportNumbers.supportMail.label}}</a>
        </span>
      </div>
      <div class="button link">
        <span class="link-name">
          <span class="icon" :class="listExpand ? 'list-expanded': ''">
            <svg class="messanger">
              <use v-bind="{'xlink:href':'#icon-messanger'}" />
            </svg>
          </span>
          <a class="link-text" id="my_custom_link" title="US Tollfree Number" href="#">
            Intercom Messenger</a>
        </span>
      </div>
    </div>
    <div class="button link">
      <span class="link-name" @click="listExpand = !listExpand">Local Toll-free Numbers
        <span class="arrow-right" :class="listExpand ? 'list-expanded': ''">
          <img src="@/assets/rz-website/arrow-right.svg" alt="Right Arrow"/>
        </span>
      </span>
      <div class="links-group" v-if="listExpand">
        <a class="link-text" title="US Tollfree Number" :href="supportNumbers.usTollfree.link">
          {{supportNumbers.usTollfree.label}}</a>
        <p class="label">US Toll-free</p>
      </div>
    </div>
    <div class="button link">
      <span class="link-name"
        @click="enterpriseListExpand = !enterpriseListExpand">Enterprise Support
        <span class="arrow-right" :class="enterpriseListExpand ? 'list-expanded': ''">
          <img src="@/assets/rz-website/arrow-right.svg" alt="Right Arrow"/>
        </span>
      </span>
      <div class="links-group" v-if="enterpriseListExpand">
        <p class="detail-text">
          By becoming our Enterprise customer, you are entitled to receive priority support through the
          following channels of communication:</p>
        <ul class="support-list">
          <li>Slack</li>
          <li>JIRA</li>
          <li>Email</li>
          <li>Contact number of Account manager</li>
          <li>Immediate responses during live matches</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.support-links {
  font-family: var(--rz-para-font);
  &.show-support-nums {
    .link-name, .link-text {
      color: var(--rs-white-color);
      font-size: var(--rs-para-font-size);
      line-height: var(--rs-para-line-height);
      font-weight: normal;
    }
    .button {
      border-bottom: 1px solid #6E74C6;
    }
    .detail-text, .label {
      color: var(--rs-white-color);
    }
    .links-group {
      .link-text {
        color: var(--rs-cta-green-color);
      }
    }
    .support-list {
      li {
        color: var(--rs-white-color);
        &:before {
          border-bottom: size(2) solid var(--rs-white-color);
          border-right: size(2) solid var(--rs-white-color);
        }
      }
    }
  }
  .link-name {
    font-size: var(--rz-link-reg-font-size);
    line-height: var(--rz-link-reg-line-height);
    font-weight: bold;
    color: var(--rz-cta-color);
    background: transparent;
    border: none;
    padding: 0;
    padding: size(10) size(20) size(10) 0;
    cursor: pointer;
    display: inline-block;
  }
  .arrow-right {
    padding-left: size(7);
    vertical-align: middle;
    img {
      transition: all .3s ease-in-out;
    }
    &.list-expanded {
      img {
        transform: rotate(90deg);
      }
    }
  }
  .links-group {
    padding-top: size(10);
  }
  .detail-text {
    font-size: size(16);
    line-height: size(18);
    color: var(--rz-brand-type-color);
  }
  .support-list {
    padding-top: size(10);
    li {
      padding: size(6) size(5);
      color: var(--rz-cta-color);
      font-size: size(16);
      &:before {
        display: inline-block;
        transform: rotate(45deg);
        height: size(14);
        content: "";
        width: size(6);
        border-bottom: size(2) solid var(--rz-cta-color);
        border-right: size(2) solid var(--rz-cta-color);
        margin-right: size(10);
      }
    }
  }
  .label {
    font-size: var(--rz-title-small-font-size);
    line-height: var(--rz-title-small-line-height);
    font-weight: normal;
    padding-top: size(5);
    padding-bottom: size(14);
    color: var(--rz-brand-type-color);
    font-family: var(--rz-para-font);
  }
  .link-text {
    font-size: var(--rz-link-reg-font-size);
    line-height: var(--rz-link-reg-line-height);
    color: var(--rz-link-color);
    font-weight: bold;
    display: block;
    font-family: var(--rz-para-font);
    display: inline-block;
    &:hover {
      @include jump-up-animation;
    }
  }
  .icon {
    border: size(1) solid var(--rs-white-color);
    border-radius: size(20);
    display: inline-block;
    margin-right: size(14);
    padding: 0 size(9);
    .messanger {
      width: size(15);
      height: size(14);
    }
  }
}
</style>
<script>
import { supportNumbers } from '@/store/modules/page';

export default {
  name: 'SupportCallLinks',
  props: {
    showSupportNums: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      enterpriseListExpand: false,
      listExpand: false,
      supportNumbers,
    };
  },
};
</script>
