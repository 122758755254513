<template>
  <div class="rs-graphQL" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section class="banner" v-if="section.static_section_id == 'banner'">
          <div class="rs-container fade-in">
            <rz-markdown class="hero-title" v-if="section.detail.desc"
              :text="section.detail.desc"></rz-markdown>
          </div>
        </section>
        <section class="intro-section" v-if="section.static_section_id == 'intro_section'">
          <div class="rs-container">
            <div class="wrapper">
              <div class="content-wrapper">
                <h2 class="animated-content">{{section.detail.name}}</h2>
                <p v-if="section.detail.desc">{{section.detail.desc}}</p>
                <div class="button-wrapper">
                  <TextButton :buttonType="'rs-button'" class="red-btn" :key="section.detail.links[0].name"
                    :link="section.detail.links[0]" />
                  <TextButton :buttonType="'rs-button'" :key="section.detail.links[1].name"
                    :link="section.detail.links[1]" />
                </div>
              </div>
              <div class="image-wrapper">
                <rz-image-item class="banner-artwork"
                  v-if="section.detail.img" :image="section.detail.img" />
              </div>
            </div>
          </div>
        </section>
        <section class="video-section" v-if="section.static_section_id == 'video_section'">
          <div class="rs-container">
            <div class="content-wrapper">
              <h2 class="animated-content">{{section.detail.name}}</h2>
              <p v-if="section.detail.desc">{{section.detail.desc}}</p>
            </div>
            <div class="image-wrapper">
              <rz-image-item
                v-if="section.detail.img" :image="section.detail.img" />
            </div>
          </div>
        </section>
        <section class="summary-section" v-if="section.static_section_id == 'summary_section'">
          <div class="rs-container">
            <h2 class="animated-content">{{section.detail.name}}</h2>
            <div class="wrapper">
              <div class="content-wrapper">
                <p v-if="section.detail.desc">{{section.detail.desc}}</p>
              </div>
              <div class="image-wrapper">
                <rz-image-item class="banner-artwork"
                  v-if="section.detail.img" :image="section.detail.img" />
              </div>
            </div>
          </div>
        </section>
        <section class="features-section" v-if="section.static_section_id == 'features_section'">
          <div class="rs-container">
            <div :class="'features-wrapper-'+index"
              class="wrapper"
              v-for="(item, index) in section.detail.items" :key="index">
              <span></span>
              <h2>{{item.name}}</h2>
              <p>{{item.desc}}</p>
            </div>
          </div>
        </section>
        <section class="code-section" v-if="section.static_section_id == 'code_section'">
          <div class="rs-container">
            <div class="content-wrapper">
              <h2 class="animated-content">{{section.detail.name}}</h2>
              <p>{{section.detail.desc}}</p>
            </div>
          </div>
        </section>
      </section>
    </div>
    <!-- <div class="rs-container" id="code-container">
      <div class="code-wrapper">
        <div class="pricing-div">
          <h3>See how our pricing works</h3>
          <table>
            <thead>
              <tr>
                <th>Queries</th>
                <th>MG 100</th>
                <th>MG 101</th>
                <th>Historical Data</th>
              </tr>
            </thead>
            <tbody>
              <tr :class="index === activeIndex ? 'active': ''"
                v-for="(info, index) in resources" :key="index"
                @click="changeCode(index, info.name)"
                @mouseover="changeCode(index, info.name)"
              >
                <template v-if="info.name === 'Featured Matches' || info.name === 'Match'
                 || info.name === 'Match Odds'">
                  <td>
                    <span>{{info.name}}</span>
                    <span class="unit-info">Unit: {{info.starts_at.unit_label}}</span>
                  </td>
                  <template class="rs-sm-para bold" v-for="key in resourcePricingTypes">
                    <td v-if="key && info.detail[key] && info.detail[key].price
                    && info.detail[key].price.normal_price && info.detail[key].price.normal_price.enterprise"
                    :key="key" class="price">
                      <span>
                        {{
                          formatCurrencyWithDiscount(
                            info.detail[key].price.normal_price.enterprise[currency], currency, 25)}}
                      </span>
                      <span class="inline strike-price"
                        v-if="info.detail[key].price.normal_price.enterprise &&
                        info.detail[key].price.normal_price.enterprise[currency]">
                        {{formatCurrency(info.detail[key].price.normal_price.enterprise[currency], currency)}}
                      </span>
                    </td>
                    <td v-else :key="key">-</td>
                  </template>
                  <td
                    v-if="info.detail && info.detail.MG100 && info.detail.MG100.price">
                    <span v-if="info.detail.MG100.price.price_variations.length">
                      <span v-for="varaition in info.detail.MG100.price.price_variations"
                        :key="varaition.variation_factor">
                        x{{varaition.variation_factor}}
                      </span>
                    </span>
                    <span v-else>-</span>
                  </td>
                  <td v-else>-</td>
                </template>
              </tr>
            </tbody>
          </table>
          <div class="text-button rs-button">
            <a href="/v5/graphql/pricing" class="link">
              <span class="link-text">See Pricing</span>
            </a>
          </div>
        </div>
        <div class="code-div">
          <code>
            <div class="first-step" id="first-step" @scroll="hide()">
              <p>query <span class="brace">{</span></p>
              <div class="inner-content">
                <p id="highlight"></p>
                <p id="feature-matches"> featured_matches
                <span class="brace">{</span></p>
                <div class="second-step">
                  <div class="inner-content">
                    <p>match <span class="brace">{</span></p>
                    <div class="third-step">
                      <div class="inner-content">
                        <p>key</p>
                      </div>
                    </div>
                    <p><span class="brace">}</span></p>
                  </div>
                </div>
                <p><span class="brace">}</span></p>
                <p id="match">match(<span class="key">key:
                  </span> <span class="value">"bblt20_2021_g16"</span>)
                  <span class="brace">{</span>
                </p>
                <div class="second-step">
                  <div class="inner-content">
                    <p>key</p>
                    <p>name</p>
                    <p>status</p>
                    <p>venue <span class="brace">{</span></p>
                    <div class="third-step">
                      <div class="inner-content">
                        <p>name</p>
                      </div>
                    </div>
                    <p><span class="brace">}</span></p>
                    <p id="match-odds">odds <span class="brace">{</span></p>
                    <div class="third-step">
                      <div class="inner-content">
                        <p>match <span class="brace">{</span></p>
                        <div class="fourth-step">
                          <div class="inner-content">
                            <p>resultPrediction <span class="brace">{</span></p>
                            <div class="fifth-step">
                              <div class="inner-content">
                                <div class="sixth-step">
                                  <div class="inner-content">
                                    <p>automatic <span class="brace">{</span></p>
                                    <div class="seventh-step">
                                      <div class="inner-content">
                                        <p>percentage <span class="brace">{</span></p>
                                        <div class="eighth-step">
                                          <div class="inner-content">
                                            <p>team <span class="brace">{</span></p>
                                            <div class="ninth-step">
                                              <div class="inner-content">
                                                <p>name</p>
                                              </div>
                                            </div>
                                            <p><span class="brace">}</span></p>
                                            <p>value</p>
                                          </div>
                                        </div>
                                        <p><span class="brace">}</span></p>
                                      </div>
                                    </div>
                                    <p><span class="brace">}</span></p>
                                  </div>
                                </div>
                                <p><span class="brace">}</span></p>
                              </div>
                            </div>
                            <p><span class="brace">}</span></p>
                          </div>
                        </div>
                        <p><span class="brace">}</span></p>
                      </div>
                    </div>
                    <p><span class="brace">}</span></p>
                  </div>
                </div>
                <p><span class="brace">}</span></p>
              </div>
              <p><span class="brace">}</span></p>
            </div>
          </code>
        </div>
      </div>
    </div> -->
    <div v-if="page.sections && page.sections.length">
      <template v-for="(section, index) in page.sections">
        <section class="explore-section" v-if="section.static_section_id == 'explore_section'" :key="index">
          <div class="rs-container">
            <div class="wrapper">
              <div class="content-wrapper">
                <h2 class="animated-content">{{section.detail.name}}</h2>
                <p v-if="section.detail.desc">{{section.detail.desc}}</p>
                <div class="button-wrapper">
                <TextButton :buttonType="'rs-button'" class="red-btn" :key="section.detail.links[0].name"
                  :link="section.detail.links[0]" />
                <TextButton :buttonType="'rs-button'" :key="section.detail.links[1].name"
                  :link="section.detail.links[1]" />
                </div>
              </div>
              <div class="image-wrapper">
                <rz-image-item class="banner-artwork"
                v-if="section.detail.img" :image="section.detail.img" />
              </div>
            </div>
          </div>
        </section>
        <section class="tech-process-wrap" v-if="section.static_section_id == 'tech_process'" :key="index">
          <div class="rs-container">
            <h2 class="hero-title  max-width-670 animated-content">{{section.name}}</h2>
            <div class="rs-flex-box">
              <div class="flex-item" v-for="section in section.detail.items" :key="section.name">
                <content-section
                  :section="section"
                  :showButton="false"
                  :sectionType="'tech-process-wrap rs-page'"/>
              </div>
            </div>
          </div>
        </section>
        <section class="rs-support-block" v-if="section.static_section_id == 'support_block'" :key="index">
          <div class="rs-container">
            <support-wrapper />
          </div>
        </section>
        <PaymentSignupSection
          v-if="section.static_section_id == 'signup_wrapper'"
          :signupInfo="section"
          :key="index"
        />
      </template>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { PayGoResources } from '@/store/modules/paygo';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import ContentSection from '@/components/RZWebsite/ContentSection.vue';
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  name: 'rsGraphQL',
  components: {
    CricketLoadingPlaceholder,
    SupportWrapper,
    PaymentSignupSection,
    ContentSection,
    TextButton,
  },
  data() {
    return {
      resources: null,
      resourcePricingTypes: ['MG100', 'MG101'],
      pricing: [
        {
          endpoints: 'Match',
          unit: 'Per Match Per Month',
          price: 'US$1.5',
        },
        {
          endpoints: 'Feature Matches',
          unit: 'Per Month',
          price: 'US$10',
        },
        {
          endpoints: 'Match Odds',
          unit: 'Per Match Per Month',
          price: 'US$1.5',
        },
      ],
      activeIndex: 0,
    };
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rsFooterLinks: (state) => state.link.rsFooterLinks,
    }),
  },
  serverPrefetch() {
    return this.fetchData().then(() => {
      return this.fetchResoucePricing();
    });
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
      this.scrollAnimation();
      this.changeCode(0, 'Match');
    }
    if (!this.dataLoaded) {
      this.freshFetch();
      this.scrollAnimation();
      this.changeCode(0, 'Match');
    } else {
      this.fetchResoucePricing();
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchResoucePricing();
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    fetchResoucePricing() {
      return PayGoResources.getGraphqlResourcePricing(this.$apiInstance, this.$inMemoryCache).then((resp) => {
        this.resources = resp.groupByEndpoints.match;
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
      });
    },
    scrollAnimation() {
      const desktopWidth = window.matchMedia('(min-width: 1194px)');
      const tabWidth = window.matchMedia('(min-width: 768px)');
      let elementVisible = null;
      document.addEventListener('scroll', () => {
        const content = document.querySelectorAll('.animated-content');
        for (let i = 0; i < content.length; i += 1) {
          const windowHeight = window.innerHeight;
          const elementTop = content[i].getBoundingClientRect().top;
          if (desktopWidth.matches) {
            elementVisible = 100;
          } else if (tabWidth.matches) {
            elementVisible = 80;
          } else {
            elementVisible = 75;
          }
          if (elementTop < windowHeight - elementVisible) {
            this.addClass(content[i], 'fade-in');
          }
        }
      });
    },
    addClass(element, className) {
      const arrayClasses = element.className.split(' ');
      if (arrayClasses.indexOf(className) === -1) {
        element.className += ` ${className}`;
      }
    },
    changeCode(index, endpoint) {
      const val = document.getElementById('highlight');
      val.style.display = 'block';
      const code = document.getElementById('first-step');
      code.scrollTo(0, 0);
      if (endpoint === 'Featured Matches') {
        val.style.transform = 'translate(-15px, 0)';
      } else if (endpoint === 'Match') {
        val.style.transform = 'translate(-15px, 130px)';
      } else if (endpoint === 'Match Odds') {
        val.style.transform = 'translate(-15px, 303px)';
      }
      this.activeIndex = index;
    },
    hide() {
      const val = document.getElementById('highlight');
      val.style.display = 'none';
      this.activeIndex = null;
    },
  },
};
</script>
<style lang="scss">
.rs-graphQL {
  .animated-content {
    opacity: 0;
    transition: opacity 1s cubic-bezier(0.390, 0.575, 0.565, 1.000);
  }
  .fade-in {
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    transition: transform 1s cubic-bezier(0.390, 0.575, 0.565, 1.000),
     opacity 1s cubic-bezier(0.390, 0.575, 0.565, 1.000);
  }

  @-webkit-keyframes fade-in {
    from {
      -webkit-transform: translateY(size(35));
      transform: translateY(size(35));
      opacity: 0;
    }
    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in {
    from {
      -webkit-transform: translateY(size(35));
      transform: translateY(size(35));
      opacity: 0;
    }
    to {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  .banner {
    background: var(--rs-black-color);
    .rs-container {
      padding: size(50) size(20);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(100) size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0;
      }
      .hero-title {
        color: var(--rs-white-color);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(60);
          line-height: size(76);
        }
        @media screen and (min-width: $breakpoint-lg) {
          font-size: var(--rs-title-hero-font-size);
          line-height: var(--rs-title-hero-line-height);
        }
        .p {
          max-width: 100%;
        }
        em {
          &:nth-child(1) {
            color: #A695FF;
          }
          &:nth-child(2) {
            color: #FF8282;
          }
          &:nth-child(3) {
            color: #58CFDB;
          }
          &:nth-child(4) {
            color: #F7C64B;
          }
          &:nth-child(5) {
            color: #4F97F1;
          }
          &:nth-child(6) {
            color: #F5FF78;
          }
        }
      }
    }
  }
  .text-button {
    .link {
      font-size: size(15);
    }
    &.red-btn {
      .link {
        background: #BE3146;
        color: var(--rs-white-color);
        border: size(1) solid #BE3146;
        margin-top: size(20);
        margin-right: size(18);
        &:after {
          border-color: var(--rs-white-color);
        }
      }
    }
  }
  .video-section {
    background: #046C62;
    .rs-container {
      padding: size(50) size(20);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(120) 0;
      }
      .content-wrapper {
        display: inline-block;
        vertical-align: top;
        h2 {
          @media screen and (min-width: $breakpoint-lg) {
            font-size: 80px;
            line-height: 90px;
          }
        }
        @media screen and (min-width: $breakpoint-md) {
          max-width: size(365);
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(470);
        }
        p {
          font-size: size(18);
          line-height: size(28);
          color: var(--rs-white-color);
        }
      }
      .image-wrapper {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding-top: size(30);
        @media screen and (min-width: $breakpoint-md) {
          width: 50%;
          padding-left: size(25);
          padding-top: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          padding-left: size(40);
        }
        img {
          max-height: size(335);
          @media screen and (min-width: $breakpoint-md) {
            max-height: size(500);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-left: size(40);
            max-height: size(714);
          }
        }
      }
    }
  }
  .tech-process-wrap {
    .rs-container {
      h2 {
        &.hero-title {
          color: var(--rs-black-color);
          @media screen and (min-width: $breakpoint-md) {
            max-width: size(400);
            padding-bottom: size(30);
          }
          @media screen and (min-width: $breakpoint-lg) {
            max-width: size(670);
            padding-bottom: size(40);
          }
        }
      }
      .title {
        span {
          font-size: size(20);
          line-height: size(24);
          @media screen and (min-width: $breakpoint-md) {
            font-size: size(24);
            line-height: size(28);
          }
          @media screen and (min-width: $breakpoint-lg) {
            font-size: size(30);
            line-height: size(42);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
body {
  position: relative;
}
.rs-graphQL {
  font-family: var(--rz-hero-font);
  h2 {
    font-size: size(26);
    line-height: size(30);
    font-weight: 800;
    padding: 0 0 size(20);
    max-width: 100%;
    color: var(--rs-white-color);
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(30);
      line-height: size(42);
    }
    @media screen and (min-width: $breakpoint-lg) {
      font-size: size(60);
      line-height: size(74);
    }
  }
  #code-container {
    background: var(--rs-white-color);
    transition: all 0.8s linear;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(50) 0;
    }
  }
  .code-wrapper {
    .pricing-div {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(580);
      }
      h3 {
        font-weight: bold;
        font-size: size(18);
        line-height: size(25);
        padding: 0 0 size(20);
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          padding: size(45) 0 size(40);
          display: block;
        }
      }
      table {
        width: 100%;
        display: none;
        @media screen and (min-width: $breakpoint-lg) {
          display: table;
          margin-bottom: size(26);
        }
        thead {
          tr {
            th {
              font-weight: bold;
              font-size: size(15);
              line-height: size(21);
              padding: size(15) 0;
              padding-right: size(10);
              border-bottom: size(1) solid #DADADA;
              text-align: left;
              &:first-child {
                padding-right: 0;
                padding-left: size(10);
              }
              &.last-child {
                padding-right: 0;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: size(15);
              line-height: size(21);
              padding: size(11) 0;
              border-bottom: size(1) solid #DADADA;
              cursor: pointer;
              text-align: left;
              transition: color 0.5s linear, border-color 0.5s linear;
              span {
                display: inline-block;
                vertical-align: top;
                padding: size(5);
                padding-right: size(20);
              }
              &:first-child {
                width: size(135);
                padding-right: 0;
                @media screen and (min-width: $breakpoint-md) {
                  width: size(180);
                }
                span {
                  border-left: size(2) solid transparent;
                  padding-left: size(10);
                  font-weight: 500;
                  &.unit-info {
                    font-size: size(11);
                    line-height: size(15);
                    font-weight: normal;
                    color: gray;
                    padding-top: 0;
                  }
                }
              }
              &.price {
                padding-right: size(10);
                span {
                  padding: size(5);
                  &.strike-price {
                    font-size: size(12);
                  }
                }
              }
            }
            &.active {
              td {
                span {
                  color: var(--rz-link-color);
                  &.unit-info {
                    color: var(--rz-link-color);
                  }
                }
                &:first-child {
                  span {
                    border-left: size(2) solid var(--rz-link-color);
                  }
                }
              }
            }
          }
        }
      }
    }
    .code-div {
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        width: size(380);
        margin-left: size(90);
        display: inline-block;
        vertical-align: top;
        position: relative;
        z-index: 1;
        padding: size(45) size(10);
      }
      code {
        background: #23313E;
        border-radius: size(6);
        padding: size(20);
        font-size: size(12);
        line-height: size(15);
        display: block;
        color: var(--rs-white-color);
        position: relative;
        z-index: 3;
        transition: transform 0.8s linear, display 0.5s linear;
        span {
          display: inline-block;
          padding: size(2) 0;
          &.brace {
            color: #4F97F1;
          }
          &.value {
            color: #A695FF;
          }
          &.key {
            color: #F5FF78;
          }
        }
        .first-step {
          height: size(460);
          overflow-y: scroll;
          scrollbar-width: none;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            display: none;
          }
          scroll-behavior: smooth;
        }
        .inner-content {
          padding-left: size(12);
        }
        p {
          padding: size(4) 0;
        }
      }
      #highlight {
        padding: size(15);
        background: var(--rz-link-color);
        color: var(--rs-white-color);
        border-radius: size(3);
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
        width: 100%;
        will-change: transform;
        transform: translate(-#{size(15)}, 0);
        transition: transform 0.5s ease;
      }
    }
  }
  .intro-section {
    position: relative;
    .rs-container {
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0 0;
      }
      .wrapper {
        position: relative;
      }
      .content-wrapper {
        max-width: size(620);
        padding-bottom: size(230);
        @media screen and (min-width: $breakpoint-md) {
          padding-bottom: size(345);
        }
        h2 {
          padding: 0 0 size(20);
          max-width: 100%;
          color: var(--rs-black-color);
        }
        p {
          font-size: var(--rz-para-hero-font-size);
          line-height: var(--rz-para-hero-line-height);
        }
      }
      .image-wrapper {
        position: absolute;
        bottom: size(-45);
        right: size(20);
        z-index: -1;
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          right: size(-20);
          height: size(350);
          bottom: size(-25);
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(450);
          bottom: size(-27);
        }
        img {
          width: size(400);
          @media screen and (min-width: $breakpoint-md) {
            width: size(550);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(900);
          }
        }
      }
    }
  }
  .summary-section {
    .rs-container {
      padding: size(50) size(20);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0;
      }
      h2 {
        padding: 0 0 size(20);
        max-width: size(600);
        color: var(--rs-black-color);
      }
      .content-wrapper {
        display: inline-block;
        vertical-align: top;
        @media screen and (min-width: $breakpoint-md) {
          max-width: 50%;
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(480);
        }
        p {
          font-size: size(18);
          line-height: size(28);
        }
      }
      .image-wrapper {
        display: inline-block;
        vertical-align: top;
        margin-top: size(20);
        height: size(225);
        @media screen and (min-width: $breakpoint-md) {
          width: 40%;
          margin-left: size(20);
          margin-top: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(407);
          width: 50%;
        }
        img {
          @media screen and (min-width: $breakpoint-lg) {
            width: size(538);
          }
        }
      }
    }
  }
  .features-section {
    background: #FEC834;
    .rs-container {
      padding: size(50) size(20);
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(120) 0;
      }
      .wrapper {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(520);
        }
        span {
          display: inline-block;
          vertical-align: top;
          width: size(40);
          height: size(40);
          background: url('../../assets/features-1.svg') no-repeat;
          margin-bottom: size(10);
        }
        h2 {
          font-weight: bold;
          font-size: size(26);
          line-height: size(30);
          padding-bottom: size(10);
          padding-right: 0;
          color: var(--rs-black-color);
          @media screen and (min-width: $breakpoint-md) {
            font-size: size(30);
            line-height: size(42);
          }
        }
        p {
          font-size: size(18);
          line-height: size(28);
        }
        &.features-wrapper-1 {
          margin-top: size(40);
          @media screen and (min-width: $breakpoint-lg) {
            margin-left: size(40);
            margin-top: 0;
          }
          span {
            background: url('../../assets/features-2.svg') no-repeat;
          }
        }
      }
    }
  }
  .code-section {
    position: relative;
    .rs-container {
      position: relative;
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0 0;
      }
      .content-wrapper {
        max-width: size(600);
        h2 {
          padding: 0 0 size(20);
          color: var(--rs-black-color);
        }
        p {
          font-size: var(--rz-para-hero-font-size);
          line-height: var(--rz-para-hero-line-height);
        }
      }
    }
  }
  .explore-section {
    .rs-container {
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0 0;
      }
      .content-wrapper {
        display: inline-block;
        vertical-align: top;
        @media screen and (min-width: $breakpoint-md) {
          max-width: 50%;
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(620);
        }
        h2 {
          padding: 0 0 size(20);
          max-width: size(600);
          color: var(--rs-black-color);
        }
      }
      .image-wrapper {
        display: inline-block;
        vertical-align: top;
        margin-top: size(20);
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          width: 47%;
          margin-left: size(20);
          margin-top: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          height: size(480);
          width: 42%;
        }
        img {
          @media screen and (min-width: $breakpoint-md) {
            width: 47%;
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(429);
          }
        }
      }
    }
  }
  .tech-process-wrap {
    .rs-container {
      padding: size(50) size(20) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(80) size(20) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding: size(180) 0 0;
      }
      .rs-flex-box {
        display: flex;
        flex-wrap: wrap;
      }
      .flex-item {
        padding-right: size(30);
        padding-bottom: size(40);
        @media screen and (min-width: $breakpoint-md) {
          flex: 0 0 45%;
        }
        @media screen and (min-width: $breakpoint-lg) {
          flex: 0 0 30%;
        }
        h1 {
          font-weight: bold;
          font-size: size(30);
          line-height: size(42);
          padding-bottom: size(10);
        }
        p {
          font-size: size(18);
          line-height: size(25);
        }
      }
    }
  }
}
</style>
