var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article-list-two-col"},[_c('div',{staticClass:"max-width-1080"},[_c('div',{staticClass:"flex-parent",class:_vm.type},[_c('div',{staticClass:"flex-child one"},[_c('div',{staticClass:"hash-tag"},[_vm._v(_vm._s(_vm.article.category))]),_c('router-link',{attrs:{"to":{
              name: 'rsArticleDetailView',
              params: { projKey: _vm.$route.params.projectKey,
              articleid: _vm.article.route_value.replace('rs-cricket-', '')}
            }}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.article.card_title))])]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.article.card_desc))]),_c('div',{staticClass:"article-date"},[_vm._v(_vm._s(_vm.formatBlogPublicationDate(_vm.article.publication_date)))]),(_vm.article.author_keys)?_c('div',{staticClass:"author-container"},_vm._l((_vm.article.author_keys),function(author,index){return _c('div',{key:index,staticClass:"author",class:'author-'+index},[_c('img',{staticClass:"author-pic",attrs:{"src":require(("@/assets/blog-" + (author.value) + ".png")),"alt":"author image"}}),_c('p',{staticClass:"author-name"},[_vm._v(_vm._s(author.key.replace('_',' ')))])])}),0):_vm._e()],1),_c('div',{staticClass:"flex-child two"},[_c('router-link',{attrs:{"to":{
              name: 'rsArticleDetailView',
              params: { projKey: _vm.$route.params.projectKey,
              articleid: _vm.article.route_value.replace('rs-cricket-', '')}
            }}},[(_vm.article.card_img)?_c('rz-image-item',{attrs:{"image":_vm.article.card_img}}):_c('div',{staticClass:"image-item"},[_c('img',{staticClass:"image-item",attrs:{"src":require("@/assets/blog-placeholder.png"),"alt":"gallery"}})])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }