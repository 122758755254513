<template>
  <section class="blog-committed-usage"
    v-if="committedUsageInfo && committedUseItems">
    <div class="rs-container">
      <div class="rs-flex-box align-center">
        <div>
          <div class="save-info flex-parent">
            <router-link
              class="flex-child"
              :class="'blk-' + index"
              v-for="(item, index) in committedUseItems"
              :key="index"
              :to="{name: 'planReview'}"
            >
              <div
                v-if="committedUsageInfo.detail && committedUsageInfo.detail.links
                && committedUsageInfo.detail.links.length"
                :link="committedUsageInfo.detail.links[index]">
                <div class="save-text">Save</div>
                <div class="save-price-info">
                  {{
                  formatCurrency(
                  item.price.extra_credits_amount[currency],
                  currency)
                  }}
                </div>
                <div class="flex-box">
                  <div class="flex-item">
                    <div class="pay-text">Pay</div>
                    <div class="pay-price-info">
                      {{
                      formatCurrency(
                      item.price.unit_amount[currency],
                      currency)
                      }}
                    </div>
                  </div>
                  <div class="flex-item">
                    <div class="get-text">Get</div>
                    <div class="get-price-info">
                      {{
                      formatCurrency((
                      item.price.unit_amount[currency] +
                      item.price.extra_credits_amount[currency]),
                      currency
                      )
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <p class="link-text-block">
        <router-link :to="{name: 'planReview'}">
          Sign Up</router-link> for Committed-use and avail discount now.
      </p>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.blog-committed-usage {
  .rs-flex-box {
    display: block;
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
    }
  }
  .link-text-block {
    padding-top: size(50);
    padding-bottom: 0;
    a {
      text-decoration: underline;
      &:hover {
        color: var(--rs-black-color);
      }
    }
  }
  .flex-box {
    display: flex;
    flex-basis: none;
    .flex-item {
      flex-basis: 0;
      border-bottom: none;
      padding-top: 0;
      padding-bottom: 0;
      &:nth-child(odd) {
        padding-right: size(35);
      }
    }
  }
  .save-info.flex-parent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: calc(100% - #{size(20)});
    margin: auto;
    color: var(--rs-black-color);
    position: relative;
    z-index: 100;
    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: center;
    }
    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
      justify-content: space-between;
      max-width: unset;
      padding: 0 size(10);
    }
    .ellipse-bg {
      position: absolute;
      z-index: -100;
      &.red {
        visibility: hidden;
        @media screen and (min-width: $breakpoint-md) {
          visibility: unset;
          top: size(-53);
          right: size(-16);
        }
        @media screen and (min-width: $breakpoint-lg) {
          top: size(-37);
          right: size(190);
        }
      }
      &.blue {
        visibility: hidden;
        @media screen and (min-width: $breakpoint-md) {
          visibility: unset;
          top: size(300);
          left: size(-30);
        }
        @media screen and (min-width: $breakpoint-lg) {
          top: size(100);
          left: size(134);
        }
      }
    }
    .flex-child {
      // flex: 0 0 80%;
      background-size: size(55) size(60);
      background-repeat: no-repeat;
      background-position: 105% 10%;
      box-shadow: rgba(17, 17, 26, 0.1) size(0) size(4) size(16), rgba(17, 17, 26, 0.05) size(0) size(8) size(32);
      border: size(1) solid #dddddd;
      border-radius: size(6);
      padding: size(20);
      position: relative;
      z-index: 200;
      background-color: var(--rs-white-color);
      transition: box-shadow .4s;
      box-sizing: border-box;
      margin: 0;
      &:not(:first-child) {
        margin-top: size(25);
        @media screen and (min-width: $breakpoint-md) {
          margin-top: 0;
        }
      }
      &:hover {
        .save-price-info, .get-price-info, .save-text, .pay-text, .get-text {
          color: var(--regular-text);
        }
      }
      @media screen and (min-width: $breakpoint-md) {
        flex: 0 0 48%;
        // max-width: size(240);
        margin-top: 0;
        &:nth-of-type(3),
        &:nth-of-type(4) {
          margin-top: size(22);
        }
        &:nth-child(odd) {
          margin-right: size(22);
        }
      }
      @media screen and (min-width: $breakpoint-lg) {
        flex: 0 0 50%;
        max-width: size(280);
        margin-top: 0;
        margin-bottom: size(22);
        &:nth-of-type(3),
        &:nth-of-type(4) {
          margin-top: size(0);
          margin-bottom: 0;
        }
        &:nth-child(odd) {
          margin-right: size(0);
        }
      }
      &.blk-0 {
        background-image: url('../../assets/rsCricket/yellowSqr.svg');
      }
      &.blk-1 {
        background-image: url('../../assets/rsCricket/lightBlueSqr.svg');
      }
      &.blk-2 {
        background-image: url('../../assets/rsCricket/darkBlueSqr.svg');
      }
      &.blk-3 {
        background-image: url('../../assets/rsCricket/redSqr.svg');
      }
      .save-text {
        font-size: var(--rs-title-level5-font-size);
        line-height: var(--rs-title-level5-line-height);
        margin-bottom: size(2);
      }
      .save-price-info {
        margin-bottom: size(20);
        font-size: var(--rs-title-level3-font-size);
        line-height: var(--rs-title-level3-line-height);
        font-weight: bold;
      }
      .get-text, .pay-text {
        font-size: var(--rs-sm-label-font-size);
        line-height: var(--rs-sm-label-line-height);
        margin-bottom: size(2);
      }
      .get-price-info {
        font-size: var(--rs-title-level5-font-size);
        line-height: var(--rs-title-level5-line-height);
        font-weight: bold;
      }
      .pay-price-info {
        color: var(--rs-cta-blue-color);
        font-size: var(--rs-title-level5-font-size);
        line-height: var(--rs-title-level5-line-height);
        font-weight: bold;
      }
      .flex-item {
        margin-right: 0;
      }
    }
  }
}
</style>
<style lang="scss">
.committed-usage {
  color: var(--rs-black-color);
  .usage-title {
    font-size: var(--rs-title-level0-font-size);
    line-height: var(--rs-title-level0-line-height);
    font-weight: 800;
    margin-bottom: size(20);
    display: inline-block;
    max-width: size(570);
    strong {
      color: var(--rs-lavender-color);;
    }
  }
  .usage-detail-text {
    font-size: var(--rs-para-font-size);
    line-height: var(--rs-para-line-height);
    margin-bottom: size(60);
    display: inline-block;
    max-width: size(570);
    strong {
      font-weight: bold;
    }
  }
  .rs-flex-box {
    .flex-child {
      a {
        color: var(--rs-black-color);
        &:hover {
          color: var(--rs-black-color);
        }
      }
    }
  }
}
</style>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    committedUsageInfo: {
      type: Object,
      default: null,
    },
    committedUseItems: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapState({
      currency: (state) => state.user.activeCurrency,
    }),
  },
};
</script>
