<template>
  <div class="pricing-api-list">
    <div class="flex-box-outer rs-container max-width-1080">
      <h3 class="endpoint-name">{{explorePageInfo.name}}</h3>
      <div class="flex-box-inner" v-if="explorePageInfo.detail && explorePageInfo.detail.items">
        <div class="flex-item" v-for="(item,index) in explorePageInfo.detail.items" :key="index">
          <content-section
            :section="item"
            :buttonType="'rs-button white-cta no-space'"
            :sectionType="'tech-process-wrap rs-page max-width-600 white'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.pricing-api-list {
  .rs-container {
    max-width: size(880);
    padding-bottom: 0;
    &.max-width-1080 {
      max-width: size(1080);
    }
  }
  font-family: var(--rz-hero-font);
  background: var(--rs-black-color);
  color: var(--rs-white-color);
  @media screen and (min-width: $breakpoint-md) {
    padding: size(100) 0 size(85) 0;
  }
  .flex-box-outer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
    .endpoint-name {
      max-width: size(400);
      font-size: var(--rs-title-level0-font-size);
      line-height: var(--rs-title-level0-line-height);
      font-weight: 800;
      color: var(--rs-white-color);
      @media screen and (min-width: $breakpoint-lg) {
        margin-top: size(-7);
      }
    }
    .flex-box-inner {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      padding-top: size(30);
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(0);
      }
      .flex-item {
        max-width: size(420);
        margin-bottom: size(40);
        @media screen and (min-width: $breakpoint-md) {
          margin-right: size(25);
        }
        @media screen and (min-width: $breakpoint-lg) {
          &:nth-child(odd) {
            margin-right: size(30);
          }
          &:nth-child(even) {
            margin-right: size(0);
          }
        }
      }
    }
  }
}
</style>
<script>
import ContentSection from '@/components/RZWebsite/ContentSection.vue';

export default {
  name: 'ExploreNewPage',
  props: {
    explorePageInfo: {
      type: Object,
      default: null,
    },
  },
  components: {
    ContentSection,
  },
};
</script>
