<template>
  <div class="package-price pricing-page" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section v-if="section.static_section_id == 'banner'">
          <Banner :banner="section" :sectionType="'resource pricing package-type'"
            :showBannerImage="false" :maxWidth="'max-width-520'">
          </Banner>
        </section>
        <section class="package-pricing-container" v-if="section.static_section_id == 'package_based'">
          <div class="package-price-wrapper">
            <div v-for="plan in packagePlans" :key="plan.key">
              <div class="content-wrapper">
                <div class="image-block">
                  <img v-if="plan.name === 'Essential'" src="@/assets/lite.png" />
                  <img v-else-if="plan.name === 'Business'" src="@/assets/pro.png" />
                  <img v-else-if="plan.name === 'Business +'" src="@/assets/premium.png" />
                </div>
                <a v-if="plan.name !== 'Business +'"
                  :href="'/v5/package/'+plan.name.split(' ').join('-').toLowerCase()" class="title">
                  <span>{{plan.name.split(' ')[0]}}</span>
                  {{plan.name.split(' ')[1]}} {{plan.name.split(' ')[2]}}
                </a>
                <a v-else
                  :href="'/v5/package/business+'" class="title">
                  <span>{{plan.name.split(' ')[0]}}</span>
                  {{plan.name.split(' ')[1]}} {{plan.name.split(' ')[2]}}
                </a>
                <p class="subtitle" v-if="plan.subtitle">{{plan.subtitle}}</p>
              </div>
              <div class="pricing">
                <p class="annual-price" v-if="plan.price.price_by_volume_discounts">
                  {{formatCurrency(plan.price.price_by_volume_discounts["12"][currency], currency)}}
                  <span>/month</span>
                </p>
                <span class="unit-label">billed annually</span>
                <p class="unit-label" v-if="plan.price.unit_amount">
                  <span>{{formatCurrency(plan.price.unit_amount[currency], currency)}}</span>
                  billed monthly
                </p>
              </div>
              <TextButton
                class="license-cta"
                :buttonType="'rs-button black-cta lg'"
                :link="licenseLink(plan.key)"
              />
              <rz-markdown :text="plan.description" v-if="plan.description"></rz-markdown>
              <span class="markdown list-view">
                <ul>
                  <li>Access to
                    <strong v-if="plan.name === 'Essential'" @click="showPopup(plan)">14 APIs</strong>
                    <strong v-if="plan.name === 'Business'" @click="showPopup(plan)">21 APIs</strong>
                    <strong v-if="plan.name === 'Business +'" @click="showPopup(plan)">25+ APIs</strong>
                  </li>
                </ul>
              </span>
            </div>
            <div class="enterprise" v-if="packagePlans">
              <div class="content-wrapper">
                <div class="image-block">
                  <img src="@/assets/enterprise.png" />
                </div>
                <a href="/v5/contact-sales" class="title"><span>Enterprise</span></a>
                <p class="subtitle">Take control of Cricket API to create your custom package</p>
              </div>
              <div class="pricing">
                <img src="@/assets/client-logos.png" />
              </div>
              <a href="/v5/contact-sales" class="contact-sales-btn"
              >Contact sales</a>
              <p class="description">
                <ul>
                  <li><strong>Custom</strong> API Endpoints</li>
                  <li><strong>Flexible</strong> subscription plan</li>
                  <li>Integration support</li>
                  <li><strong>Tech</strong> <strong>Assistance</strong></li>
                  <li>Streamlined <strong>DevOps</strong> process</li>
                  <li>Inclusive of <strong>SLA</strong></li>
                  <li>Dedicated Account Manager</li>
                  <li>Complete access to APIs</li>
                </ul>
              </p>
            </div>
          </div>
          <div class="compare-plans">
            <button @click="scrollToElement()">See all Features</button>
          </div>
          <div class="developer-kit" v-if="basicPlans && basicPlans[0]">
            <div class="left-wrapper">
              <h6 v-if="basicPlans[0].early_bird_pricing_notes">{{basicPlans[0].early_bird_pricing_notes}}</h6>
              <rz-markdown class="hero-title" v-if="basicPlans[0].name"
                :text="basicPlans[0].name"></rz-markdown>
              <rz-markdown class="content" v-if="basicPlans[0].subtitle"
                :text="basicPlans[0].subtitle"></rz-markdown>
              <rz-markdown v-if="basicPlans[0].description" class="text list hide-on-desktop"
                :text="basicPlans[0].description"></rz-markdown>
              <p class="annual-price" v-if="basicPlans[0].price.price_by_volume_discounts">
                {{formatCurrency(basicPlans[0].price.price_by_volume_discounts["12"][currency], currency)}}
                <span>/Month</span>
              </p>
              <div class="button-wrapper">
                <TextButton
                  class="license-cta"
                  :link="{
                    name: 'Get Started',
                    routeParams: {
                      name: 'projectCreation',
                      query: { package: basicPlans[0].key, quantity: 12 },
                    }
                  }"
                />
                <a href="/" class="underlined-btn">Learn More</a>
              </div>
            </div>
            <div class="right-wrapper">
              <rz-markdown v-if="basicPlans[0].description" class="text list"
              :text="basicPlans[0].description"></rz-markdown>
            </div>
          </div>
        </section>
        <section id="compare-plans"
           v-if="resourceLoaded && section.static_section_id == 'package_based'" class="table-section">
          <h4 class="subtitle-content">Deep-dive into our plans</h4>
          <PackageCompareTable  :pricing="pricing" :packagePlans="packagePlans" />
          <div class="subscript-desc-container">
            <p v-for="content in subscriptDescription" :key="content.key">
              <span>{{content.key}}</span>
              <span>{{content.desc}}</span>
            </p>
          </div>
        </section>
        <section class="rs-container table package-based"
          id="package-based"
          v-if="section.static_section_id == 'package_based' && resourceLoaded">
          <div class="content-section">
            <rz-markdown v-if="section.detail && section.detail.name" :text="section.detail.name"
            class="title"></rz-markdown>
            <rz-markdown class="content" v-if="section.detail && section.detail.desc"
            :text="section.detail.desc"></rz-markdown>
          </div>
          <h4 class="sub-title-content">Other Packages</h4>
          <PackageBasedPricing :packagePlans="otherPlans" :currency="currency"
           @showPopup="showPopup($event)"/>
        </section>
        <section v-if="section.static_section_id === 'explore_other'">
          <ExploreNewPage :explorePageInfo="section" />
        </section>
        <section class="rs-support-block" v-if="section.static_section_id == 'support_block'">
          <div class="rs-container max-width-1080">
            <support-wrapper />
          </div>
        </section>
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
    <div class="popup-wrapper" v-if="openPopup" @click.self="closePopup()">
      <div class="popup" id="popup">
        <div class="popup-header">
          <svg @click="closePopup()"><use v-bind="{'xlink:href':'#close-btn'}"></use></svg>
        </div>
       <PackagePopup :planInfo="planInfo" :currency="currency"/>
      </div>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { supportNumbers } from '@/store/modules/page';
import { Package } from '@/store/modules/paygo';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import ExploreNewPage from '@/components/RsCricket/ExploreNewPage.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import PackageCompareTable from '@/components/PackageCompareTable.vue';
import PackagePopup from '@/components/PackagePopup.vue';
import PackageBasedPricing from '@/components/PackageBasedPricing.vue';
import TextButton from '@/components/RZWebsite/TextButton.vue';
import packageBasedData from '../../../stories/PackageBasedPlans.json';

export default {
  name: 'rsPackagePricing',
  components: {
    CricketLoadingPlaceholder,
    Banner,
    PaymentSignupSection,
    SupportWrapper,
    ExploreNewPage,
    PackageCompareTable,
    PackagePopup,
    TextButton,
    PackageBasedPricing,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
      priceDetails() {
        if (this.payGoResources && this.payGoResources.licenses) {
          return this.payGoResources.licenses;
        }
        return 'none';
      },
    }),
  },
  serverPrefetch() {
    return this.fetchData().then(() => {
      return this.fetchPackagePricing();
    });
  },
  data() {
    return {
      resources: null,
      api_usage: null,
      resourceLoaded: false,
      packageBasedData,
      packagePlans: null,
      otherPlans: null,
      openPopup: false,
      licenseInfo: null,
      planInfo: null,
      supportNumbers,
      basicPlans: null,
      pricing: {},
      enterprise: {
        monthly: {
          usd: 299,
          inr: 22599,
          eur: 259,
        },
        annual: {
          usd: 229,
          inr: 16999,
          eur: 199,
        },
      },
    };
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      this.fetchPackagePricing();
    }
  },
  watch: {
    packagePlans(newPlans) {
      if (newPlans) {
        newPlans.forEach((plan) => {
          this.pricing[plan.name.replace('Cricket ', '')] = this.formatCurrency(
            plan.price.price_by_volume_discounts['12'][this.currency],
            this.currency,
          );
        });
      }
    },
  },
  methods: {
    scrollToElement() {
      document.getElementById('compare-plans').scrollIntoView({
        behavior: 'smooth',
      });
    },
    licenseLink(key) {
      return {
        name: 'Get Started',
        routeParams: {
          name: 'projectCreation',
          query: { package: key, quantity: 12 },
        },
      };
    },
    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchPackagePricing();
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    fetchPackagePricing() {
      return Package.getPackagePricing(this.$apiInstance, this.$inMemoryCache).then((resp) => {
        this.packagePlans = resp.data.data.response.filter(
          (plan) => plan.type === 'standard_package' && plan.active && plan.show_in_website
            && plan.name !== 'Developer Kit',
        );
        this.basicPlans = resp.data.data.response.filter(
          (plan) => plan.type === 'standard_package' && plan.active && plan.show_in_website
            && plan.name === 'Developer Kit',
        );
        this.otherPlans = resp.data.data.response.filter((plan) => plan.type !== 'standard_package'
          && plan.show_in_website && plan.active);
        this.resourceLoaded = true;
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    showPopup(plan) {
      this.planInfo = plan;
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'hidden';
    },
    closePopup() {
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'auto';
    },
  },
};
</script>
<style lang="scss" scoped>
.max-width-440 {
  max-width: size(440);
  @media screen and (min-width: $breakpoint-md) {
    &.pad-100 {
      padding-right: size(100);
    }
  }
}
.package-pricing-container {
  max-width: size(500);
  margin: size(40) auto;
  @media screen and (min-width: $breakpoint-md) {
    max-width: size(700);
    margin: size(30) auto;
  }
  @media screen and (min-width: 1024px) {
    max-width: size(900);
    margin: size(30) auto;
  }
  @media screen and (min-width: $breakpoint-lg) {
    max-width: size(1080);
    margin: size(40) auto;
  }
  .package-price-wrapper {
    display: block;
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    >div {
      padding: 0 size(20) size(20);
      border: size(2) solid #DADADA;
      margin: size(20) auto;
      border-radius: size(8);
      max-width: size(300);
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(234);
        flex: 0 0 45%;
        padding: size(30);
        margin: 0 size(30) size(30) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        flex: 0 0 25%;
        padding: 0 size(48) 0 0;
        margin: 0;
        border: none;
      }
      &:last-child {
        @media screen and (min-width: $breakpoint-lg) {
          padding-right: 0;
        }
      }

      &.enterprise {
        .content-wrapper {
          .subtitle {
            padding-bottom: size(10);
          }
        }
      }

      .subtitle {
        font-weight: 400;
        font-size: size(14);
        line-height: size(20);
        padding-bottom: size(30);
      }
      .annual-price {
        font-weight: 700;
        font-size: size(24);
        line-height: size(34);
        color: var(--rs-black-color);
        padding-bottom: size(4);

        span {
          font-size: size(14);
          line-height: size(18);
          font-weight: 500;
          color: #777;
        }
      }
      .markdown, .description {
        display: block;
        font-weight: 400;
        font-size: size(15);
        line-height: size(21);
        padding-top: size(20);
      }
      .description {
        ul {
          list-style-type: disc;
          padding-left: size(20);
          li {
            font-size: size(14);
            line-height: size(20);
            padding-top: size(6);
            color: #000000;
            &:first-child {
              padding-top: 0;
            }
            strong {
              font-weight: bolder;
            }
          }
        }
      }
      .title {
        display: block;
        font-weight: 700;
        font-size: size(16);
        line-height: size(22);
        text-transform: capitalize;
        color: var(--rs-black-color);
        padding-bottom: size(8);
      }

      .contact-sales-btn {
        background: var(--rs-black-color);
        color: var(--rs-white-color);
        border: size(1) solid var(--rs-black-color);
        padding: size(12) 0;
        line-height: size(21);
        font-size: var(--rs-title-level5-font-size);
        font-weight: bold;
        display: block;
        border-radius: size(5);
        margin-top: size(18);
        text-align: center;
        &:hover {
          @include jump-up-animation;
        }
        &:after {
          content: '';
          border: solid var(--rs-white-color);
          border-width: 0 size(2) size(2) 0;
          display: inline-block;
          padding: size(2.2);
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          margin-left: size(5);
          vertical-align: middle;
        }
      }
      .content-wrapper {
        @media screen and (min-width: $breakpoint-md) {
          min-height: size(324);
        }
        .image-block {
          img {
            max-width: 100%;
            margin-bottom: size(20);
            min-height: size(200);
          }
        }
      }
      .pricing {
        display: flex;
        flex-direction: column;
        justify-content: end;

        img {
          max-width: 90%;
        }
        @media screen and (min-width: $breakpoint-md) {
          min-height: size(96);
        }
      }
      .unit-label {
        font-weight: 400;
        font-size: size(12);
        line-height: size(17);

        span {
          font-weight: 500;
          color: var(--rs-black-color);
        }
      }
    }
  }
}
.subscript-desc-container {
  margin-top: size(30);
  padding: 0 size(20);
  @media screen and (min-width: $breakpoint-lg) {
    max-width: size(1080);
    margin: size(30) auto 0;
  }
  p {
    display: flex;
    gap: size(8);
    max-width: 100%;

    span {
      font-size: size(14);
      line-height: size(20);
      padding-bottom: size(10);
      &:first-child {
        font-weight: 700;
      }
    }
  }
}
.table-section {
  background: #FBFBFB;
  padding: size(30) 0;
  margin-top: size(30);
  @media screen and (min-width: $breakpoint-lg) {
    margin-top: size(80);
  }
  .subtitle-content {
    font-weight: 700;
    font-size: size(24);
    line-height: size(30);
    text-align: center;
    padding: size(0) size(20);
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(1080);
      margin: size(30) auto;
      padding: 0;
      font-size: size(30);
      line-height: size(42);
    }
  }
}
.sub-title-content {
  font-size: var(--rs-title-level0-font-size);
  line-height: var(--rs-title-level0-line-height);
  font-weight: 800;
  color: var(--rs-black-color);
  padding-left: 0;
  padding-right: 0;
  margin-top: size(30);
  @media screen and (min-width: $breakpoint-md) {
    margin-top: size(60);
  }
  @media screen and (min-width: $breakpoint-lg) {
    max-width: size(1080);
    margin: size(80) auto 0;
    padding: 0;
  }
}
.package-price {
  font-family: var(--rz-hero-font);
  --cards-border-color: #DADADA;
  scroll-behavior: smooth;
  .rs-container {
    max-width: size(500);
    margin: 0 size(20);
    @media screen and (min-width: $breakpoint-md) {
      margin: 0 auto;
    }
    &.max-width-1080 {
      max-width: size(1080);
    }
    &.table {
      padding: 0;
      .content {
        max-width: size(600);
        margin: 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(1080);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      max-width: size(700);
    }
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(900);
    }
  }
  .popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 10000;
    .popup {
      position: fixed;
      z-index: 1000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: size(2);
      width: size(300);
      height: size(450);
      overflow: scroll;
      @media screen and (min-width: $breakpoint-md) {
        width: size(680);
        height: auto;
        overflow: auto;
      }
      .popup-header {
        svg {
          position: absolute;
          right: size(20);
          top: size(20);
          width: size(10);
          height: size(10);
          cursor: pointer;
        }
      }
    }
  }
  &-title {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
    color: var(--rs-black-color);
    font-weight: bold;
    text-align: center;
    max-width: size(800);
    margin: 0 auto;
    padding-bottom: size(80);
  }
  .title-section {
    h4 {
      &:first-child {
        padding-bottom: 0;
      }
    }
  }
}
.developer-kit {
  background-color: #111;
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: var(--rs-white-color);
  padding: size(30);
  margin-top: size(32);

  &:empty {
    padding: 0;
    margin: 0;
  }

  @media screen and (min-width: $breakpoint-md) {
    margin-top: size(80);
    gap: size(30);
  }

  @media screen and (min-width: $breakpoint-lg) {
    gap: size(60);
    padding: size(60);
    background-image: url('~@/assets/dev-bg.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 14%;
  }

  .left-wrapper {
    max-width: size(350);
  }

  h6 {
    color: #A588FF;
    font-size: size(16);
    font-weight: 700;
    padding-bottom: size(4);

    @media screen and (min-width: $breakpoint-md) {
      font-size: size(18);
    }
  }

  .annual-price {
    font-size: size(36);
    font-weight: 700;
    line-height: size(45);
    margin-bottom: size(16);
    padding-top: size(20);

    @media screen and (min-width: $breakpoint-md) {
      margin-top: 0;
    }

    span {
      font-size: size(15);
      font-weight: 500;
      line-height: size(18);
    }
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    gap: size(16);
    .underlined-btn {
      font-size: size(15);
      font-weight: 500;
      line-height: size(17);
      text-decoration-line: underline;
      color: var(--rs-white-color);
    }
  }
}
</style>

<style lang="scss">
.package-price {
  &.pricing-page {
    .solution-banner {
      .flex-box {
        .one {
          span {
            display: block;
          }
        }
      }
    }
  }
  .solution-banner {
    background: transparent;
    clip-path: none;
    &.pricing {
        &.package-type {
        min-height: size(370);
        @media screen and (min-width: $breakpoint-md) {
          min-height: size(200);
        }

        @media screen and (min-width: $breakpoint-sm) {
          min-height: size(100);
        }
      }
    }

    &.resource {
      padding-bottom: 0;
    }

    .flex-box {
      justify-content: center;
      .one {
        text-align: center;
         .hero-links {
          display: none;
        }
        span {
          color: #131313;
        }
      }

      .banner-title {
        max-width: 100%;
        color: #131313;
      }
    }
  }

  .package-price-wrapper {
    > div {
      .markdown {
        display: inline-block;
        margin: 0;
        em {
          font-style: italic;
          font-weight: normal;
        }
        strong {
          font-weight: bolder;
          em {
            font-weight: bolder;
          }
        }
        &.list-view {
          padding-top: size(6);
          margin: 0;
          strong {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        ul {
          list-style-type: disc;
          padding-left: size(20);
          li {
            font-size: size(14);
            line-height: size(20);
            padding-top: size(6);
            color: #000000;
            &:first-child {
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  #package-based {
    padding-top: 0;
    .title {
      .p {
        @media screen and (min-width: $breakpoint-lg) {
          font-weight: 800;
          font-size: size(80);
          line-height: size(90);
          padding-bottom: size(20)
        }
        strong {
          color: #4F37C8;
        }
      }
    }
    .package-info {
      padding-top: size(30);
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(40);
      }
    }
  }
  .committed-usage {
    .rs-container {
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(100);
      }
    }
  }
  .content-section {
    max-width: size(550);
    padding: size(30) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
      padding-bottom: size(40);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(120);
      padding-bottom: size(60);
    }
    &:empty {
      padding: 0;
    }
    .markdown {
      .p {
        font-weight: bold;
        font-size: size(20);
        line-height: size(26);
        padding-bottom: size(10);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(24);
          line-height: size(32);
        }
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(30);
          line-height: size(42);
        }
        a {
          color: var(--hero-text);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    .content {
      .p {
        font-size: size(16);
        line-height: size(24);
        font-weight: normal;
        padding-bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(18);
          line-height: size(28);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    p {
      font-size: size(16);
      line-height: size(24);
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(18);
        line-height: size(28);
      }
    }
  }
  .package-plans-section {
    padding: size(30) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(60) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(100) 0;
    }

    .section-wrapper {
      .plan-box-wrapper {
        margin-bottom: 0;
      }
    }
  }
}
.package-pricing-container {
  .markdown {
    margin-bottom: 0;
  }
}
.compare-plans {
  margin-top: size(30);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: $breakpoint-md) {
    margin-top: size(40);
  }
  button {
    font-size: size(15);
    font-weight: 500;
    line-height: size(16);
    text-decoration-line: underline;
    cursor: pointer;
  }
}
.developer-kit {
  .hero-title {
    color: var(--rs-white-color);
    font-size: size(32);
    font-weight: 700;
    line-height: size(40);
    margin-bottom: size(10);

    @media screen and (min-width: $breakpoint-md) {
      font-size: size(42);
      line-height: size(50);
    }
  }
  .content {
    color: var(--rs-white-color);
    font-size: size(16);
    font-weight: 400;
    line-height: size(24);
    margin-bottom: size(35);
  }

  .license-cta {
    a {
      padding: size(12) size(40);
      background: var(--rz-link-color);
      color: var(--rs-white-color);
      border-radius: size(5);
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      font-size: size(15);
      font-weight: 700;

      &:hover {
        color: var(--rs-white-color);
      }
    }
  }

  .hide-on-desktop {
    display: block;
    @media screen and (min-width: $breakpoint-md) {
      display: none;
    }
  }

  .right-wrapper {
    display: none;
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
    }
  }

  .list {
    margin-bottom: 0;
    ul {
      list-style-type: none;
      padding-left: 0;
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(30);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(66);
        columns: 2;
      }
      li {
        display: flex;
        align-items: center;
        color: var(--rs-white-color);
        font-size: size(14);
        font-weight: 400;
        line-height: size(21);
        margin-bottom: size(12);
        padding: 0;
        @media screen and (min-width: $breakpoint-lg) {
          margin-bottom: size(28);
        }
        &:before {
          display: inline-block;
          padding-right: size(10);
          line-height: 0;
        }
        &:nth-child(1) {
          &:before {
            content: url('~@/assets/dev-feat-1.svg');
          }
        }
        &:nth-child(2) {
          &:before {
            content: url('~@/assets/dev-feat-2.svg');
          }
        }
        &:nth-child(3) {
          &:before {
            content: url('~@/assets/dev-feat-3.svg');
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-bottom: 0;
          }
        }
        &:nth-child(4) {
          &:before {
            content: url('~@/assets/dev-feat-4.svg');
          }
        }
        &:nth-child(5) {
          &:before {
            content: url('~@/assets/dev-feat-5.svg');
          }
        }
        &:nth-child(6) {
          &:before {
            content: url('~@/assets/dev-feat-6.svg');
          }
          @media screen and (min-width: $breakpoint-lg) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
