<template>
  <div>
    <div class="rs-get-started" v-if="dataLoaded && page">
      <div v-for="(section, index) in page.sections" :key="index">
        <div class="bg-blue">
          <section class="container" v-if="section.static_section_id === 'banner'">
            <div class="banner flex-box space-between">
              <div class="flex-item one">
                <h1 class="title">{{ section.detail.name }}</h1>
                <p class="desc">{{ section.detail.desc }}</p>
              </div>
              <div class="flex-item two">
                <div class="image-wrap">
                  <rz-image-item v-if="section.detail.img" :image="section.detail.img" />
                </div>
              </div>
            </div>
          </section>
          <section class="container" v-if="section.static_section_id === 'step_one'">
            <div class="step-container-one">
              <StepSection
                :stepData="section"
                :stepTitle="'step 1'"
                :layoutType="'one'"
                :buttonType="'rs-button yellow-cta'"
              />
            </div>
          </section>
          <section class="container" v-if="section.static_section_id === 'step_two'">
            <div class="step-container-two">
              <h2>{{ section.name }}</h2>
              <StepSection
                :stepData="section"
                :stepTitle="'step 2'"
                :layoutType="'two'"
              />
            </div>
          </section>
          <section class="container" v-if="section.static_section_id === 'step_three'">
            <div class="step-container-three">
              <StepSection
                :stepData="section"
                :stepTitle="'step 3'"
                :layoutType="'two-column'"
                :buttonType="'rs-button plain-green-cta'"
                class="pad-200"
              />
            </div>
          </section>
        </div>
        <section
          class="two-col-container clip-section"
          v-if="section.static_section_id === 'after_month'"
        >
          <div class="container">
            <div class="content">
              <p class="step-number">After each month</p>
              <h2 class="title">{{ section.detail.name }}</h2>
              <p class="para">{{ section.detail.desc }}</p>
              <div class="hero-links" v-if="section.detail.links && section.detail.links.length">
                <template v-for="(link,index) in section.detail.links">
                  <TextButton :buttonType="'rs-button plain-green-cta'" :key="index" :link="link" />
                </template>
              </div>
            </div>
            <div class="bg-img">
              <rz-image-item v-if="section.detail.img" :image="section.detail.img" />
            </div>
          </div>
        </section>
        <section class="container" v-if="section.static_section_id == 'tech_process'">
          <div class="tech-process-wrap">
            <h3 class="title" v-if="section.name">{{ section.name }}</h3>
            <div class="flex-box">
              <div class="flex-item" v-for="section in section.detail.items" :key="section.name">
                <content-section
                  :section="section"
                  :showButton="false"
                  :sectionType="'tech-process-wrap rs-page'"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <section class="rs-support-block">
        <div class="rs-container">
          <support-wrapper />
        </div>
      </section>
      <section class="customer-logo">
          <img src="@/assets/rsCricket/customer-logo.png" alt="Customer logos"/>
        </section>
      <div v-for="(section) in page.sections" :key="section.static_section_id">
        <section v-if="section.static_section_id == 'fantasy_banner'">
          <div class="rs-container">
            <ContentImageWrap
              :section="section.detail"
              :imageClass="'max-width-450'"
              :sectionType="'fantasy'"
            />
          </div>
        </section>
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </div>
    </div>
    <div class="content placeholder" v-else>
      <CricketLoadingPlaceholder :state="pageState" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import StepSection from '@/components/RsCricket/StepSection.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import ContentImageWrap from '@/components/RsCricket/ContentImageWrap.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import ContentSection from '@/components/RZWebsite/ContentSection.vue';
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  name: 'RsGetStarted',
  components: {
    TextButton,
    PaymentSignupSection,
    CricketLoadingPlaceholder,
    SupportWrapper,
    ContentImageWrap,
    StepSection,
    ContentSection,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.fetchData();
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  methods: {
    freshFetch() {
      return this.fetchData();
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        console.log('route value', routeValue);
        return this.$store.dispatch('page/fetchActivePage', {
          route,
          routeValue,
        });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
  },
};
</script>
<style lang="scss" scoped>
.rs-get-started {
  --rs-get-started-pad-horizontal: #{size(15)};
  --rs-get-started-green-color: #17a48a;
  --rs-get-started-orange-color: #e88e62;
  --rs-get-started-blue-color: #575bc8;
  --rs-get-started-step-title-color: #634cd9;

  @media screen and (min-width: $breakpoint-md) {
    --rs-get-started-pad-horizontal: #{size(15)};
  }

  font-family: var(--rz-hero-font);
  color: var(--rs-white-color);
  img {
    max-width: 100%;
  }
  .container {
    margin: auto;
    padding: 0 var(--rs-get-started-pad-horizontal);
    box-sizing: border-box;
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(1080);
      padding: 0;
    }
  }
  .max-width-1440 {
    max-width: size(1440);
    margin: 0 auto;
    position: relative;
  }
  .pad-100 {
    padding-top: size(100);
  }
  .pad-200 {
    padding-top: size(200);
  }
  .bg-blue {
    background: var(--onboard-link-color);
  }
  .clip-section {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    background: var(--onboard-link-color);
    padding-top: size(60);
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(120);
    }
  }
  .content {
    max-width: size(460);
    .step-number {
      font-size: size(18);
      line-height: size(25);
      letter-spacing: size(1.6);
      text-transform: uppercase;
      margin-bottom: size(10);
      font-weight: 700;
      color: var(--rs-light-lavender-color);
    }
    h2.title {
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
      margin-bottom: size(10);
      color: var(--rs-white-color);
      font-weight: 700;
      padding-right: unset;
    }
    .para {
      font-size: var(--rs-para-font-size);
      line-height: var(--rs-para-line-height);
      margin-bottom: size(10);
    }
  }
  .banner {
    display: flex;
    padding: size(40) 0 0 0;
    @media screen and (min-width: $breakpoint-md) {
      padding: size(40) 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(140) 0 size(80) 0;
    }
    .title {
      font-size: var(--rs-title-level0-font-size);
      line-height: var(--rs-title-level0-line-height);
      font-weight: 800;
      margin-bottom: size(10);
      max-width: size(500);
    }
    .desc {
      font-size: var(--rs-para-font-size);
      line-height: var(--rs-para-line-height);
      max-width: size(460);
    }
    .flex-item.two {
      margin: auto;
      @media screen and (min-width: $breakpoint-md) {
        margin: unset;
        margin-left: auto;
      }
    }
  }
  .step-container {
    &-one {
      padding-bottom: size(25);
    }
    &-two {
      position: relative;
      h2 {
        font-size: size(110);
        line-height: size(110);
        color: var(--rs-get-started-step-title-color);
        max-width: size(780);
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(140);
          line-height: size(140);
          position: absolute;
          top: size(-20);
        }
      }
      .get-started-step {
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(200);
        }
      }
    }
    &-three {
      padding-top: unset;
      @media screen and (min-width: $breakpoint-md) {
        padding-top: size(50);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(120);
      }
    }
  }
  .two-col-container {
    .container {
      position: relative;
      height: size(600);
      @media screen and (min-width: $breakpoint-md) {
        height: size(700);
      }
      @media screen and (min-width: $breakpoint-lg) {
        height: size(600);
      }
    }
    .content {
      margin: 0 auto 0 0;
    }
    .bg-img {
      position: absolute;
      right: 0;
      top: size(200);
      max-width: size(800);
      height: size(350);
      z-index: var(--nav-box-z-index);
      @media screen and (min-width: $breakpoint-md) {
        top: size(200);
      }
      @media screen and (min-width: $breakpoint-lg) {
        top: size(110);
      }
    }
  }
  .tech-process-wrap {
    .title {
      font-size: var(--rs-title-level0-font-size);
      line-height: var(--rs-title-level0-line-height);
      margin-bottom: size(40);
      max-width: size(700);
      color: var(--rs-black-color);
      font-weight: 800;
    }
    .flex-item {
      flex: 0 0 100%;
      margin-bottom: size(40);
      @media screen and (min-width: $breakpoint-lg) {
        flex: 0 0 size(340);
      }
      &:not(:nth-child(3n)) {
        margin-right: size(30);
      }
    }
  }
  .customer-logo {
    padding: 0 size(20);
    max-width: size(1000);
    margin: auto;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(40) size(0);
    }
  }
}
</style>
<style lang="scss">
.rs-get-started {
  .banner {
    .flex-box {
      .flex-item.two {
        display: none;
      }
      @media screen and (min-width: $breakpoint-lg) {
        .flex-item.two {
          display: unset;
        }
      }
    }
  }
  .step-container-two {
    .get-started-step.two .content {
      margin-left: unset;
      margin-top: size(75);
      @media screen and (min-width: $breakpoint-lg) {
        margin-left: auto;
        margin-top: unset;
      }
    }
    .get-started-step .image-sec {
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          height: size(480);
        }
      }
  }
  .step-container-one {
    .get-started-step {
      padding-top: size(25);
      padding-bottom: size(0);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(35);
      }
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(25);
        padding-top: unset;
      }
      .image-sec {
        height: size(300);
        @media screen and (min-width: $breakpoint-md) {
          height: size(480);
        }
      }
    }
  }
  .step-container-three {
    margin-left: auto;
    .pad-200 {
      padding-top: size(40);
    }
    .image-sec {
      height: size(300);
      @media screen and (min-width: $breakpoint-md) {
        height: size(480);
      }
    }
    .content {
      padding-bottom: size(25);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(0);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: unset;
      .image-wrapper {
        margin-left: auto;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-left: unset;
      .pad-200 {
        padding-top: unset;
      }
    }
    .get-started-step.two-column {
      .content {
        padding-top: size(0);
        @media screen and (min-width: $breakpoint-lg) {
          padding-top: size(40);
        }
      }
    }
  }
  .quote-wrap.tech-process-wrap .detail-text {
    max-width: unset;
  }
  .flex-box {
    display: flex;
    flex-wrap: wrap;
    &.space-between {
      justify-content: space-between;
    }
    &.align-center {
      align-items: center;
    }
    }

    .banner {
    .image-wrap {
      height: size(380);
      max-width: size(480);
    }
  }
}
</style>
