<template>
  <div class="rs-resource-home" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section v-if="section.static_section_id == 'resource_banner'">
          <Banner :banner="section" :maxWidth="'max-width-600'" :sectionType="'resource'" :isGraphql="true"></Banner>
        </section>
      </section>
      <section class="rs-flex-box rs-container part-first space-between">
        <section class="change-order">
          <section v-for="(section, index) in page.sections" :key="'section'+index">
            <section class="what-can-build-wrap" v-if="section.static_section_id == 'section_1'">
              <div class="rs-container pad-15">
                <div class="rs-flex-box space-between">
                  <div>
                    <content-section
                      :section="section.detail"
                      :showButton="false"
                      :sectionType="'tech-process-wrap rs-page max-width-600'"/>
                    <div class="image-wrap max-width-600" v-if="section.detail.img">
                      <rz-image-item
                        v-if="section.detail.img" :image="section.detail.img" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section v-if="section.static_section_id == 'section_2'">
              <div class="rs-container pad-15">
                <content-section
                  :section="section.detail"
                  :sectionType="'tech-process-wrap rs-page max-width-600'"
                  :buttonType="'rs-button tick-btn'"/>
                <h4 class="pink-color">{{section.name}}</h4>
              </div>
            </section>
            <section v-if="section.static_section_id == 'section_3'">
              <div class="rs-container pad-15">
                <content-section class="docs-wrapper"
                  :section="section.detail"
                  :sectionType="'tech-process-wrap rs-page max-width-600'"
                  :buttonType="'rs-button'"/>
              </div>
            </section>
            <section v-if="section.static_section_id == 'section_4'">
              <div class="rs-container pad-15">
                <h5>{{section.name}}</h5>
                <content-section
                  :section="section.detail"
                  :sectionType="'tech-process-wrap rs-page max-width-600'"/>
                <div class="max-width-600">
                  <div class="top-padding" v-for="(item,index) in section.detail.items" :key="index">
                    <h6>{{item.name}}</h6>
                    <rz-markdown class="detail-text" v-if="item.desc" :text="item.desc"></rz-markdown>
                  </div>
                  <div class="image-wrap">
                    <img src="@/assets/rsCricket/client_logos.png" alt="Client logos"/>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </section>
        <div v-if="resources && resourceLoaded" class="match-wrap">
          <h4 class="match-wrap_currency">Pricing</h4>
          <div v-for="(endpoint,key) in resources" :key="key">
            <div v-for="api in endpoint" :key="api.name">
              <div v-if="api.resourceKey == apiresourceType">
                <div class="pricing-wrapper">
                  <p class="unit-label">{{api.starts_at.unit_label}}</p>
                  <table>
                    <thead>
                      <tr>
                        <th><a href="/v5/pricing">Standard</a></th>
                        <th><a href="/v5/pricing">Premium</a></th>
                        <th><a href="/v5/pricing">Enterprise</a></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="type in apiPricingTypes" :key="type.key">
                        <td v-for="info in (api && api.detail
                         && api.detail[type] &&  api.detail[type].price
                         && api.detail[type].price.normal_price)" :key="info.key">
                          <a v-if="Object.keys(api.detail).length > 1" href="/v5/coverage">
                            {{type}}</a>
                          <p v-if="info[currency] > 0">{{formatCurrency(info[currency], currency)}}</p>
                          <span class="free-card" v-else>Free</span>
                        </td>
                      </tr>
                      <tr v-if="api.detail.MG100.price.price_variations.length">
                        <td v-for="index in 3" :key="index">
                          <template v-for="varaition in api.detail.MG100.price.price_variations">
                            <h5 :key="varaition.variation_factor">Historical Data</h5>
                            <p :key="varaition.variation_factor"> x{{varaition.variation_factor}}</p>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <p class="match-wrap_per-month color-gray two">
            Apart from the resource costs, for every 100 requests per month,
             you will be charged
            <span v-if="api_usage && api_usage.normal_price">
              <span class="inline">
                {{formatCurrency(api_usage.normal_price.standard[currency], currency)}}</span>
            </span>
            for standard plan,
            <span v-if="api_usage && api_usage.normal_price">
              <span class="inline">
                {{formatCurrency(api_usage.normal_price.premium[currency], currency)}}</span>
            </span> for premium plan and
            <span v-if="api_usage && api_usage.normal_price">
              <span class="inline">
                {{formatCurrency(api_usage.normal_price.enterprise[currency], currency)}}</span>
            </span> for enterprise plan.
          </p>
          <TextButton :buttonType="'rs-button plain-cta'" :link="{'name': 'See Coverage', 'url': '/v5/coverage/'}" />
        </div>
      </section>
      <section v-for="(section, index) in page.sections" :key="'endpint'+index">
        <section class="pay-go-wrap" v-if="section.static_section_id == 'end_points'">
          <div v-if="resources && resourceLoaded">
            <div class="rs-container">
              <div class="flex-item endpoint hide" v-for="(endpoints, key) in resources" :key="key" :id="key"
                :class="key + '-section'">
                <div class="flex-item-header" @click="collapseSection(key)">
                  <div class="flex-box clickable">
                    <h4 class="title">{{key}} Endpoints</h4>
                    <button class="expand">
                    </button>
                  </div>
                  <p class="text">
                    <span v-for="(api, index) in endpoints" :key="api.name">{{api.name}}
                      <span v-if="index + 2 == endpoints.length">
                        and&nbsp;
                      </span>
                      <span v-else-if="index + 1 == endpoints.length"></span>
                      <span v-else>,&nbsp;</span>
                    </span>
                  </p>
                </div>
                <div class="rs-flex-box space-between">
                  <template v-for="item in section.detail.items">
                    <template v-for="(api, index)  in endpoints">
                      <div class="flex-item inner-box"
                        :class="{ 'show-border' : showBorder(index, endpoints.length)}"
                        v-if="api.name == item.name" :key="item.name || api.name">
                        <div class="flex-box-inner">
                        <div class="block-left">
                        <router-link
                          class="end-point-link color-blue"
                          :to="{name: 'rsResourcePage', params: { api: api.resourceKey.split('_').join('-') }}">
                            {{ item.name }} Query
                        </router-link>
                        <rz-markdown class="text" v-if="item.desc" :text="item.desc"></rz-markdown>
                        </div>
                        <div class="block-right">
                        <div class="pricing-info">
                          <span class="label" v-if="(api.starts_at && api.starts_at.unit_amount &&
                            api.starts_at.unit_amount[currency]) && (api.detail && api.detail.MG101) &&
                            (api.name !== 'Featured Tournaments') && (api.name !== 'Tournament Player Stats')
                            && (api.name !== 'Tournament Featured Matches')">
                            From</span>
                          <div class="make-bold">
                            <span>
                              {{formatCurrencyWithDiscount(api.starts_at.unit_amount[currency], currency, 25)}}
                            </span>
                            <span  class="strike-price" v-if="api.starts_at && api.starts_at.unit_amount &&
                              api.starts_at.unit_amount[currency]">
                              {{formatCurrency(api.starts_at.unit_amount[currency], currency)}}
                            </span>
                          </div>
                          <div class="per-month" v-if="api.starts_at">
                            <span class="tooltip">{{api.starts_at.unit_label}}
                            </span>
                          </div>
                        </div>
                        </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="rs-container pad-100" v-if="section.static_section_id == 'get_started'">
          <get-started-section />
        </section>
        <section class="rs-support-block" v-if="section.static_section_id == 'support_block'">
          <div class="rs-container">
            <support-wrapper />
          </div>
        </section>
        <section v-if="section.static_section_id == 'discount_banner'" class="discount-banner">
          <div class="rs-container">
            <ContentImageWrap :section="section.detail" :sectionType="'discount'"
              :buttonType="'rs-button yellow-cta white'" :subText="section.name"
              :infoText="formatCurrency(minAmountForDiscount[currency], currency)"/>
          </div>
        </section>
      </section>
      <section v-for="(section) in page.sections" :key="section.static_section_id">
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { minAmountForDiscount } from '@/store/modules/page';
import { Enum } from '@/core/utils/enum';
import TextButton from '@/components/RZWebsite/TextButton.vue';
import { PayGoResources } from '@/store/modules/paygo';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import ContentImageWrap from '@/components/RsCricket/ContentImageWrap.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import ContentSection from '@/components/RZWebsite/ContentSection.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import GetStartedSection from '@/components/RsCricket/GetStartedSection.vue';

export const ApiSection = new Enum({
  LiveScore: 'Live Score & Stats API',
  Chatbot: 'Chatbot API',
  Performance: 'Performance API',
  Fantasy: 'Fantasy API',
  GraphQL: 'GraphQL',
});

export default {
  name: 'rsCricketHome',
  props: {
    resourceType: String,
  },
  components: {
    CricketLoadingPlaceholder,
    TextButton,
    SupportWrapper,
    ContentImageWrap,
    ContentSection,
    Banner,
    GetStartedSection,
    PaymentSignupSection,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
    }),
    apiresourceType() {
      if (this.resourceType.split('-').includes('query') && this.resourceType !== 'auth-query') {
        return this.resourceType.replace('-query', '').replace(/-/g, '_');
      } if (this.resourceType === 'auth-query') {
        return this.resourceType.replace('auth-query', 'authentication');
      }
      return this.resourceType.replace(/-/g, '_');
    },
  },
  serverPrefetch() {
    return this.fetchData().then(() => {
      return this.fetchResoucePricing();
    });
  },
  data() {
    return {
      resources: null,
      api_usage: null,
      resourceLoaded: false,
      apiPricingTypes: ['MG100', 'MG101'],
      previousTabId: null,
      minAmountForDiscount,
    };
  },
  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.resourceType}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      this.fetchResoucePricing().then(() => {
        this.enableEndpointGroup();
      });
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchResoucePricing().then(() => {
          return this.enableEndpointGroup();
        });
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.resourceType}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    fetchResoucePricing() {
      return PayGoResources.getGraphqlResourcePricing(this.$apiInstance, this.$inMemoryCache).then((resp) => {
        this.resources = resp.groupByEndpoints;
        this.resourceLoaded = true;
        this.api_usage = resp.usageInfo;
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
      });
    },
    enableEndpointGroup() {
      Object.values(this.resources).forEach((endpoint) => {
        Object.values(endpoint).forEach((api) => {
          if (api.resourceKey === this.resourceType.replace(/-/g, '_')) {
            this.collapseSection(api.endpoint_group);
          }
        });
      });
    },
    collapseSection(key) {
      const element = document.getElementsByClassName(`${key}-section`);
      let contentContainer;
      if (element && element[0]) {
        contentContainer = element[0].querySelector('.rs-flex-box');
      }
      if (element && element[0]) {
        if (element[0].classList.contains('hide')) {
          element[0].classList.remove('hide');
          contentContainer.style.height = `${contentContainer.scrollHeight}px`;
        } else {
          element[0].classList.add('hide');
          contentContainer.setAttribute('style', '');
        }
      }
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    showBorder(index, arrSize) {
      if (
        arrSize % 2 === 1
        && index > 2
        && index === arrSize - 2
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
}
.rs-resource-home {
  font-family: var(--rz-hero-font);
  h6 {
    &.flag-text {
      font-size: size(14);
      line-height: size(20);
      color: var(--rs-white-color);
      margin-bottom: size(5);
      clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
      background: #97E6AB;
      text-transform: uppercase;
      -webkit-clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
      width: size(82);
      font-size: size(10);
      line-height: size(14);
      font-weight: 600;
      color: black;
      padding: size(3) size(16) size(3) size(9);
    }
  }
  .match-wrap {
    background: var(--rs-white-color);
    box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
    border-radius: size(6);
    max-width: size(330);
    padding: size(28);
    color: var(--rs-black-color);
    align-self: flex-start;
    margin: 0 size(20);
    order: 1;
    &_box {
      display: inline-block;
      width: calc(100% / 3);
      .inline {
        font-size: size(15);
        line-height: size(21);
        font-weight: bold;
      }
      label {
        font-size: var( --rs-sm-label-font-size);
        line-height: var( --rs-sm-label-line-height);
        padding-bottom: size(4);
        display: block;
        color: #585757;
      }
    }
    &_currency {
      font-size: var(--rs-title-level3-font-size);
      line-height: var(--rs-title-level3-line-height);
      color: var(--rs-black-color);
      font-weight: bold;
    }
    &_per-month {
      font-size: var( --rs-sm-label-font-size);
      line-height: var( --rs-sm-label-line-height);
      .strike-price {
        font-size: var( --rs-sm-label-font-size);
        line-height: var( --rs-sm-label-line-height);
      }
      &.color-gray {
        color: var(--rs-gray-color);
        padding-top: size(18);
        &.two {
          padding-bottom: size(10);
        }
        span {
          color: var(--rs-black-color);
          font-weight: bold;
          display: inline-block;
          &.strike-price {
            padding-bottom: 0;
          }
        }
      }
    }
    .pricing-wrapper {
      .unit-label {
        font-weight: 400;
        font-size: size(15);
        line-height: size(21);
        color: #777777;
        padding-top: size(5);
      }
      table {
        width: 100%;
        margin-top: size(20);
        thead {
          tr {
            text-align: left;
            th {
              a {
                font-size: size(13);
                line-height: size(18);
                color: var(--hero-text);
                font-weight: bold;
              }
            }
          }
        }
        tbody {
          tr {
            &:first-child {
              td {
                padding: size(14) 0 size(20);
              }
            }
            td {
              padding: size(20) 0;
              padding-right: size(5);
              border-bottom: size(1) solid #DADADA;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(20) 0;
              }
              a {
                font-weight: 400;
                font-size: size(12);
                line-height: size(17);
                color: #585757;
                padding-bottom: size(6);
                display: block;
                width: size(42);
                &:hover {
                  text-decoration: underline;
                }
              }
              h5 {
                font-weight: 400;
                font-size: size(12);
                line-height: size(17);
                color: #585757;
                padding-bottom: size(6);
              }
              p {
                font-weight: 700;
                font-size: size(13);
                line-height: size(18);
                color: var(--rs-black-color);
              }
              .free-card {
                color: #00A656;
                padding: size(1) size(8);
                background: rgba(151, 230, 171, 0.2);
                border-radius: size(3);
                font-size: size(12);
                line-height: size(17);
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: size(1024)) {
      position: sticky;
      top: 80px;
      margin: size(75) 0 0 0;
    }
  }
  .what-can-build-wrap {
    .image-wrap {
      height: size(400);
    }
  }
  .rs-support-block {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: size(35);
      }
    }
  }
  .discount-banner {
    .rs-container {
      @media screen and (min-width: $breakpoint-lg) {
        padding-top: size(35);
      }
    }
  }
  .pad-15 {
    padding: size(15) size(20);
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(15) 0;
    }
  }
  .docs-wrapper {
    border-bottom: size(1) dashed var(--rs-gray-color);
    padding-bottom: size(30);
  }
  .max-width-600 {
    max-width: size(600);
  }
  .top-padding {
    padding-top: size(30);
  }
  h4 {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
    color: var(--rs-white-color);
    font-weight: bold;
    &.pink-color {
      color: var(--rs-pink-color);
      max-width: size(600);
      padding-top: size(30);
    }
  }
  h5 {
    font-size: var(--rs-title-level2-font-size);
    line-height: var(--rs-title-level2-line-height);
    color: var(--rs-black-color);
    font-weight: bold;
  }
  h6 {
    font-size: var(--rs-title-level4-font-size);
    line-height: var(--rs-title-level4-line-height);
    font-weight: bold;
    color: var(--rs-black-color);
    font-family: var(--rz-hero-font);
    padding-bottom: size(10);
  }
  .detail-text {
    color: var(--rs-black-color);
    font-size: var(--rs-title-level4-font-size);
    line-height: size(25);
  }
  .pay-go-wrap {
    border-top: size(1) dashed var(--rs-gray-color);
  }
}

.change-order {
  order: 2;
  padding-top: size(30);
  @media screen and (min-width: size(1024)) {
    order: 1;
    padding-top: 0;
  }
}

.rs-container {
  &.part-first {
    position: relative;
    padding: 0 0 size(50) 0;
  }
}
</style>
