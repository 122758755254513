<template>
  <div class="get-started-wrap" >
    <h2 class="hero-title">Get started</h2>
    <div class="rs-flex-box no-wrap">
      <div class="flex-item-left">
        <div class="rs-flex-box sub-wrap">
          <h3>01</h3>
          <p>Quickly
            <router-link :to="{name: 'planReview'}">
            Sign Up.</router-link></p>
        </div>
        <div class="rs-flex-box sub-wrap">
          <h3>02</h3>
          <p>Share the API credentials with your developers and link them to our
            <router-link :to="{name: 'docsHome'}">
            docs.</router-link></p>
        </div>
        <div class="rs-flex-box sub-wrap">
          <h3>03</h3>
          <p>Come back and pay only for what you have used.</p>
        </div>
      </div>
      <div class="flex-item-right image-wrap hide-on-mobile">
        <!-- <img src="@/assets/rsCricket/get_started@3x.png" alt="Book"/> -->
        <picture>
          <source media="(max-width: 767px)" srcset="@/assets/rsCricket/variations/getstarted_artwork.png">
          <source media="(min-width: 768px)" srcset="@/assets/rsCricket/variations/getstarted_artwork@2x.png">
          <source media="(min-width: 1194px)" srcset="@/assets/rsCricket/variations/getstarted_artwork@3x.png">
          <img src="@/assets/rsCricket/variations/getstarted_artwork@3x.png" alt="Book">
        </picture>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .get-started-wrap {
    color: var(--rs-black-color);
    .hero-title {
      padding-bottom: size(30);
      font-weight: bold;
    }
    img {
      max-width: 100%;
    }
    h3 {
      font-size: var(--rs-title-level0-font-size);
      line-height: var(--rs-title-level0-line-height);
      font-weight: bold;
      color: var(--rs-black-color);
    }
    p {
      font-size: var(--rs-para-font-size);
      line-height: var(--rs-para-line-height);
      a {
        color: var(--rz-link-color);
      }
    }
    .rs-flex-box {
      justify-content: space-between;
      &.sub-wrap {
        justify-content: initial;
        flex-wrap: initial;
        align-items: center;
        padding-bottom: size(30);
        h3 {
          flex: 0 0 20%;
        }
      }
    }
    .image-wrap {
      max-width: size(540);
      height: size(400);
      margin: 0 auto;
      transform: translateY(#{size(-30)});
    }
    @media screen and (min-width: $breakpoint-md) {
      h3 {
        padding-right: size(30);
      }
      .flex-item-left {
        flex: 0 0 40%;
      }
      .flex-item-right {
        flex: 0 0 50%;
      }
      .image-wrap {
        padding-left: size(50);
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      .image-wrap {
        padding-left: 0;
        margin: initial;
      }
    }
    @media screen and (max-width: #{size(900)}) and (min-width: $breakpoint-md) {
      .image-wrap {
        max-width: size(400);
        height: size(340);
      }
      .flex-item-right {
        align-self: center;
      }
    }
  }
</style>
<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: null,
    },
    link: {
      type: Object,
    },
  },
};
</script>
