<template>
  <div class="solution-banner" :class="[isGraphql ? 'graphql-banner' : '', sectionType]">
    <div class="container">
      <div class="flex-box" :class="maxWidth">
        <div class="flex-item one">
          <h1 class="banner-title">{{ banner.detail.name }}</h1>
          <rz-markdown v-if="banner.detail && banner.detail.desc" class="banner-para"
          :text="banner.detail.desc"></rz-markdown>
          <div class="hero-links" v-if="banner.detail.links && banner.detail.links.length">
            <template v-for="(link,index) in banner.detail.links">
              <TextButton
                :buttonType="index == 0 ? 'rs-button yellow-cta' : 'rs-button white-cta'"
                :key="index"
                :link="link"
              />
            </template>
          </div>
        </div>
        <div class="flex-item two" v-if="showBannerImage" :class="maxWidth">
          <div class="image-wrap" :class="maxWidth">
            <rz-image-item
              v-if="banner.detail.img" :image="banner.detail.img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.solution-banner {
  .markdown {
    .p {
      a {
        color: var(--hero-text);
      }
      strong {
        font-weight: bold;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.rsArticleList {
  .solution-banner {
    clip-path: none;
  }
}
.solution-banner {
  height: size(460);
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0 85%);
  background: var(--rs-dark-blue-color);
  padding: 0 size(20);
  &.resource {
    min-height: size(600);
    height: auto;
    padding-bottom: size(50);
    position: relative;
    @media screen and (min-width: $breakpoint-md) {
      min-height: size(650);
    }
    @media screen and (min-width: $breakpoint-lg) {
      min-height: size(600);
    }
    &.coverage {
      min-height: size(400);
    }
    &.bg-black {
      background-color: var(--rs-black-color);
    }
    &.blog {
      min-height: size(200);
      @media screen and (min-width: $breakpoint-md) {
          min-height: size(400);
        }
      .flex-box {
        flex-direction: column;
        .flex-item {
          &.two {
            display: none;
          }
          &.one {
            .hero-links {
              display: none;
            }
          }
        }
        @media screen and (min-width: $breakpoint-md) {
          flex-direction: row;
          .flex-item.two {
            display: block;
            margin-top: size(30);
          }
        }
      }
    }
    &.coverage {
      min-height: size(200);
      .flex-box {
        .flex-item.two {
          display: none;
          @media screen and (min-width: $breakpoint-md) {
            display: block;
          }
        }
      }
    }
    &.pricing, &.licenses {
      min-height: size(300);
      .flex-box {
        .flex-item.two {
          display: none;
          @media screen and (min-width: $breakpoint-md) {
            display: block;
          }
        }
      }
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    height: size(550);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
    &.resource {
      .image-wrap {
        width: initial;
      }
      &.coverage {
        min-height: size(500);
      }
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: 0;
    height: size(735);
    &.resource {
      .image-wrap {
        right: size(150);
      }
    }
  }
  &.graphql-banner {
    &.resource {
      @media screen and (min-width: $breakpoint-md) {
        min-height: size(660);
      }
    }
  }
  .container {
    margin: auto;
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(1080);
    }
  }
  .flex-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: initial;
    &.max-width-600 {
      flex-wrap: wrap;
      @media screen and (min-width: $breakpoint-md) {
        flex-wrap: nowrap;
      }
    }
    .flex-item {
      &.one {
        margin-top: size(50);
        @media screen and (min-width: $breakpoint-md) {
          margin-top: size(75);
        }
        @media screen and (min-width: $breakpoint-lg) {
          margin-top: size(100);
        }
      }
      &.two {
        margin-top: size(100);
        @media screen and (min-width: $breakpoint-lg) {
          margin-top: size(170);
        }
      }
      &.max-width-600 {
        margin-top: 0;
        flex: 0 0 100%;
        @media screen and (min-width: $breakpoint-md) {
          flex: initial;
        }
      }
    }
    .image-wrap {
      &.max-width-600 {
        max-width: size(360);
        height: size(380);
        margin-left: auto;
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(600);
          height: size(470);
          margin-top: size(30);
        }
      }
      &.max-width-300 {
        max-width: size(440);
        height: size(440);
        @media screen and (min-width: $breakpoint-lg) {
          width: size(440);
          max-width: unset;
        }
      }
       &.max-width-520 {
        max-width: size(360);
        height: size(380);
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(520);
          height: size(400);
        }
      }
    }
  }
  &.solution {
    .flex-box {
      .flex-item {
        &.two {
          display: none;
          @media screen and (min-width: $breakpoint-md) {
            display: unset;
          }
        }
      }
    }
  }
  .banner {
    &-title {
      font-size: var(--rs-title-level0-font-size);
      line-height: var(--rs-title-level0-line-height);
      font-weight: 800;
      color: var(--rs-white-color);
      margin-bottom: size(10);
      max-width: size(580);
    }
    &-para {
      font-size: var(--rs-solution-para-font-size);
      line-height: var(--rs-solution-para-line-height);
      color: var(--rs-white-color);
      margin-bottom: size(20);
      max-width: size(300);
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(600);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(540);
      }
    }
  }
}
</style>
<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  name: 'Banner',
  props: {
    banner: {
      type: Object,
    },
    maxWidth: {
      type: String,
    },
    sectionType: {
      type: String,
    },
    isGraphql: {
      type: Boolean,
    },
    showBannerImage: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TextButton,
  },
};
</script>
