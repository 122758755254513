<template>
  <div class="get-started-step" :class="layoutType">
    <div class="content">
      <p class="step-number">{{ stepTitle }}</p>
      <h2 class="title">{{ stepData.detail.name }}</h2>
      <p class="para">{{ stepData.detail.desc }}</p>
      <div class="hero-links" v-if="stepData.detail.links && stepData.detail.links.length">
        <template v-for="(link,index) in stepData.detail.links">
          <TextButton :buttonType="buttonType" :key="index" :link="link" />
        </template>
      </div>
    </div>
    <div class="image-wrapper">
      <div class="image-sec">
        <rz-image-item v-if="stepData.detail.img" :image="stepData.detail.img" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.get-started-step {
  .content {
    max-width: size(460);
    margin: unset;
    .step-number {
      font-size: size(18);
      line-height: size(25);
      letter-spacing: size(1.6);
      text-transform: uppercase;
      margin-bottom: size(10);
      font-weight: 700;
      color: var(--rs-light-lavender-color);
    }
    h2.title {
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
      margin-bottom: size(10);
      color: var(--rs-white-color);
      font-weight: 700;
      padding-right: unset;
    }
    .para {
      font-size: var(--rs-para-font-size);
      line-height: var(--rs-para-line-height);
      margin-bottom: size(10);
    }
  }
  .image-wrapper {
    padding-top: size(50);
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: size(25);
    }
    .image-sec {
      max-width: size(780);
      height: size(480);
    }
  }
  &.one {
    .content {
      margin-right: auto;
    }
    .image-sec {
      margin-left: auto;
    }
  }
  &.two {
    .content {
      margin-left: auto;
    }
    .image-sec {
      margin-right: auto;
    }
  }
  &.two-column {
    display: flex;
    flex-direction: column;
    .image-sec {
      max-height: size(300);
    }
    .image-wrapper {
      padding-top: size(0);
    }
    @media screen and (min-width: $breakpoint-md) {
      .image-sec {
        max-height: size(375);
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
      .image-wrapper {
        margin-left: auto;
      }
    }
  }
}
</style>
<style lang="scss">
.get-started-step {
  .image-wrapper {
    .image-item {
      .placeholder-data {
        text-align: left;
        display: none;
      }
    }
  }
}
</style>
<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  name: 'StepSection',
  props: {
    stepData: {
      type: Object,
    },
    stepTitle: {
      type: String,
    },
    layoutType: {
      type: String,
    },
    buttonType: {
      type: String,
    },
  },
  components: {
    TextButton,
  },
};
</script>
