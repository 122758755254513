<template>
  <div class="package-resource price-table" v-if="filteredLinks">
    <div class="sticky-header" id="sticky-header">
      <div class="rs-container">
        <table>
           <thead>
              <tr>
                <th></th>
                <th v-for="(type, i) in packageTypes"
                :key="type.key">
                  <a
                    v-if="type !== 'Enterprise' && type !== 'Business +'"
                    :href="'/v5/package/'+packagePlans[i].name.split(' ').join('-').toLowerCase()"
                    class="rs-sm-para bold"
                    :class="type"
                  >
                    {{type}}
                  </a>
                  <a v-else-if="type === 'Business +' && type !== 'Enterprise'"
                    :href="'/v5/package/business+'" :class="type" class="rs-sm-para bold">
                    {{type}}
                  </a>
                  <template v-else>
                    <a class="rs-sm-para bold" :class="type" href="/v5/contact-sales">Enterprise</a>
                  </template>
                  <template v-if="packagePlans && packagePlans[i]">
                    <div class="price-container">
                      <p class="annual-price text" v-if="packagePlans[i].price.price_by_volume_discounts">
                        {{formatCurrency(packagePlans[i].price.price_by_volume_discounts["12"][currency], currency)}}
                        <span>/month</span>
                      </p>
                      <span class="unit-label text">billed annually</span>
                      <p class="unit-label text monthly" v-if="packagePlans[i].price.unit_amount">
                        <span>{{formatCurrency(packagePlans[i].price.unit_amount[currency], currency)}}</span>
                        billed monthly
                      </p>
                    </div>
                  </template>
                  <p v-else >
                    <a class="product-pricing sales-link" href="/v5/contact-sales">Contact Sales
                    </a>
                  </p>
                </th>
              </tr>
            </thead>
        </table>
      </div>
    </div>
    <div class="container rs-container table pricing">
      <table>
        <thead>
          <tr>
            <th></th>
            <th v-for="(type, i) in packageTypes"
            :key="type.key">
              <a
                v-if="type !== 'Enterprise' && type !== 'Business +'"
                :href="'/v5/package/'+packagePlans[i].name.split(' ').join('-').toLowerCase()"
                class="rs-sm-para bold"
                :class="type"
              >
                {{type}}
              </a>
              <a v-else-if="type === 'Business +' && type !== 'Enterprise'"
                class="rs-sm-para bold"
                :class="type"
                :href="'/v5/package/business+'" >
                {{type}}
              </a>
              <template v-else>
                <a class="rs-sm-para bold" :class="type" href="/v5/contact-sales">Enterprise</a>
              </template>
              <template v-if="packagePlans && packagePlans[i]">
                <div class="price-container">
                  <p class="annual-price text" v-if="packagePlans[i].price.price_by_volume_discounts">
                    {{formatCurrency(packagePlans[i].price.price_by_volume_discounts["12"][currency], currency)}}
                    <span>/month</span>
                  </p>
                  <span class="unit-label text">billed annually</span>
                  <p class="unit-label text monthly" v-if="packagePlans[i].price.unit_amount">
                    <span>{{formatCurrency(packagePlans[i].price.unit_amount[currency], currency)}}</span>
                    billed monthly
                  </p>
                </div>
              </template>
              <p v-else >
                <a class="product-pricing sales-link" href="/v5/contact-sales">Contact Sales
                </a>
              </p>
            </th>
          </tr>
        </thead>
        <tbody id="pricing">
          <template v-for="endpoints in apiEndpoints[0].linkslist.links">
            <template v-for="endpoint in endpoints">
              <tr class="body-header"
                v-if="endpoint && endpoint.name" :key="endpoint.key">
                <td data-label>{{ endpoint.name.endsWith('s') ? endpoint.name: (endpoint.name+'s')}}</td>
                <td data-label></td>
                <td data-label></td>
                <td data-label></td>
                <td data-label></td>
              </tr>
              <template v-if="endpoint && endpoint.name">
                <template v-for="link in endpoint.links">
                  <tr class="body-data" :key="link.name" >
                    <td class="header-name" :class="'header-'+link.name.toLowerCase().split(' ').join('-')">
                      <rz-link-item :link="link" class="link-name"/>
                      <div v-if="(link.name === 'Wagon Zone'
                        || link.name === 'Wagon Zone SVG')"
                        class="tooltip">
                        Exclusive For IPL
                      </div>
                    </td>
                    <td :class="[!essentialsRestricted.includes(link.name) &&
                      (endpoint.name !== 'fantasy endpoint' && endpoint.name !== 'stats endpoint'
                       && endpoint.name !== 'match odds endpoint'
                      && endpoint.name !== 'Advanced Stat Endpoint')
                      ? 'available' : 'unavailable']"
                      data-label="Essential"
                    ></td>
                    <td :class="[!businessRestricted.includes(link.name)
                    && endpoint.name !== 'match odds endpoint'
                      ? 'available' : 'unavailable']"
                      data-label="Business"></td>
                    <td :class="[!businessPlusRestricted.includes(link.name)
                      && endpoint.name !== 'match odds endpoint'
                      && endpoint.name !== 'Advanced Stat Endpoint'
                        ? 'available' : 'unavailable']"
                        data-label="Business+">
                    </td>
                    <td class="available"
                      data-label="Enterprise"></td>
                  </tr>
                </template>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <div class="package-features-block">
      <div class="rs-container">
        <table>
          <thead>
            <tr>
              <th></th>
              <th v-for="type in packageTypes"
              :key="type.key">
                <p
                class="rs-sm-para bold">{{type}}</p>
              </th>
            </tr>
          </thead>
          <tbody id="pricing" v-for="values in otherFeatures" :key="values.key">
            <template v-for="features in values">
              <tr class="body-header"
                :key="features.key" v-if="features.name">
                <td data-label>{{features.name}} </td>
                <td data-label></td>
                <td data-label></td>
                <td data-label></td>
                <td data-label></td>
              </tr>
              <template v-for="feature in features.data">
                <tr class="body-data" :key="feature.key">
                  <td class="header-name">
                    <a v-if="features.name === 'Coverage'" href="/v5/coverage/">{{feature.name}}</a>
                    <template v-else> {{feature.name}}</template>
                  </td>
                  <td
                    v-for="(val, id) in feature.comparison" :key="val.key"
                    :class="{
                      'available': (val === true),
                      'unavailable': (val === false)}"
                    :data-label="id === 'businessPlus' ? 'Business+' : id"
                  >
                    <span
                      v-if="val !== true
                      && val !== false"
                      :class="{'text-card' : (val === 'Customisable')}"
                    >
                      <template v-if="val >= 0">
                        {{ val.toLocaleString() }}
                      </template>
                      <template v-else>
                        {{val}}
                      </template>
                    </span>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { PackageFeatures, OtherFields, PackageCoverage } from '../../stories/PackageFeatures';

export default {
  data() {
    return {
      apiEndpoints: null,
      otherFeatures: {
        packageCoverage: PackageCoverage(),
        packageFeatures: PackageFeatures(),
        otherFields: OtherFields(),
      },
      apiList: [],
      packageTypes: ['Essential', 'Business', 'Business +', 'Enterprise'],
      essentialsRestricted: [
        'Match Via Push',
        'Match Ball-by-Ball',
        'Match Overs Summary',
        'Grouped Tournament',
        'Worm',
        'Manhattan',
        'Run Rate',
        'Fixtures',
        'Wagon Zone SVG',
        'Wagon Zone',
        'Fantasy Team Insights',
        'Tournament Points Matrix',
        'Competition Ball by Ball Dataset',
        'Player Stats Dataset'],
      businessRestricted: [
        'Match Via Push',
        'Fixtures',
        'Grouped Tournament',
        'Worm',
        'Manhattan',
        'Run Rate',
        'Fantasy Match Points via Push',
        'Wagon Zone SVG',
        'Wagon Zone',
        'Wagon Zone',
        'Fantasy Team Insights',
        'Tournament Points Matrix',
        'Competition Ball by Ball Dataset',
        'Player Stats Dataset'],
      businessPlusRestricted: ['Fixtures', 'Fantasy Team Insights',
        'Tournament Points Matrix',
        'Competition Ball by Ball Dataset',
        'Player Stats Dataset'],
    };
  },
  props: {
    pricing: Object,
    packagePlans: Object,
  },
  computed: {
    ...mapState({
      currency: (state) => state.user.activeCurrency,
      links: (state) => state.link.rzSubMenu.links,
    }),
    filteredLinks() {
      if (this.links) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.apiEndpoints = this.links.filter((val) => val.name === 'API Endpoints');
      }
      return this.links;
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      const element = document.getElementById('sticky-header');
      const scrollVal = window.scrollY;
      if (scrollVal > 1900) {
        element.className = 'sticky-header add-header';
      } else {
        element.className = 'sticky-header';
      }
    });
  },
};
</script>
<style lang="scss" scoped>
  .package-resource {
    --rs-license-border-color: #dadada;
    color: var(--ca-title-color);
    position: relative;

    .sticky-header {
      display: none;
      @media screen and (min-width: $breakpoint-lg) {
        position: sticky;
        top: size(65);
        left: 0;
        right: 0;
        background: #FBFBFB;
        border-bottom: size(1) solid var(--rs-license-border-color);
        text-align: center;
        .rs-container {
          padding: size(16) 0;
        }
        z-index: -1;
        display: block;
        opacity: 0;
        height: 0;
        transition: opacity 0.3s ease;
        &.add-header {
          z-index: 2;
          opacity: 1;
          height: size(120);
        }
      }
    }

    .product-pricing {

      font-size: size(14);

       &.sales-link {
        text-decoration: underline;
        color: #333333;
        font-size: size(12);
       }

       line-height: size(28);
      .month-price {
        color: var(--rs-gray-color);
        font-size: size(12);
        margin-left: size(6);
        font-weight: 500;
      }


      @media screen and (min-width: $breakpoint-lg ) {
        font-size: size(16);

        &.sales-link {
          font-size: size(14);
        }
      }


    }
    table {
      border-collapse: collapse;
      margin: 0 auto;
      width: 100%;
      table-layout: fixed;
      padding: 0 size(15);
      margin-bottom: size(18);
      scroll-behavior: smooth;
      thead {
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: table-header-group;
        }
        tr {
          border: none;
          display: flex;
          flex-wrap: wrap;
          @media screen and (min-width: $breakpoint-md) {
            display: table-row;
          }
          &.top {
            @media screen and (min-width: $breakpoint-md) {
              padding-bottom: 0;
              th {
                padding-bottom: 0;
              }
            }
            th {
              &:first-child {
                font-weight: bold;
              }
            }
          }
          th {
            background: var(--rs-white-color);
            z-index: var(--senior-menu-z-index);
            margin: size(20) 0;
            border: size(3) solid var(--rs-license-border-color);
            min-width: 89%;
            background: #FBFBFB;
            border-radius: size(6);
            &:first-child {
              min-width: 0;
            }
            @media screen and (min-width: $breakpoint-md) {
              margin: unset;
              border: unset;
              min-width: unset;
            }
            &:last-child {
              display: none;
              @media screen and (min-width: $breakpoint-md) {
                display: table-cell;
                vertical-align: top;
              }
            }
            a, .text, .title1 {
              display: flex;
              align-items: center;
              &::before {
                display: inline-block;
                content: '';
                width: size(8);
                height: size(8);
                border-radius: 50%;
                margin-right: size(8);
              }
              &.Essential {
                &::before {
                  background: #A9A8F6;
                }
              }
              &.Business {
                &::before {
                  background: #96EAA4;
                }
              }
              &.Business {
                &::before {
                  background: #ffd281;
                }
              }
              &.Enterprise {
                &::before {
                  background: #8ec7ff;
                }
              }
            }
          }
        }
      }
      tbody {
        z-index: var(--goto-top-z-index);
        tr {
          &:first-child {
            td {
              &:first-child {
                @media screen and (min-width: $breakpoint-md) {
                  padding-top: size(50);
                }
              }
            }
          }
          &.body-header {
            border: none;
            text-decoration: underline;
            padding: 0;
            @media screen and (min-width: $breakpoint-md) {
              border-bottom: size(1) solid var(--rs-license-border-color);
              padding: unset;
              text-decoration: none;
            }
            td {
              padding: 0;
              font-size: size(12);
              line-height: size(17);
              font-weight: bold;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #333;
              text-align: left;
              border: none;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(40) 0 size(18);
              }
              &:not(:first-child) {
                display: none;
                @media screen and (min-width: $breakpoint-md) {
                  display: table-cell;
                }
              }
            }
          }
          &.body-data {
            &:last-child {
              border: size(3) solid var(--rs-license-border-color);
              border-radius: size(6);
              @media screen and (min-width: $breakpoint-md) {
                border: none;
              }
            }
            .available {
              background-image: url(../assets/rsCricket/correct.svg);
              background-repeat: no-repeat;
              background-position: 90% 50%;
              @media screen and (min-width: $breakpoint-md) {
                background-position: size(20);
              }
            }
            .unavailable {
              background-image: url(../assets/rsCricket/wrong.svg);
              background-repeat: no-repeat;
              background-position: 90% 50%;
              @media screen and (min-width: $breakpoint-md) {
                background-position: size(20);
              }
            }
          }
          td {
            font-size: size(15);
            line-height: size(21);
            color: #181818;
            text-align: right;
            @media screen and (min-width: $breakpoint-md) {
              text-align: left;
            }
            &.header-name {
              color: var(--rs-light-black-color);
              text-align: left;
              font-size: size(15);
              line-height: size(21);
              color: #121212;
              padding-top: 0;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(18) 0;
                font-size: size(15);
                line-height: size(21);
                padding-right: size(20);
              }
              a {
                color: var(--rs-white-color);
                &:hover {
                  color: var(--rz-link-color);
                }
              }
              span {
                display: block;
                padding-top: size(4);
                font-size: size(14);
                line-height: size(18);
                color: #585757;
                max-width: size(250);
              }
            }
            .text-card {
              padding: size(1) size(8);
              border-radius: size(3);
              font-size: size(12);
              line-height: size(17);
              font-weight: 600;
              color: #8A73FF;
              margin-left: size(20);
              background: rgba(166, 149, 255, 0.2);
            }
          }
          span {
            display: inline-block;
            @media screen and (min-width: $breakpoint-md) {
              padding: size(5) size(20);
            }
          }
        }
        .btn-group {
          border-bottom: none;
          display: none;
          @media screen and (min-width: $breakpoint-md) {
            display: table-row;
          }
        }
      }
      tr {
        border: size(3) solid var(--rs-license-border-color);
        border-radius: size(6);
        padding: size(18) 0;
        margin: size(15) 0;
        display: block;
        @media screen and (min-width: $breakpoint-md) {
          border: none;
          border-bottom: size(1) solid var(--rs-license-border-color);
          display: table-row;
        }
        th,
        td {
          text-align: left;
          padding: size(15);
          width: 90%;
          vertical-align: middle;
          @media screen and (min-width: $breakpoint-md) {
            padding: 0;
            width: 30%;
          }
          &:not(:last-child) {
            padding-right: size(15);
            @media screen and (min-width: $breakpoint-md) {
              padding-right: size(25);
            }
            @media screen and (min-width: $breakpoint-lg) {
              padding-right: size(30);
            }
          }
          &:first-child {
            width: 90%;
            @media screen and (min-width: $breakpoint-md) {
              width: 60%;
            }
          }
        }
        td {
          display: block;
          width: 90%;
          &::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
            word-break: break-all;
            width: size(100);
            text-align: left;
            &:first-child {
              content: '';
            }
          }
          &:after {
            content: "";
            clear: both;
            display: block;
          }
          &:last-child {
            border-bottom: 0;
          }
          @media screen and (min-width: $breakpoint-md) {
            display: table-cell;
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .pricing {
      &.rs-container {
        padding-bottom: size(30);
      }
    }

    .price-container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      padding-top: size(10);


      img {
        max-width: 90%;
      }
    }
    .annual-price {
      font-weight: 700;
      font-size: size(14);
      line-height: size(16);
      color: var(--rs-black-color);
      padding-bottom: size(4);

      span {
        font-size: size(10);
        line-height: size(12);
        font-weight: 500;
        color: #777;
        display: block;
        @media screen and (min-width: 1024px) {
          display: inline-block;
          font-size: size(14);
          line-height: size(18);
        }
      }
    }
    .unit-label {
      font-weight: 400;
      font-size: size(12);
      line-height: size(17);

      span {
        font-weight: 500;
        color: var(--rs-black-color);
        padding-right: size(2);
      }

      &.monthly {
        @media screen and (max-width: 1024px) {
          flex-direction: column;
          align-items: start;
          margin-left: size(18);
        }
      }
    }
    .package-features-block {
      background: #111111;
      .rs-container {
        padding-top: size(20);
      }
      table {
        thead {
          visibility: hidden;
        }
        tbody {
          tr {
            @media screen and (min-width: $breakpoint-md) {
              border-bottom: size(1) solid #3A3A3A;
            }
            td {
              color: var(--rs-white-color);
            }
            &.body-header {
              td {
                padding-top: size(40);
                padding-bottom: size(18);
                color: #777777;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
.price-table {
  .rs-button {
    &.black-cta {
      > a {
        margin-top: size(4);
      }
    }
  }
}


.header-wagon-zone-svg, .header-wagon-zone {
  position: relative;

  a {
    border-bottom: 1px dotted var(--rs-black-color);
  }

  .link-item {
    transition: opacity 0.3 linear;
  }

  .tooltip {
    opacity: 0;
    position: absolute;
    top: size(-10);
    left: size(70);
    padding: size(6);
    z-index: 1;
    font-size: size(12);
    line-height: size(15);
    background: var(--rs-white-color);
    box-shadow: size(1) size(2) size(1) size(2) var(--rs-gray-color);
    border-radius: size(6);
    transition: opacity 0.3 linear;
  }

  .link-item:hover + .tooltip {
    opacity: 1;
  }
}
</style>
