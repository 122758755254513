export function PackageFeatures() {
  return [
    {
      name: 'Features',
      data: [
        {
          name: 'Match Count per month',
          comparison: {
            essential: '100 matches',
            business: '200 matches',
            businessPlus: 'Unlimited matches',
            enterprise: 'Customisable',
          },
        },
        {
          name: 'API Request limit per month',
          comparison: {
            essential: 150000,
            business: 300000,
            businessPlus: 1000000,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Active access tokens per project',
          comparison: {
            essential: 100,
            business: 300,
            businessPlus: 500,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Concurrent requests per access token',
          comparison: {
            essential: 32,
            business: 64,
            businessPlus: 128,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Apps per Subscription plan',
          comparison: {
            essential: 'One',
            business: 'One',
            businessPlus: 'One',
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Access to completed matches and tournaments (Historic data)',
          comparison: {
            essential: 'No Access',
            business: '1 month',
            businessPlus: '3 months',
            enterprise: 'Customisable',
          },
        },
        // {
        //   name: 'Access to retired players and teams',
        //   comparison: {
        //     essential: 'Feature Not Included',
        //     business: 'Limited access',
        //     businessPlus: 'Full access',
        //     enterprise: 'Full access',
        //   },
        // },
      ],
    },
    {
      name: 'Support',
      data: [
        {
          name: 'Slack',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: true,
          },
        },
        {
          name: 'Intercom',
          comparison: {
            essential: true,
            business: true,
            businessPlus: true,
            enterprise: true,
          },
        },
        {
          name: 'Priority Email Support',
          comparison: {
            essential: false,
            business: true,
            businessPlus: true,
            enterprise: true,
          },
        },
        {
          name: 'Expert Technical Support',
          comparison: {
            essential: false,
            business: false,
            businessPlus: true,
            enterprise: true,
          },
        },
        {
          name: 'SLA',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
      ],
    },
    {
      name: 'DevOps',
      data: [
        {
          name: 'Dedicated Server',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Access to Beta APIs',
          comparison: {
            essential: false,
            business: false,
            businessPlus: true,
            enterprise: true,
          },
        },
        {
          name: 'Access to Internal monitoring tools',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: true,
          },
        },
        {
          name: 'Data Push via AWS Event Bridge, AWS SQS',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Data CSV push to AWS S3',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
      ],
    },
  ];
}
export function PackageCoverage() {
  return [
    {
      name: 'Coverage',
      data: [
        {
          name: 'MG100',
          comparison: {
            essential: true,
            business: true,
            businessPlus: true,
            enterprise: true,
          },
        },
        {
          name: 'MG101',
          comparison: {
            essential: true,
            business: true,
            businessPlus: true,
            enterprise: true,
          },
        },
      ],
    },
  ];
}
export function OtherFields() {
  return [
    {
      name: 'Additional Features',
      data: [
        {
          name: 'Match list CSV/Parquet',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: true,
          },
        },
        {
          name: 'Tournament list CSV/Parquet',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: true,
          },
        },
        {
          name: 'News aggregation API',
          comparison: {
            essential: false,
            business: false,
            businessPlus: true,
            enterprise: true,
          },
        },
        {
          name: 'Country Flags API',
          comparison: {
            essential: false,
            business: false,
            businessPlus: true,
            enterprise: true,
          },
        },
        {
          name: 'Fantasy Credit Editor',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Custom build APIs',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Cricket Widget API',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Cricket Chatbot API',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
        {
          name: 'Ants & Falcon Editor',
          comparison: {
            essential: false,
            business: false,
            businessPlus: false,
            enterprise: 'Customisable',
          },
        },
      ],
    },
  ];
}
