<template>
  <div>
    <div class="rs-blog-detail blog-page" v-if="dataLoaded">
      <section v-for="(section, index) in page.sections" :key="index">
        <div class="blog-content">
          <div
            class="max-width-600"
            :class="'section-' + index"
            v-if="section.static_section_id === 'blog_section_1'"
          >
            <h1>{{ section.detail.name }}</h1>
            <rz-image-item v-if="section.detail.img" :image="section.detail.img" />
            <rz-markdown class="brief" :text="section.detail.desc" />
          </div>
          <div :class="'section-' + index" v-if="section.static_section_id === 'blog_section_2'">
            <rz-image-item v-if="section.detail.img" :image="section.detail.img" />
            <rz-markdown :text="section.detail.desc" class="para" />
          </div>
          <div
            :class="'section-' + index"
            v-if="section.static_section_id === 'blog_section_3'"
          >
            <div class="content max-width-600">
              <rz-image-item v-if="section.detail.img" :image="section.detail.img" />
              <rz-markdown :text="section.detail.desc" />
            </div>
          </div>
        </div>
        <section
          v-if="section.static_section_id == 'committed_usage'"
        >
          <BlogCommittedUsage
            class="pay-go-wrap"
            :committedUseItems="committedUseItems"
            :committedUsageInfo="section"
            v-if="committedUseItems && section"
          />
        </section>
        <PaymentSignupSection
          v-if="section.static_section_id == 'signup_wrapper'"
          :signupInfo="section"
          :blogListStatus="blogListStatus"
          :blogList="blogList"
        />
      </section>
    </div>
    <div class="blog-detail-404" v-else-if="pageErrorState">
      <h1 class="header">Oops! Blog not found.</h1>
      <p class="para">The blog you are looking for was removed, renamed or never existed!</p>
    </div>
    <div class="content placeholder" v-else>
      <CricketLoadingPlaceholder :state="pageState" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import BlogCommittedUsage from '@/components/RsCricket/BlogCommitedUsage.vue';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
// import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  components: {
    PaymentSignupSection,
    CricketLoadingPlaceholder,
    BlogCommittedUsage,
    // TextButton,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageErrorState: (state) => state.page.activePageState.status.isError,
      page: (state) => state.page.activePage,
      committedUseItems: (state) => state.payments.wizard.committedUseItems,
      pageState: (state) => state.page.activePageState,
      blogList: (state) => state.blog.activeBlogList,
      blogListStatus: (state) => state.blog.activeBlogListState.status.isLoaded,
    }),
  },
  serverPrefetch() {
    return this.freshFetch();
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-cricket-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log(
          'Refreshing page because page mounted with different route',
        );
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  methods: {
    freshFetch() {
      return this.fetchData();
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter').then(() => {
        return this.fetchCommittedUseItem();
      });
    },
    fetchCommittedUseItem() {
      const { currency } = this;
      this.$store.dispatch('payments/fetchCommittedUseItems', { currency })
        .then((resp) => {
          console.log('committed use item', resp);
          console.log('currecy', this.currency);
        })
        .catch((err) => {
          console.log('Error while fetchCommittedUseItems');
          console.error(err);
          this.loadingState.ref = err;
        });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-cricket-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', {
          route,
          routeValue,
        });
      });
    },
  },
};
</script>
<style lang="scss">
.rs-blog-detail {
  .blog-committed-usage {
    .rs-container {
      max-width: size(600);
      margin: 0 auto;
    }
  }
}
</style>
<style lang="scss" scoped>
.rs-blog-detail {
  --rs-blog-detail-view-blue-color: #8571eb;
  color: var(--rs-black-color);
  margin: size(20) size(0) 0 size(0);
  @media screen and (min-width: $breakpoint-md) {
    margin: size(50) size(0) 0 size(0);
  }
  @media screen and (min-width: $breakpoint-lg) {
    margin: size(70) 0 0 0;
  }
  .max-width-600 {
    max-width: size(600);
    margin: auto;
    text-align: center;
  }
  .max-width-100 {
    max-width: size(100);
    margin: auto;
    text-align: center;
  }
  .blog-content {
    margin-left: size(15);
    margin-right: size(15);
    @media screen and (min-width: $breakpoint-lg) {
      margin-left: size(0);
      margin-right: size(0);
    }
  }
  .title {
    padding-bottom: 0;
  }
  p {
    &.category {
      color: var(--rs-blog-detail-view-blue-color);
      font-weight: bold;
      padding-bottom: 0;
    }
    &.title {
      margin-bottom: size(18);
    }
    &.brief {
      margin-bottom: size(18);
      font-size: var(--rs-title-level4-font-size);
      line-height: var(--rs-title-level4-line-height);
    }
    &.date {
      color: var(--rs-gray-color);
      font-size: var(--rs-sm-label-font-size);
      line-height: var(--rs-sm-label-line-height);
      padding-bottom: size(10);
      margin-top: size(10);
      @media screen and (min-width: $breakpoint-md) {
        margin-top: size(20);
      }
    }
  }
  .author-container {
    display: flex;
    flex-direction: column;
    margin-bottom: size(10);
    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      margin-bottom: size(20);
    }
    .author-name {
      padding-left: size(5);
      font-size: var(--rs-sm-label-font-size);
      line-height: var(--rs-sm-label-line-height);
      font-weight: 600;
      padding-bottom: 0;
    }
    .author {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        margin-left: size(0);
        @media screen and (min-width: $breakpoint-md) {
          margin-left: size(15);
        }
      }
      &:not(:last-child) {
        padding-bottom: size(5);
        @media screen and (min-width: $breakpoint-md) {
          padding-bottom: size(0);
        }
      }
    }
    .author-pic {
      width: size(35.2);
      height: size(35.2);
      border-radius: 50%;
      display: block;
      &-wrap {
        display: inline-block;
      }
    }
  }
  .main-image-wrap {
    text-align: center;
    margin: size(10) auto;
    @media screen and (min-width: $breakpoint-md) {
      margin: size(20) auto;
    }
  }
  .section {
    &-1 {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding-bottom: size(25);
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(700);
        flex-direction: row;
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(850);
      }
      .para {
        display: inline-block;
        align-self: center;
        max-width: size(320);
        @media screen and (min-width: $breakpoint-md) {
          margin-left: size(30);
        }
      }
      img {
        width: 100%;
        max-width: size(300);
        height: auto;
        border-radius: var(--rs-blog-border-radius);
        margin: size(15) size(0);
        @media screen and (min-width: $breakpoint-md) {
          max-width: size(350);
          margin: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
          max-width: size(570);
        }
      }
    }
    &-2 {
      .content {
        padding-bottom: size(80);
        @media screen and (min-width: $breakpoint-lg) {
          padding-bottom: size(120);
        }
        .blog-btn {
          display: flex;
          justify-content: center;
          padding: size(26) 0 0;
          margin-top: size(15);
          border-top: size(1) dashed var(--rs-gray-color);
        }
      }
    }
  }
}
.blog-detail-404 {
  max-width: size(850);
  margin: size(100) auto;
  font-family: var(--hero-font);
  h1 {
    font-size: var(--title-font-size);
    line-height: var(--title-line-height);
    margin: size(32) 0;
    font-weight: bold;
    letter-spacing: var(--title-letter-space);
  }
  .para {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
  }
}
</style>

<style lang="scss">
.blog-page {
  h1 {
    font-weight: bold;
    font-size: size(26);
    line-height: size(34);
    padding-bottom: size(10);
    color: var(--rs-black-color);
    text-align: left;
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(40);
      line-height: size(50);
      padding-bottom: size(6);
    }
  }
  h2 {
    color: var(--rs-black-color);
    font-weight: bold;
    font-size: size(20);
    line-height: size(24);
    padding: size(18) 0 size(6);
    padding-right: 0;
    text-align: left;
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(30);
      line-height: size(42);
    }
  }
  h3 {
    color: var(--rs-black-color);
    font-weight: bold;
    font-size: size(18);
    line-height: size(25);
    text-align: left;
    padding: size(18) 0 size(8);
  }
  .p {
    font-size: size(16);
    line-height: size(24);
    color: var(--rs-black-color);
    padding-bottom: size(12);
    display: block;
    text-align: left;
    &:empty {
      padding-bottom: 0;
    }
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(18);
      line-height: size(28);
    }
    a {
      text-decoration: underline;
      color: var(--rz-link-color);
    }
    em {
      font-style: italic;
      font-weight: normal;
    }
    strong {
      font-weight: bolder;
      em {
        font-weight: bolder;
      }
    }
  }
  p {
    font-size: size(16);
    line-height: size(24);
    color: var(--rs-black-color);
    padding-bottom: size(12);
    text-align: left;
    margin-bottom: 0;
    @media screen and (min-width: $breakpoint-md) {
      font-size: size(18);
      line-height: size(28);
    }
    &:empty {
      padding-bottom: 0;
    }
  }
  h4, h5, h6 {
    text-align: left;
  }
  ul {
    text-align: left;
    padding-left: size(20);
    &>li {
      list-style-type: disc;
      padding-bottom: size(12);
    }
    li {
      ol {
        padding-left: size(30);
        list-style-type: decimal;
        li {
          padding-bottom: size(6);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  ol {
    text-align: left;
    padding-left: size(20);
    &>li {
      list-style-type: decimal;
      padding-bottom: size(12);
    }
    li {
      ul {
        padding-left: size(30);
        list-style-type: disc;
        li {
          padding-bottom: size(6);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  code {
    background-color: rgba(0,0,0,.05);
    border-radius: size(3);
    padding: size(4) size(8);
    text-align: left;
  }
  pre {
    text-align: left;
    &>code {
      background-color: rgba(0,0,0,.05);
      display: block;
      padding: size(12);
      -webkit-text-size-adjust: none;
      overflow-x: auto;
      white-space: pre;
      margin-bottom: size(18);
    }
  }
  blockquote {
    text-align: left;
    background-image: url('../../assets/rsCricket/doublequotes.svg');
    background-repeat: no-repeat;
    background-position: 0% size(10);
    background-size: size(36) size(24);
    max-width: size(575);
    margin: 0 auto;
    .p {
      font-size: size(30);
      line-height: size(40);
      color: var(--rs-lavender-color);
      font-style: italic;
      font-weight: normal;
      padding-left: size(45);
      margin: size(12) 0;
      a {
        text-decoration: none;
        color: var(--rs-lavender-color);
      }
    }
    a {
      text-decoration: none;
      color: var(--rs-lavender-color);
    }
    h1,h2,h3 {
      color: var(--rs-lavender-color);
      font-style: italic;
      font-weight: normal;
      padding-left: size(45);
    }
  }
}
.rs-blog-detail {
  --rs-blog-border-radius: #{size(6)};
  .markdown {
    img {
      display: block;
      margin: size(25) auto;
      width: 100%;
      text-align: center;
      border-radius: var(--rs-blog-border-radius);
    }
    li {
      color: var(--rs-black-color);
      font-size: size(16);
      line-height: size(24);
      max-width: size(600);
      margin: auto;
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(18);
        line-height: size(28);
      }
      .signup-btn {
        color: var(--rz-link-color);
        display: inline-block;
        font-weight: 600;
        a {
          color: var(--rz-link-color);
        }
        &:hover {
          @include jump-up-animation;
        }
      }
      .block-btn a {
        font-size: var(--rs-title-level5-font-size);
        line-height: size(16);
        font-weight: bold;
        color: var(--rs-black-color);
        border: size(1) solid var(--rs-black-color);
        padding: size(14) size(24);
        display: inline-block;
        border-radius: size(5);
        padding-right: size(16);
        padding-left: size(16);
        @media screen and (min-width: $breakpoint-md) {
          padding-right: size(24);
          padding-left: size(24);
        }
        &:after {
          content: '';
          border: solid var(--rs-black-color);
          border-width: 0 size(2) size(2) 0;
          display: inline-block;
          padding: size(2.2);
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          margin-left: size(5);
          vertical-align: middle;
        }
        &:hover {
          @include jump-up-animation;
        }
      }
      .block-btn.yellow a {
        background: var(--rs-cta-green-color);
      }
      .block-btn.blue a {
        background: var(--rs-cta-blue-color);
        border: size(1) solid var(--rs-white-color);
        color: var(--rs-white-color);
        &:after {
          border: solid var(--rs-white-color);
          border-width: 0 size(2) size(2) 0;
        }
      }
    }
  }
  .section-2 {
    .markdown li:first-child .p {
      font-weight: 600;
    }
  }
  .section-1 {
    img {
      width: 100%;
      max-width: size(500);
      border-radius: var(--rs-blog-border-radius);
    }
    .image-item {
      padding: size(10) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(0) 0;
      }
    }
  }
  .main-image-wrap {
    img {
      max-width: size(500);
      width: 100%;
      height: auto;
      border-radius: var(--rs-blog-border-radius);
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(600);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(870);
      }
    }
  }
}
</style>
