<template>
  <div class="rs-cricket-license">
    <div v-if="dataLoaded && page">
      <div v-if="page.sections && page.sections.length">
        <div v-for="(section, index) in page.sections" :key="index">
          <section v-if="section.static_section_id === 'banner'">
            <Banner :banner="section" :sectionType="'resource licenses'" :maxWidth="'max-width-520'"></Banner>
          </section>
        </div>
        <div class="container">
          <table class="license-comparision-table">
            <thead>
              <tr
                class="top"
              >
                <th></th>
                <th>
                  <p class="license-name">Standard</p>
                  <div
                    class="license-amount"
                  >
                    <p v-if="priceDetails.standard.monthly">
                      <span class="annual-price">{{formatCurrency(priceDetails.standard.monthly &&
                        priceDetails.standard.monthly.price.unit_amount[currency], currency)}}
                      </span>
                    </p>
                  </div>
                  <router-link class="license-info" :to="{name: 'rsPricing'}">
                    Plus API Usage charges
                  </router-link>
                  <TextButton
                    class="license-cta"
                    :buttonType="'rs-button blue-cta lg'"
                    :link="licenseLink('standard')"
                  />
                </th>
                <th>
                  <p class="license-name">Premium</p>
                  <div
                    class="license-amount"
                  >
                    <p v-if="priceDetails.premium.annual">
                      <span class="annual-price">{{formatCurrency(priceDetails.premium.annual &&
                        priceDetails.premium.annual.price.unit_amount[currency], currency)}}
                      </span>
                      <span>Per month billed annually</span>
                    </p>
                    <p v-if="priceDetails.premium.monthly">
                      <span class="monthly-price">{{formatCurrency(priceDetails.premium.monthly &&
                        priceDetails.premium.monthly.price.unit_amount[currency], currency)}}
                      </span>
                      <span>billed monthly</span>
                    </p>
                  </div>
                  <router-link class="license-info" :to="{name: 'rsPricing'}">
                    Plus API Usage charges
                  </router-link>
                  <TextButton
                    class="license-cta"
                    :buttonType="'rs-button blue-cta lg'"
                    :link="licenseLink('enterprisea101')"
                  />
                </th>
                <th>
                  <p class="license-name">Enterprise</p>
                  <div
                    class="license-amount"
                  >
                    <p v-if="enterprise.annual">
                      <span class="annual-price">{{formatCurrency(enterprise.annual &&
                        enterprise.annual[currency], currency)}}
                      </span>
                      <span>Per month billed annually</span>
                    </p>
                    <p v-if="enterprise.monthly">
                      <span class="monthly-price">{{formatCurrency(enterprise.monthly[currency], currency)}}
                      </span>
                      <span>billed monthly</span>
                    </p>
                  </div>
                  <router-link class="license-info" :to="{name: 'rsPricing'}">
                    Plus API Usage charges
                  </router-link>
                  <TextButton
                    class="license-cta"
                    :buttonType="'rs-button blue-cta lg'"
                    :link="licenseLink('enterprisea101')"
                  />
                </th>
              </tr>
              <tr class="bottom">
                <th></th>
                <th>
                  <TextButton :buttonType="'rs-button blue-cta lg'" :link="licenseLink('standard')" />
                </th>
                <th>
                  <TextButton :buttonType="'rs-button blue-cta lg'" :link="licenseLink('enterprisea101')" />
                </th>
                <th>
                  <TextButton :buttonType="'rs-button blue-cta lg'" :link="licenseLink('enterprisea101')" />
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(licenseHeader, mainIndex) in licenseInfo">
                <tr class="body-header" :class="'level-'+mainIndex" :key="licenseHeader.name">
                  <td data-label>{{ licenseHeader.name }}</td>
                  <td data-label></td>
                  <td data-label></td>
                  <td data-label></td>
                </tr>
                <template v-for="(licenseAccessInfo) in licenseHeader.data">
                  <tr class="body-data" :key="licenseAccessInfo.name">
                    <td class="header-name" data-label>{{ licenseAccessInfo.name }}</td>
                    <td
                      v-for="(license, index) in licenseTypes"
                      :key="index"
                      :data-label="license.name"
                      :class="{
                        'available': (licenseAccessInfo.comparision[license.id] === true),
                        'unavailable': (licenseAccessInfo.comparision[license.id] === false),
                        'none': (licenseAccessInfo.comparision[license.id] === 'none') }"
                    >
                      <span
                        v-if="licenseAccessInfo.comparision[license.id] !== true
                        && licenseAccessInfo.comparision[license.id] !== false
                        && licenseAccessInfo.comparision[license.id] !== 'none'"
                        :class="{'enterprise':
                        (license.id !== 'rs_paygo_standard')}"
                      >
                        <template v-if="licenseAccessInfo.comparision[license.id][currency]">
                          {{ licenseAccessInfo.comparision[license.id].desc }}
                          {{ formatCurrency(licenseAccessInfo.comparision[license.id][currency], currency) }}
                        </template>
                        <template v-else>
                          {{ licenseAccessInfo.comparision[license.id] }}
                        </template>
                      </span>
                      <span class="hidden"
                       :class="{'enterprise':
                        (license.id !== 'rs_paygo_standard')}" v-else>none
                      </span>
                    </td>
                  </tr>
                </template>
              </template>
              <tr class="btn-group">
                <td></td>
                <td>
                  <TextButton :buttonType="'rs-button blue-cta lg'" :link="licenseLink('standard')" />
                </td>
                <td>
                  <TextButton :buttonType="'rs-button blue-cta lg'" :link="licenseLink('enterprisea101')" />
                </td>
                <td>
                  <TextButton :buttonType="'rs-button blue-cta lg'" :link="licenseLink('enterprisea101')" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-for="(section, index) in page.sections" :key="'sec'+index">
          <section v-if="section.static_section_id === 'explore_other'">
            <ExploreNewPage :explorePageInfo="section" />
          </section>
          <section class="rs-support-block" v-if="section.static_section_id == 'support_block'">
            <div class="rs-container max-width-1080">
              <support-wrapper />
            </div>
            <section class="customer-logo">
              <img src="@/assets/rsCricket/customer-logo.png" alt="Customer logos" />
            </section>
          </section>
          <PaymentSignupSection
            v-if="section.static_section_id == 'singup_wrapper'"
            :signupInfo="section"
          />
        </div>
      </div>
    </div>
    <div class="content placeholder" v-else>
      <CricketLoadingPlaceholder :state="pageState" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import TextButton from '@/components/RZWebsite/TextButton.vue';
import ExploreNewPage from '@/components/RsCricket/ExploreNewPage.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import { licenseInfo } from '../../../stories/LicenseInfo';

export default {
  name: 'rsCricketHome',
  components: {
    CricketLoadingPlaceholder,
    Banner,
    TextButton,
    ExploreNewPage,
    PaymentSignupSection,
    SupportWrapper,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      payGoResources: (state) => state.paygo.payGoResources,
      priceDetails() {
        if (this.payGoResources && this.payGoResources.licenses) {
          return this.payGoResources.licenses;
        }
        return 'none';
      },
    }),
  },
  serverPrefetch() {
    return this.fetchData().then(() => {
      return this.fetchLicenses();
    });
  },
  data() {
    return {
      contact: {
        name: 'Contact',
        url: '#',
      },
      licenseInfo: licenseInfo(),
      licenseTypes: [
        {
          id: 'rs_paygo_standard',
          name: 'Standard',
        },
        {
          id: 'rs_paygo_premium',
          name: 'Premium',
        },
        {
          id: 'rs_paygo_enterprise_a101',
          name: 'Enterprise',
        },
      ],
      enterprise: {
        monthly: {
          usd: 299,
          inr: 22599,
          eur: 259,
        },
        annual: {
          usd: 229,
          inr: 16999,
          eur: 199,
        },
      },
    };
  },
  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      // eslint-disable-next-line operator-linebreak
      const sameRoute =
        this.page.route_value === `rs-cricket-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log(
          'Refreshing page because page mounted with different route',
        );
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      this.fetchLicenses();
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchLicenses();
      });
    },
    fetchLicenses() {
      return this.$store.dispatch('paygo/fetchLicenses').catch((err) => {
        console.log('fetch Page error', err);
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-cricket-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', {
          route,
          routeValue,
        });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    licenseLink(licenseKey) {
      return {
        name: 'Get Started',
        routeParams: {
          name: 'planReview',
          query: { license: licenseKey },
        },
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.rs-cricket-license {
  --rs-license-bordder-color: #dadada;
  --rs-license-z-index-0: 0;
  --rs-license-z-index-1: 1;
  --rs-license-z-index-2: 2;
  --rs-license-z-index-3: 3;
  --rs-license-z-index-4: 4;
  --rs-license-black-color: #181818;
  --rs-solution-pad-horizontal: #{size(20)};
  * {
    box-sizing: border-box;
    .solution-banner.resource {
      box-sizing: initial;
    }
  }
  .pad-top-10 {
    padding-top: size(10);
  }
  .pad-top-6 {
    padding-top: size(6);
  }
  .pad-top-30 {
    padding-top: size(30);
  }
  .inline-block {
    display: inline-block;
  }
  .container {
    margin: auto;
    padding: 0 var(--rs-solution-pad-horizontal);
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(1080);
      padding: 0;
    }
  }
  .max-width-1440 {
    max-width: size(1440);
    margin: 0 auto;
    position: relative;
  }
  .customer-logo {
    padding: 0 size(20);
    text-align: center;
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(0);
    }
    img {
      width: 100%;
      max-width: size(1000);
    }
  }

  table {
    border-collapse: collapse;
    margin: 0;
    width: 100%;
    max-width: size(1080);
    table-layout: fixed;
    padding: 0 size(15);
    margin-bottom: size(18);
    thead {
      tr {
        border: none;
        display: flex;
        flex-wrap: wrap;
        @media screen and (min-width: $breakpoint-md) {
          display: table-row;
        }
        &.top {
          @media screen and (min-width: $breakpoint-md) {
            padding-bottom: 0;
            th {
              padding-bottom: 0;
            }
          }
        }
        th {
          background: var(--rs-white-color);
          z-index: var(--senior-menu-z-index);
          margin: size(15) 0;
          border: size(3) solid var(--rs-license-bordder-color);
          min-width: 100%;
          border-radius: size(6);
          &:first-child {
            min-width: 0;
          }
          @media screen and (min-width: $breakpoint-md) {
            margin: unset;
            border: unset;
            min-width: unset;
          }
          &:first-child {
            display: none;
            @media screen and (min-width: $breakpoint-md) {
              display: table-cell;
            }
          }
        }
        .license {
          &-name {
            font-size: var(--rs-title-level5-font-size);
            line-height: var(--rs-title-level5-line-height);
            color: var(--rs-light-black-color);
            margin-bottom: size(10);
          }
          &-amount {
            padding-bottom: size(18);
            p {
              font-size: size(12);
              line-height: size(17);
              span {
                font-size: size(12);
                line-height: size(17);
                color: var(--rs-black-color);
                &.annual-price {
                  font-size: var(--rs-title-level2-font-size);
                  line-height: var(--rs-title-level2-line-height);
                  color: var(--ca-title-color);
                  padding-bottom: size(4);
                  font-weight: bold;
                  display: block;
                }
                &.monthly-price {
                  font-weight: 500;
                }
              }
            }
          }
          &-info {
            font-size: var(--rs-title-level5-font-size);
            line-height: var(--rs-title-level5-line-height);
            color: var(--rz-link-color);
          }
          &-alt-amount {
            font-size: var(--rs-title-level5-font-size);
            line-height: var(--rs-title-level5-line-height);
            color: var(--rs-light-black-color);
            margin-bottom: size(6);
          }
          &-c1x {
            font-size: var(--rs-title-level2-font-size);
            line-height: var(--rs-title-level2-line-height);
            color: var(--rs-light-black-color);
            font-weight: bold;
          }
          &-cta {
            display: block;
            @media screen and (min-width: $breakpoint-md) {
              display: none;
            }
          }
        }
        &.bottom {
          display: none;
          @media screen and (min-width: $breakpoint-md) {
            display: table-row;
          }
          th {
            padding-top: 0;
            padding-bottom: 0;
          }
        }
      }
    }
    tbody {
      z-index: var(--goto-top-z-index);
      tr {
        &.body-header {
          border: none;
          text-decoration: underline;
          padding: 0;
          @media screen and (min-width: $breakpoint-md) {
            border-bottom: size(1) solid var(--rs-license-bordder-color);
            padding: unset;
            text-decoration: none;
          }
          td {
            padding: 0;
            font-size: var(--rs-title-level2-font-size);
            line-height: var(--rs-title-level2-line-height);
            color: var(--rs-black-color);
            font-weight: bold;
            background: var(--rs-white-color);
            text-align: left;
            border: none;
            @media screen and (min-width: $breakpoint-md) {
              padding: size(20) 0;
            }
            &:not(:first-child) {
              display: none;
              @media screen and (min-width: $breakpoint-md) {
                display: table-cell;
              }
            }
          }
          &.level-0 {
            z-index: var(--rs-license-z-index-0);
          }
          &.level-1 {
            z-index: var(--rs-license-z-index-1);
          }
          &.level-2 {
            z-index: var(--rs-license-z-index-2);
          }
          &.level-3 {
            z-index: var(--rs-license-z-index-3);
          }
          &.level-4 {
            z-index: var(--rs-license-z-index-4);
          }
        }
        &.body-data {
          &:last-child {
            border: size(3) solid var(--rs-license-bordder-color);
            border-radius: size(6);
            @media screen and (min-width: $breakpoint-md) {
              border: none;
            }
          }
          td:last-child {
            &.available,
            &.none,
            &.unavailable {
              background-position: 90% 50%;
              @media screen and (min-width: $breakpoint-md) {
                background-position: 50% 50%;
              }
            }
          }
          .available {
            background-image: url(../../assets/rsCricket/correct.svg);
            background-repeat: no-repeat;
            background-position: 90% 50%;
            @media screen and (min-width: $breakpoint-md) {
              background-position: 40% 50%;
            }
          }
          .unavailable {
            background-image: url(../../assets/rsCricket/wrong.svg);
            background-repeat: no-repeat;
            background-position: 90% 50%;
            @media screen and (min-width: $breakpoint-md) {
              background-position: 40% 50%;
            }
          }
          .none {
            background-image: url(../../assets/rsCricket/none.svg);
            background-repeat: no-repeat;
            background-position: 90% 50%;
            @media screen and (min-width: $breakpoint-md) {
              background-position: 40% 50%;
            }
          }
        }
        td {
          font-size: var(--rs-title-level5-font-size);
          line-height: var(--rs-title-level5-line-height);
          color: var(--rs-license-black-color);
          text-align: right;
          @media screen and (min-width: $breakpoint-md) {
            text-align: center;
          }
          &.header-name {
            color: var(--rs-light-black-color);
            text-align: left;
            font-size: size(20);
            line-height: size(24);
            font-weight: 600;
            @media screen and (min-width: $breakpoint-md) {
              padding: unset;
              font-size: unset;
              font-weight: unset;
              line-height: unset;
            }
          }
          .hidden {
            visibility: hidden;
            &.enterprise {
              min-height: size(42);
              display: block;
              @media screen and (min-width: $breakpoint-md) {
                min-height: unset;
                display: unset;
              }
            }
          }
          .enterprise {
            min-height: size(42);
            @media screen and (min-width: $breakpoint-md) {
              min-height: unset;
            }
          }
        }
        span {
          display: inline-block;
          max-width: size(150);
          @media screen and (min-width: $breakpoint-md) {
            display: unset;
          }
        }
      }
      .btn-group {
        border-bottom: none;
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: table-row;
        }
      }
    }
    tr {
      border: size(3) solid var(--rs-license-bordder-color);
      border-radius: size(6);
      padding: size(18) 0;
      margin: size(15) 0;
      display: block;
      @media screen and (min-width: $breakpoint-md) {
        border: none;
        border-bottom: size(1) solid var(--rs-license-bordder-color);
        display: table-row;
      }
      th,
      td {
        text-align: left;
        padding: size(15);
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          padding: size(28.5) 0;
          width: 25%;
        }
        &:not(:last-child) {
          padding-right: size(15);
          @media screen and (min-width: $breakpoint-md) {
            padding-right: size(30);
          }
          @media screen and (min-width: $breakpoint-lg) {
            padding-right: size(60);
          }
        }
        &:first-child {
          width: 100%;
          @media screen and (min-width: $breakpoint-md) {
            width: 30%;
          }
        }
        &:last-child {
          width: 100%;
          @media screen and (min-width: $breakpoint-md) {
            width: 20%;
          }
        }
      }
      td {
        display: block;
        width: 100%;
        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
          word-break: break-all;
          width: size(100);
          text-align: left;
          &:first-child {
            content: '';
          }
        }
        &:last-child {
          border-bottom: 0;
        }
        @media screen and (min-width: $breakpoint-md) {
          display: table-cell;
          &::before {
            display: none;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.rs-cricket-license {
  font-family: var(--rz-hero-font);
}
</style>
