<template>
  <div v-if="footerLinks.loaded">
    <div class="footer">
      <div class="footer-container">
        <section class="all-links">
          <div class="inner-block">
            <div class="company">
              <a href="/" class="image-wrap">
                <img src="@/assets/rca-logo-black.svg" alt="Roanuz"/>
              </a>
            </div>
            <div class="rs-flex-box">
              <div class="links" v-for="(linkslist,index) in footerLinks.links" :key="index">
                <h3>{{linkslist.name}}</h3>
                <div class="links-section-wrapper">
                  <div
                    class="links-section"
                    >
                    <ul>
                      <li
                        v-for="link in linkslist.linkslist.links"
                        :key="link.key"
                      >
                        <TextButton :link="link" :buttonType="'plain'"/>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tools-section" v-if="footerLinks && footerLinks.tools &&
                  (index === (footerLinks.links.length - 1))">
                  <h3>{{footerLinks.tools.name}}</h3>
                  <div class="links-section-wrapper">
                    <div
                      class="links-section"
                      >
                      <ul>
                        <li
                          v-for="link in footerLinks.tools.linkslist.links"
                          :key="link.key"
                        >
                          <TextButton :link="link" :buttonType="'plain'"/>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="links">
                <h3>Billing Region</h3>
                <div class="currency-wrapper">
                  <CurrencySelector />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section class="toc-links">
        <div class="inner-block">
          <div class="company">
            <p>© Copyright Roanuz Softwares Private Limited. All rights reserved</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';
import CurrencySelector from '@/components/WebPage/Provisional/CurrencySelector.vue';

export default {
  components: {
    TextButton,
    CurrencySelector,
  },
  props: {
    footerLinks: null,
  },
};
</script>
