<template>
  <div class="rs-article-list article-page">
    <div v-if="articleLoaded && pageLoaded">
      <div v-for="(section) in page.sections" :key="section.static_section_id">
        <section v-if="section.static_section_id === 'banner_section'">
          <Banner
            :banner="section"
            :sectionType="'resource article bg-black'"
            :maxWidth="'max-width-520'"
          ></Banner>
        </section>
      </div>
      <div class="container" v-if="articleList">
        <ArticleListTwoColSec :article="articleList && articleList.article[0]" />
        <div>
          <div class="article-list">
            <template v-for="(article, index) in articleList.article">
              <div class="list-wrap" :key="index" v-if="index !== 0">
                <div class="list" :class="'list'+index">
                  <router-link
                    :to="{
                      name: 'rsArticleDetailView',
                      params: {
                      projKey: projkey,
                      articleid: article.route_value.replace('rs-cricket-', '')}
                    }"
                  >
                    <div class="image-wrap" v-if="article.card_img && article.card_img.url">
                      <rz-image-item :image="article.card_img" />
                    </div>
                    <div class="image-wrap" v-else>
                      <img class="image-item" src="@/assets/blog-placeholder.png" alt="gallery" />
                    </div>
                    <div class="title" v-if="article.category">{{ article.category}}</div>
                    <div class="desc" v-if="article.card_title">{{ article.card_title }}</div>
                    <div
                      class="date"
                      v-if="article.publication_date"
                    >{{ formatBlogPublicationDate(article.publication_date)}}</div>
                  </router-link>
                </div>
              </div>
            </template>
            <div
              class="list-wrap on-tab"
              v-for="(emptyArticles, index) in addEmptyArticles"
              :key="index+'articleblog'"
            ></div>
          </div>
        </div>
        <TextButton class="archive-btn" :buttonType="'rs-button'" :link="archiveRoute" />
      </div>
    </div>
    <div class="content placeholder" v-else>
      <CricketLoadingPlaceholder :state="pageState" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import TextButton from '@/components/RZWebsite/TextButton.vue';
import ArticleListTwoColSec from '@/components/RsCricket/ArticleTwoColSec.vue';

export default {
  name: 'ArticleList',
  components: {
    CricketLoadingPlaceholder,
    Banner,
    ArticleListTwoColSec,
    TextButton,
  },
  props: {
    projKey: String,
  },
  computed: {
    ...mapState({
      articleList: (state) => state && state.article && state.article.activeArticleList,
      articleListState: (state) => state.article.activeArticleListState,
      articleLoaded: (state) => state && state.article
      && state.article.activeArticleListState
       && state.article.activeArticleListState.status.isLoaded,
      page: (state) => state.page.activePage,
      pageState: (state) => state.page.activePageState,
      pageLoaded: (state) => state.page.activePageState.status.isLoaded,
    }),
    addEmptyArticles() {
      if (this.articleList.article) {
        switch ((this.articleList.article.length - 1) % 3) {
          case 1:
            return 2;

          case 2:
            return 1;

          default:
            return 0;
        }
      }
      return 0;
    },
  },
  serverPrefetch() {
    return this.freshFetch();
  },
  data() {
    return {
      articleListLimit: 13,
      archiveRoute: {
        routeParams: { name: 'rsArticleArchives' },
        name: 'View Archive',
      },
    };
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  mounted() {
    if (this.articleLoaded) {
      // eslint-disable-next-line operator-linebreak
      const sameRoute = this.page.route_value === this.$route.meta.routeValue;
      if (!sameRoute) {
        console.log(
          'Refreshing page because page mounted with different route',
        );
        this.refreshPage();
        return;
      }
    }

    if (!this.articleLoaded) {
      this.freshFetch();
    }
  },
  methods: {
    freshFetch() {
      return Promise.all([
        this.fetchArticles(),
        this.fetchBannerData(),
      ]);
    },
    fetchBannerData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = 'rs-cricket-article-detail';
        console.log('route value', this.$route.meta.routeValue);
        return this.$store.dispatch('page/fetchActivePage', {
          route,
          routeValue,
          projectKey: this.projKey,
        });
      });
    },
    fetchArticles() {
      const params = {
        limit: this.articleListLimit,
      };
      return this.$store.dispatch('article/fetchArticleList', {
        params,
        routeName: 'articleList',
        projectKey: this.projKey,
      });
    },
    refreshPage() {
      this.$store.commit('article/resetActivePage');
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
  },
};
</script>
<style lang="scss" scoped>
.rs-article-list {
  --rs-license-bordder-color: #dadada;
  --rs-license-z-index-0: 0;
  --rs-license-z-index-1: 1;
  --rs-license-z-index-2: 2;
  --rs-license-z-index-3: 3;
  --rs-license-z-index-4: 4;
  --rs-license-black-color: #181818;
  --rs-solution-pad-horizontal: #{size(20)};
  color: var(--rs-black-color);
  font-family: var(--rz-hero-font);
  margin-bottom: size(150);
  * {
    box-sizing: border-box;
    .solution-banner.resource {
      box-sizing: initial;
    }
  }
  .pad-top-10 {
    padding-top: size(10);
  }
  .pad-top-6 {
    padding-top: size(6);
  }
  .pad-top-30 {
    padding-top: size(30);
  }
  .inline-block {
    display: inline-block;
  }
  .container {
    margin: auto;
    padding: 0 var(--rs-solution-pad-horizontal);
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(1080);
      padding: 0;
    }
    margin-bottom: size(50);
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: size(80);
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-bottom: size(100);
    }
  }
  .max-width-1440 {
    max-width: size(1440);
    margin: 0 auto;
    position: relative;
  }
  .archive-btn {
    display: flex;
    justify-content: center;
    margin: size(10) 0 0;
  }
}
</style>
<style lang="scss">
.rs-article-list {
  --rs-article-border-radius: #{size(6)};
  .article-list-two-col {
    .flex-child.two .image-item {
      img {
        height: auto;
        max-height: size(600);
        border-radius: var(--rs-article-border-radius);
      }
    }
  }
}
</style>
