<template>
  <div class="rs-coverage" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section v-if="section.static_section_id == 'banner'">
          <Banner :banner="section" :sectionType="'resource coverage'" :maxWidth="'max-width-600'"></Banner>
        </section>
        <section v-if="section.static_section_id == 'section_1'">
          <div class="rs-container">
            <h4>{{section.name}}</h4>
            <div class="flex-box">
              <div>
                <content-section
                  :section="section.detail"
                  :showButton="false"
                  :sectionType="'tech-process-wrap rs-page max-width-320'"/>
              </div>
              <div class="pricing-table">
                <div class="price-table metrics">
                  <div class="table-row">
                    <div class="table-head one">Metrics</div>
                    <div class="table-head">Metric Group 100 <label>(MG100)</label></div>
                    <div class="table-head">Metric Group 100B <label>(MG100B)</label></div>
                    <div class="table-head">Metric Group 101 <label>(MG101)</label></div>
                  </div>
                  <div class="table-row" v-for="(item,index) in metrics" :key="index">
                    <div class="table-cell one">{{item.metrics}}</div>
                    <div class="table-cell" :class="item.mg_100">{{item.mg_100}}</div>
                    <div class="table-cell" :class="item.mg_100b">{{item.mg_100b}}</div>
                    <div class="table-cell" :class="item.mg_101">{{item.mg_101}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div class="pricing-table tour-and-leagues">
        <h2 class="rs-hero-title">Tours &amp; Leagues</h2>
        <div class="price-table">
          <div class="table-row">
            <div class="table-head one">Tour &amp; Leagues</div>
            <div class="table-head">Metric Group</div>
            <div class="table-head">Match Metrics</div>
            <div class="table-head">Ball Metrics</div>
            <div class="table-head">Player Metrics</div>
            <div class="table-head">Team Metrics</div>
            <div class="table-head">Season Metrics</div>
            <div class="table-head">Charts Metrics</div>
            <div class="table-head">Performance Metrics</div>
            <div class="table-head broadcast-head">Broadcast latency</div>
          </div>
          <div class="table-row" v-for="(item,index) in coverage" :key="index">
            <div class="table-cell one">{{item.tour}}</div>
            <div class="table-cell group">{{item.group}}</div>
            <div class="table-cell" :class="item.match_metrics">{{item.match_metrics}}</div>
            <div class="table-cell" :class="item.ball_metrics">{{item.ball_metrics}}</div>
            <div class="table-cell" :class="item.player_metrics">{{item.player_metrics}}</div>
            <div class="table-cell" :class="item.team_metrics">{{item.team_metrics}}</div>
            <div class="table-cell" :class="item.season_metrics">{{item.season_metrics}}</div>
            <div class="table-cell" :class="item.charts_metrics">{{item.charts_metrics}}</div>
            <div class="table-cell" :class="item.performance_metrics">{{item.performance_metrics}}</div>
            <div class="table-cell broadcast">{{item.broadcast_latency}}</div>
          </div>
        </div>
      </div>
      <section v-for="(section, index) in page.sections" :key="index">
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import ContentSection from '@/components/RZWebsite/ContentSection.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import coverage from '../../../stories/CoverageList.json';
import metrics from '../../../stories/MetricData.json';


export default {
  name: 'rsCricketHome',
  components: {
    CricketLoadingPlaceholder,
    Banner,
    ContentSection,
    PaymentSignupSection,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  data() {
    return {
      coverage,
      metrics,
    };
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData();
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  max-width: 100%;
}
.rs-coverage {
  font-family: var(--rz-hero-font);
  .price-table {
    border: size(1) solid var(--rs-white-color);
    box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
    border-radius: size(6);
    margin-bottom: size(100);
    overflow-x: scroll;
    display: block;
    .table {
      &-cell, &-head {
        display: table-cell;
      }
      &-head {
        background: var(--rs-light-blue-color);
        font-weight: bold;
        padding: size(10);
        font-size: var(--rs-md-label-font-size);
        line-height: var(--rs-md-label-line-height);
        vertical-align: middle;
        color: var(--rs-black-color);
        &:nth-child(1) {
          background: var(--rs-black-color);
          color: var(--rs-white-color);
          padding-left: size(25);
        }
        &:nth-child(2) {
          padding-left: size(33);
        }
      }
      &-row {
        display: table-row;
        &:hover {
          border: size(1) solid #c4c4c4;
          box-shadow: 0 size(2) size(5) rgba(0, 0, 0, 0.05);
          background: var(--rs-white-color);
          .table-cell {
            border-right: size(1) solid var(--rs-white-color);
          }
        }
        &:nth-child(2) {
          .table-cell {
            padding-top: size(18);
          }
        }
        &:last-child {
          .table-cell {
            padding-bottom: size(18);
          }
        }
      }
      &-cell {
        padding: size(12) size(10);
        border-right: size(1) solid #EBE9E9;
        vertical-align: middle;
        font-size: var(--rs-md-label-font-size);
        line-height: var(--rs-md-label-line-height);
        color: var(--rs-cta-blue-color);
        min-width: size(100);
        &:last-child {
          border-right: size(1) solid transparent;
        }
        &:nth-child(2) {
          padding-left: size(33);
        }
        &.broadcast {
          color: var(--rs-pink-color);
        }
        &.available {
          color: var(--rs-gray-color);
        }
        &.one {
          min-width: size(220);
          max-width:  size(220);
          color: var(--rs-black-color);
          padding-left: size(25);
        }
      }
    }
    &.metrics {
      max-width: 90vw;
      margin: 0 auto;
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(675);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: initial;
        margin: initial;
      }
      .table {
        &-head {
          padding: size(20);
          &.one {
            padding-left: size(30);
          }
          &:last-child {
            padding-right: size(35);
          }
        }
        &-row {
          &:last-child {
            .table-cell {
              color: var(--rs-pink-color);
              &.one {
                color: var(--rs-black-color);
              }
            }
          }
        }
        &-cell {
          border-right: size(1) solid transparent;
          padding-left: size(20);
          &.one {
            min-width: size(150);
            max-width: size(150);
            font-weight: 500;
            padding-left: size(30);
          }
        }
      }

    }
    label {
      display: block;
      font-weight: normal;
    }
  }
  .tour-and-leagues {
    max-width: size(1360);
    margin: 0 auto;
    .rs-hero-title {
      max-width: initial;
      padding: 0 0 size(20) 0;
      text-align: center;
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(50);
    }
  }
  h4 {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
    color: var(--rs-black-color);
    font-weight: bold;
    text-align: center;
    max-width: size(800);
    margin: 0 auto;
    padding-bottom: size(80);
  }
  @media screen and (min-width: size(1400)) {
    .price-table {
      display: table;
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .flex-box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}
</style>
