import { render, staticRenderFns } from "./GetStarted.vue?vue&type=template&id=ad71adcc&scoped=true&"
import script from "./GetStarted.vue?vue&type=script&lang=js&"
export * from "./GetStarted.vue?vue&type=script&lang=js&"
import style0 from "./GetStarted.vue?vue&type=style&index=0&id=ad71adcc&lang=scss&scoped=true&"
import style1 from "./GetStarted.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad71adcc",
  null
  
)

export default component.exports