<template>
  <div class="content-image-wrap" :class="sectionType">
    <div class="rs-flex-box no-wrap">
      <div class="flex-item">
        <template v-if="sectionType === 'discount'">
          <rz-markdown class="sub-text"
            :text="section.links[0].name"></rz-markdown>
        </template>
        <template v-else>
          <rz-markdown class="sub-text" v-if="subText"
            :text="subText"></rz-markdown>
        </template>
        <rz-markdown class="rs-hero-title" v-if="section.name"
          :text="section.name"></rz-markdown>
        <rz-markdown class="detail-text" v-if="section.desc"
          :text="section.desc"></rz-markdown>
        <div>
         <template v-if="sectionType === 'discount'">
            <div class="hero-links" v-if="section.links.length">
              <TextButton :key="section.links[1].name" :link="section.links[1]" :buttonType="buttonType"/>
            </div>
          </template>
          <template v-else>
            <div class="hero-links" v-if="section.links.length">
              <template v-for="link in section.links">
                <TextButton :key="link.name" :link="link" :buttonType="buttonType"/>
              </template>
            </div>
          </template>
        </div>
        <p class="info-text" v-if="infoText !== ''">
          *{{section.links[2].name}} {{ infoText }} {{section.links[3].name}}
        </p>
      </div>
      <div class="image-wrap" :class="imageClass">
        <rz-image-item
          v-if="section.img" :image="section.img" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .content-image-wrap {
    color: var(--rs-black-color);
    background: linear-gradient(0deg, #81F09D, #81F09D), #FDCCCC;;
    padding: size(50) size(20) size(15);
    border-radius: size(6);
    &.fantasy {
      background: #F2BB9A;
    }
    &.discount {
      color: var(--rs-white-color);
      padding-bottom: size(50);
      background: linear-gradient(100.5deg, #051E42 0%, #0C3269 100%);
      .rs-hero-title {
        color: var(--rs-white-color);
        padding-bottom: size(15);
      }
      .detail-text {
        padding-bottom: size(10);
      }
      .image-wrap {
        max-width: size(390);
        height: size(380);
        margin: 0 auto;
        @media screen and (min-width: $breakpoint-md) {
          max-width: size(450);
          height: size(400);
        }
      }
      .info-text {
        margin-top: size(30);
        color: #CBE0FF;
        font-size: var(--rs-sm-label-font-size);
        line-height: var(--rs-sm-label-line-height);
      }
    }
    .rs-hero-title {
      padding-right: 0;
      padding-bottom: size(10);
      display: block;
    }
    .detail-text {
      font-size: var(--rs-title-level4-font-size);
      line-height: var(--rs-title-level4-line-height);
      max-width: size(440);
      padding-bottom: size(20);
      display: block;
    }
    .rs-flex-box {
      justify-content: space-between;
      align-items: center;
    }
    .image-wrap {
      max-width: size(385);
      height: size(320);
      img {
        max-width: 100%;
        max-height: 100%;
      }
      &.max-width-450 {
        max-width: size(385);
      }
    }
    .sub-text {
      font-size: var(--rs-title-level4-font-size);
      line-height: var(--rs-title-level4-line-height);
      color: #FFC220;
      font-weight: 500;
      padding-bottom: size(10);
      display: inline-block;
    }
    @media screen and (min-width: $breakpoint-md) {
      .flex-item {
        flex: 0 0 50%;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding: size(60);
    }
  }
</style>
<style lang="scss">
 .content-image-wrap {
   &.discount {
      .rs-hero-title {
        position: relative;
        strong {
          color: #FFC220;
        }
        code {
          font-weight: 400;
          font-size: size(20);
          position: absolute;
          bottom: size(35);
        }
      }
      .detail-text {
        strong {
          font-weight: bold;
        }
      }
      .sub-text {
        strong {
          text-decoration: underline;
        }
      }
    }
  }
</style>
<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  props: {
    section: {
      type: Object,
    },
    imageClass: {
      type: String,
    },
    sectionType: {
      type: String,
    },
    buttonType: {
      type: String,
      default: 'rs-button plain-cta',
    },
    subText: {
      type: String,
    },
    infoText: {
      type: String,
      default: '',
    },
  },
  components: {
    TextButton,
  },
  data() {
    return {
      link: {
        name: 'Get Started Now',
        url: '/',
      },
    };
  },
};
</script>
