<template>
  <div class="rs-blog-archives blog-page">
    <div class="container">
      <h1>Archive blogs</h1>
      <template v-if="dataLoaded">
        <div class="blog-archive-list">
          <template v-for="(blog, index) in blogList.blogs">
            <router-link
              :to="{name: 'rsBlogDetailView',params: {blogid: blog.route_value.replace('rs-cricket-', '')}}"
              :key="blog.route_value"
            >
              <div class="list" :class="'list'+index">
                <div
                  class="image-wrap"
                >
                  <rz-image-item v-if="blog.card_img && blog.card_img.url" :image="blog.card_img" />
                  <img class="image-item placeholder" src="@/assets/blog-placeholder.png" alt="gallery" v-else />
                </div>
                <div class="list-content">
                  <div class="title" v-if="blog.category">{{ blog.category}}</div>
                  <div class="desc" v-if="blog.card_title">{{ blog.card_title }}</div>
                  <div
                    class="date"
                    v-if="blog.publication_date"
                  >{{ formatBlogPublicationDate(blog.publication_date)}}</div>
                </div>
              </div>
            </router-link>
          </template>
        </div>
        <div class="pagination">
          <div
            class="arrow left"
            :class="[isPrevPageExist() ? null : 'disable']"
            @click="prevPage()"
          ></div>
          <div class="page-number">{{ activePage }}</div>
          <div
            class="arrow right"
            :class="[isNextPageExist() ? null : 'disable']"
            @click="nextPage()"
          ></div>
        </div>
      </template>
      <div class="placeholder-wrap" v-else>
        <div class="placeholder" v-for="index in loaderBlockCount" :key="index">
          <div class="image-loader-block loader"></div>
          <div class="content-loader-block">
            <div class="item-1 loader"></div>
            <div class="item-2 loader"></div>
            <div class="item-3 loader"></div>
          </div>
        </div>
      </div>
    </div>
    <template v-for="(section, index) in page.sections">
      <PaymentSignupSection
        v-if="section.static_section_id == 'singup_wrapper'"
        :signupInfo="section"
        :key="index + section.static_section_id"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BlogList } from '@/store/modules/blog';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';

export default {
  name: 'BlogArchives',
  data() {
    return {
      activePage: 1,
      batchNo: 0,
      isLastPage: false,
      loaderBlockCount: 3,
    };
  },
  components: {
    PaymentSignupSection,
  },
  computed: {
    ...mapState({
      blogListState: (state) => state.blog.activeBlogListState,
      dataLoaded: (state) => state.blog.activeBlogListState.status.isLoaded,
      blogList: (state) => state.blog.activeBlogList,
      page: (state) => state.page.activePage,
      pageState: (state) => state.page.activePageState,
      pageLoaded: (state) => state.page.activePageState.status.isLoaded,
    }),
  },
  serverPrefetch() {
    return this.freshFetch();
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
    activePage() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    if (this.dataLoaded) {
      // eslint-disable-next-line operator-linebreak
      const sameRoute = this.page.route_value === this.$route.meta.routeValue;
      if (!sameRoute) {
        console.log(
          'Refreshing page because page mounted with different route',
        );
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    }
  },
  methods: {
    freshFetch() {
      return Promise.all([this.fetchBlogList(), this.fetchBannerData()]);
    },
    fetchBlogList() {
      const params = {
        last: this.batchNo,
      };
      return this.$store
        .dispatch('blog/fetchBlogList', {
          params,
          routeName: 'archives',
        })
        .then(() => {
          if (this.blogList.cursor.total === 10) {
            this.isLastBlog();
          } else {
            this.isLastPage = true;
          }
        });
    },
    fetchBannerData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = 'rs-cricket-blog-archive';
        console.log('route value', this.$route.meta.routeValue);
        return this.$store.dispatch('page/fetchActivePage', {
          route,
          routeValue,
        });
      });
    },
    refreshPage() {
      this.$store.commit('blog/resetActivePage');
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    prevPage() {
      if (this.activePage === 1) {
        return;
      }
      this.batchNo -= 10;
      this.activePage -= 1;
      this.fetchBlogList();
    },
    nextPage() {
      if (this.isLastPage) {
        return;
      }
      this.batchNo += 10;
      this.activePage += 1;
      this.fetchBlogList();
    },
    isPrevPageExist() {
      if (this.activePage === 1) {
        return false;
      }
      return true;
    },
    isNextPageExist() {
      console.log('next page cursor : ', this.blogList.cursor.total);
      if (this.blogList.cursor.total !== 10) {
        return false;
      }
      return !this.isLastPage;
    },
    isLastBlog() {
      const batchNo = this.batchNo + 10;
      const params = {
        last: batchNo,
      };
      BlogList.fetchBlogList(
        this.$apiInstance,
        this.$inMemoryCache,
        params,
      ).then((nextPage) => {
        if (nextPage.blogs.length) {
          this.isLastPage = false;
        } else {
          this.isLastPage = true;
        }
        this.isPrevPageExist();
        this.isNextPageExist();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rs-blog-archives {
  --rs-license-bordder-color: #dadada;
  --rs-license-z-index-0: 0;
  --rs-license-z-index-1: 1;
  --rs-license-z-index-2: 2;
  --rs-license-z-index-3: 3;
  --rs-license-z-index-4: 4;
  --rs-license-black-color: #181818;
  --rs-solution-pad-horizontal: #{size(15)};
  color: var(--rs-black-color);
  font-family: var(--rz-hero-font);
  * {
    box-sizing: border-box;
    .solution-banner.resource {
      box-sizing: initial;
    }
  }
  .pad-top-10 {
    padding-top: size(10);
  }
  .pad-top-6 {
    padding-top: size(6);
  }
  .pad-top-30 {
    padding-top: size(30);
  }
  .inline-block {
    display: inline-block;
  }
  .container {
    margin: auto;
    padding: 0 var(--rs-solution-pad-horizontal);
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(1080);
      padding: 0;
    }
  }
  h1 {
    font-weight: bold;
    font-size: var(--rs-title-level2-font-size);
    line-height: var(--rs-title-level2-line-height);
    color: var(--rs-black-color);
    padding: size(30) 0 size(20) 0;
    border-bottom: size(1) dashed var(--rs-gray-color);
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(50);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(60);
    }
  }
  .blog-archive-list {
    padding: size(30) 0;
    border-bottom: size(1) dashed var(--rs-gray-color);
    margin-bottom: size(30);
    & > a {
      &:last-child .list {
        padding-bottom: size(0);
        border: none;
      }
      &:first-child .list {
        padding-top: size(0);
      }
      .list {
        padding: size(30) 0;
        border-bottom: size(1) dashed var(--rs-gray-color);
        @media screen and (min-width: $breakpoint-md) {
          padding: size(15) 0;
          border: none;
        }
      }
      & {
        @include headerColorVariant(
          '.title',
          #b02907,
          #ff931e,
          #0751b9,
          #ba298a,
          #62cd9f,
          #856900
        );
      }
      &:hover .list .desc {
        color: var(--rz-link-color);
      }
    }
    .list {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: size(30) 0;
      border-bottom: size(1) dashed var(--rs-gray-color);
      @media screen and (min-width: $breakpoint-md) {
        flex-direction: row;
        padding: size(15) 0;
        border: none;
      }
      img {
        border-radius: var(--rs-blog-border-radius);
        margin-bottom: size(18);
      }
      .image-wrap {
        margin-bottom: size(10);
        border-radius: var(--rs-blog-border-radius);
        width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          width: var(--blog-image-max-width);
        }
      }
      .title {
        color: var(--rs-orange-color);
        padding-bottom: size(5);
        font-size: var(--rs-title-level5-font-size);
        line-height: var(--rs-title-level5-line-height);
      }
      .desc {
        padding-bottom: size(10);
        color: var(--regular-text);
        font-size: var(--rs-title-level4-font-size);
        line-height: var(--rs-title-level4-line-height);
      }
      .date {
        color: var(--rs-gray-color);
        font-size: var(--rs-sm-label-font-size);
        line-height: var(--rs-sm-label-line-height);
      }
      &.on-tab {
        display: none;
        @media screen and (min-width: $breakpoint-md) {
          display: block;
        }
      }
      a:hover {
        .desc {
          color: var(--rs-cta-blue-color);
        }
      }
      &-content {
        margin-left: size(0);
        max-width: 100%;
        @media screen and (min-width: $breakpoint-md) {
          margin-left: size(15);
          max-width: size(340);
        }
      }
      .list-content {
        @media screen and (min-width: $breakpoint-md) {
          margin-top: size(0);
        }
      }
    }
  }
  .max-width-1440 {
    max-width: size(1440);
    margin: 0 auto;
    position: relative;
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: size(50);
    @media screen and (min-width: $breakpoint-lg) {
      margin-bottom: size(80);
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-bottom: size(100);
    }
    .arrow {
      border-radius: size(5);
      border: size(1) solid var(--rs-black-color);
      cursor: pointer;
      display: inline-block;
      &:after {
        content: '';
        border: solid var(--rs-black-color);
        border-width: 0 size(2) size(2) 0;
        display: inline-block;
        padding: size(3);
        vertical-align: middle;
      }
      &.left {
        padding: size(10) size(12) size(10) size(14);
        &:after {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }
      &.right {
        padding: size(10) size(14) size(10) size(12);
        &:after {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
      &:hover {
        @include jump-up-animation;
      }
      &.disable {
        border: size(1) solid var(--rs-border-gray-color);
        cursor: not-allowed;
        &:after {
          border: solid var(--rs-border-gray-color);
          border-width: 0 size(2) size(2) 0;
        }
        &:hover {
          animation: none;
        }
      }
    }
    .page-number {
      margin: auto size(20);
    }
  }
  .placeholder {
    display: flex;
    margin-bottom: size(15);
    .loader {
      background: var(--loading-placeholder-bg-lite);
      animation: placeholder-move 0.5s infinite;
    }
    .image-loader-block {
      width: var(--blog-image-max-width);
      height: size(100);
      border-radius: var(--rs-blog-border-radius);
      @media screen and (min-width: $breakpoint-md) {
        height: var(--blog-image-height);
      }
    }
    .content-loader-block {
      padding-left: size(15);
      .item-1 {
        width: 40vw;
        height: size(30);
        margin-bottom: size(10);
        border-radius: var(--rs-blog-border-radius);
      }
      .item-2 {
        width: 30vw;
        height: size(20);
        margin-bottom: size(10);
        border-radius: var(--rs-blog-border-radius);
      }
      .item-3 {
        width: 10vw;
        height: size(20);
      }
    }
    &-wrap {
      padding-top: size(30);
    }
  }
}
@keyframes placeholder-move {
  from {
    background-color: rgba(211, 215, 228, 0.5);
  }
  to {
    background-color: var(--loading-placeholder-bg-lite);
  }
}
</style>
<style lang="scss">
.rs-blog-archives {
  --blog-image-max-width: #{size(350)};
  --blog-image-min-width: #{size(290)};
  --blog-image-height: #{size(200)};
  --rs-blog-border-radius: #{size(6)};

  .blog-archive-list {
    .image-item {
      transition: transform .5s;
      border-radius: var(--rs-blog-border-radius);
      @media screen and (min-width: $breakpoint-md) {
          max-width: var(--blog-image-max-width);
      }
      img {
        width: 100%;
        border-radius: var(--rs-blog-border-radius);
      }
      .image-container {
        border-radius: size(10);
      }
      .show-container {
        text-align: left;
      }
      &.placeholder {
        min-width: size(250);
      }
    }
    .image-wrap {
      position: relative;
      overflow: hidden;
      border-radius: var(--rs-blog-border-radius);
    }
    .list:hover {
      .image-item {
        transform: scale(1.05);
      }
    }
  }
}
</style>
