<template>
  <div class="rs-fantasy-points" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section v-if="section.static_section_id == 'banner'">
          <Banner :banner="section" :sectionType="'resource coverage'" :maxWidth="'max-width-600'"></Banner>
        </section>
      </section>
      <div class="tab-wrapper">
        <span v-for="(tab, index) in matchFormat" :key="index"
          @click="activeTabIndex = index" :class="{active: activeTabIndex === index}">
          {{tab.split('_').join(' ')}}</span>
      </div>
      <section class="rs-container">
        <div class="pricing-resource">
          <div v-for="(point, index) in Object.keys(points[matchFormat[activeTabIndex]])"
            :key="index" :class="point + '-section'" class="flex-item-wrapper">
            <div class="flex-item-header" @click="collapseSection(index)">
              <div class="flex-box clickable">
                <h4 class="title">
                  {{matchFormat[activeTabIndex].split('_').join(' ')}} - {{point}}</h4>
                <span class="expand" :class="[opened.includes(index) ? '': 'hide']"
                ></span>
              </div>
              <p class="text" v-if="point === 'Economy Rate'">
                <span v-if="matchFormat[activeTabIndex] === 'T20' || matchFormat[activeTabIndex] === 'The_Hundred'
                || matchFormat[activeTabIndex] === 'T10'">
                  Requires a minimum of 2 Overs to start calculating points.
                </span>
                <span v-if="matchFormat[activeTabIndex] === 'One_Day'">
                  Requires a minimum of 5 overs to start calculating points.
                </span>
              </p>
              <p class="text" v-if="point === 'Strike Rate'">
                <span v-if="matchFormat[activeTabIndex] === 'T20' || matchFormat[activeTabIndex] === 'The_Hundred'
                || matchFormat[activeTabIndex] === 'T10'">
                  Requires a minimum of 10 balls to start calculating points.
                </span>
                <span v-if="matchFormat[activeTabIndex] === 'One_Day'">
                  Requires a minimum of 20 balls to start calculating points.
                </span>
              </p>
            </div>
            <div class="rs-flex-box" :class="[opened.includes(index) ? 'show': 'hide']">
              <div class="inner-box">
                <div class="table-view">
                  <div class="table-view__header">
                    <section>
                      <div class="rs-sm-para bold">Points Type</div>
                      <div class="rs-sm-para bold">Points</div>
                    </section>
                  </div>
                  <div class="table-view__inner">
                    <div class="table-view__row" v-for="(key,val,index) in points[matchFormat[activeTabIndex]][point]"
                      :key="index">
                      <div class="rs-sm-para">
                        <span>{{val}}</span>
                        <span class="sub-text">{{key.help_text}}</span>
                      </div>
                      <div class="rs-sm-para">
                        <span v-if="key.value !== '-'"
                          :class="[parseInt(key.value, 10) >= 0 ? 'green': 'red']">
                          {{key.value}}
                        </span>
                        <span v-else>-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-for="(section, index) in page.sections" :key="index">
        <section class="rs-support-block" v-if="section.static_section_id == 'support_section'">
          <div class="rs-container">
            <support-wrapper />
          </div>
        </section>
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import points from '../../../stories/fantasyPoints.json';

export default {
  name: 'rsFantasyPointsSystem',
  components: {
    CricketLoadingPlaceholder,
    Banner,
    PaymentSignupSection,
    SupportWrapper,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  data() {
    return {
      points,
      activeTabIndex: 0,
      matchFormat: [
        'One_Day',
        'Test',
        'T20',
        'T10',
        'The_Hundred',
      ],
      toggleSection: null,
      opened: [],
    };
  },
  mounted() {
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      setTimeout(() => {
        this.collapseSection(0);
      }, 1000);
    }
  },
  watch: {
    activeTabIndex() {
      for (let i = 0; i < this.opened.length; i += 1) {
        const contentContainer = document.getElementsByClassName('rs-flex-box')[this.opened[i]];
        contentContainer.setAttribute('style', '');
      }
      this.opened = [];
      this.collapseSection(0);
    },
  },
  methods: {
    freshFetch() {
      return this.fetchData().then(() => {
        setTimeout(() => {
          this.collapseSection(0);
        }, 1000);
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    collapseSection(id) {
      const index = this.opened.indexOf(id);
      const contentContainer = document.getElementsByClassName('rs-flex-box')[id];
      if (index > -1) {
        this.opened.splice(index, 1);
        contentContainer.setAttribute('style', '');
      } else {
        this.opened.push(id);
        contentContainer.style.height = `${contentContainer.scrollHeight}px`;
        contentContainer.style.minHeight = `${contentContainer.scrollHeight}px`;
      }
    },
  },
};
</script>
<style lang="scss">
.rs-fantasy-points {
  .rs-support-block {
    .rs-container {
      max-width: size(1080);
    }
  }
  .solution-banner {
    &.coverage {
      &.resource {
        min-height: size(420);
        @media screen and (min-width: $breakpoint-md) {
          min-height: size(650);
        }
        @media screen and (min-width: $breakpoint-lg) {
          min-height: size(540);
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
img {
  max-width: 100%;
}
.rs-container {
  max-width: size(780);
  margin: 0 auto;
  padding-top: size(25);
  padding-bottom: size(60);
  @media screen and (min-width: $breakpoint-md) {
    padding-bottom: size(35);
  }
}
.expand {
  width: initial;
}
.flex-item-wrapper {
  border-bottom: size(1) solid #C4C4C4;
  padding: 0;
  .flex-item-header {
    padding-bottom: size(30);
    padding-top: size(30);
    cursor: pointer;
    padding-top: size(25);
    padding-bottom: size(25);
  }
  .rs-flex-box {
    transition: height .6s cubic-bezier(.645,.045,.355,1), opacity .3s cubic-bezier(.645,.045,.355,1),
     min-height .6s cubic-bezier(.645,.045,.355,1);
    opacity: 1;
    height: 0;
    min-height: 0;
    overflow: hidden;
    &.hide {
      opacity: 0;
    }
  }
  .expand {
    &.hide {
      &:after {
        transform:rotate(90deg);
      }
      &:before {
        transform: initial;
      }
    }
  }
}
.inner-box {
  flex: 0 0 100%;
}
.expand {
  cursor: pointer;
  position:relative;
  padding: 0 size(10);
  width: size(16);
  height: size(16);
  background-color: transparent;
  border: 0;
  display: inline-block;
  &:before, &:after {
    content: "";
    position: absolute;
    background-color: var(--rs-black-color);
    transition: transform 300ms cubic-bezier(.694,0,.335,1);
    width: size(2);
    height: size(16);
    top: 0;
  }
  &:after {
    transform: rotate(270deg);
  }
  &:before {
    transform: rotate(90deg);
  }
}
.rs-fantasy-points {
  --resource-links-bg: #F5F5F5;
  --desc-font-color: #525252;
  --cards-border-color: #DADADA;

  font-family: var(--rz-hero-font);
  .flex-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tab-wrapper {
    max-width: size(540);
    margin: size(30) auto 0;
    text-align: center;
    span {
      display: inline-block;
      margin: 0 size(10);
      padding: size(10) 0;
      @media screen and (min-width: $breakpoint-md) {
        padding: size(10);
      }
      cursor: pointer;
      &.active {
        border-bottom: solid size(3) var(--rz-link-color);
      }
    }
  }
  .pricing-resource {
    color: var(--ca-title-color);
    position: relative;
    .title {
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
      font-weight: bold;
      text-transform: capitalize;
      display: inline-block;
      color: var(--rs-black-color);
      padding: 0 size(30) 0 0;
      cursor: pointer;
      max-width: size(210);
      @media screen and (min-width: #{size(376)}) {
        max-width: size(300);
        font-size: size(22);
      }
      @media screen and (min-width: #{size(420)}) {
        max-width: unset;
      }
      @media screen and (min-width: $breakpoint-md) {
        display: inline-block;
      }
    }
    .text {
      padding-top: size(10);
    }
    .table-view {
      padding-bottom: size(15);
      margin: size(10) auto;
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(35);
        &.no-pad {
          padding-bottom: 0;
        }
      }
      &__header, &__row {
        padding: size(15) 0;
        margin-bottom: size(20);
        @media screen and (min-width: $breakpoint-md) {
          padding: size(24) size(16) size(24) 0;
          margin-bottom: size(25);
        }
        div {
          &:nth-child(3),  &:nth-child(4), &:nth-child(5) {
            display: inline-block;
            width: 33%;
            text-align: center;
            padding-top: 1.25rem;
            @media screen and (min-width: $breakpoint-md) {
              text-align: left;
            }
          }
          .sub-text {
            display: block;
            color: var(--rs-gray-color);
            font-size: size(14);
          }
        }
        .label {
          font-size: var(--rs-sm-label-font-size);
          line-height: var(--rs-sm-label-line-height);
          font-weight: normal;
        }
        @media screen and (min-width: $breakpoint-md) {
          border-bottom: size(1) solid var(--cards-border-color);
          padding: 0;
          display: flex;
          margin-bottom: 0;
          div {
            flex: 1;
            padding: size(15) 0 size(16) size(25);
            &:first-child, &:nth-child(2) {
              flex: 2;
            }
            &:last-child {
              min-width: 14%;
              @media screen and (min-width: $breakpoint-md) {
                min-width: 8%;
              }
            }
            &:nth-child(3),  &:nth-child(4), &:nth-child(5) {
              width: initial;
            }
          }
          .label {
            font-size: var(--rs-title-level5-font-size);
            line-height: var(--rs-title-level5-line-height);
          }
        }
      }
      &__row {
        background: var(--regular-text-inv);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: size(10);
        position: relative;
        background: transparent;
        transition: all .3s ease-in-out;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &:last-child {
          border-bottom: none;
        }
        .green {
          display: inline-block;
          padding: size(4) size(8);
          background: #97E6AB;
          border-radius: size(5);
        }
        .red {
          display: inline-block;
          padding: size(4) size(8);
          background: #FF8282;
          border-radius: size(5);
        }
        div {
          &:first-child {
            min-width: 80%;
            flex: 2;
            @media screen and (min-width: $breakpoint-md) {
              min-width: 88%;
            }
            &:before {
              display: none;
            }
            @media screen and (min-width: $breakpoint-md) {
              padding: size(15) 0 size(16) 0;
            }
          }
          &:last-child {
            min-width: 14%;
            @media screen and (min-width: $breakpoint-md) {
              min-width: 8%;
            }
          }
        }
        box-shadow: none;
        border-radius: 0;
        justify-content: initial;
        border-bottom: size(1) solid var(--cards-border-color);
      }
      div {
        &.hide {
          .table-view__inner {
            display: none;
          }
          .sub-heading {
            .collapse-icon {
              display: none;
            }
            .expand-icon {
              display: block;
            }
          }
        }
        .sub-heading {
          .expand-icon {
            display: none;
          }
          .collapse-icon {
            font-size: size(28);
            line-height: size(13);
          }
        }
      }
      &__header {
        background: var(--regular-text-inv);
        display: table;
        width: 100%;
        section {
          display: flex;
          div {
            flex: 1;
            &:first-child, &:nth-child(2) {
              flex: 2;
            }
            &:first-child {
              min-width: 83%;
              padding-left: 0;
              @media screen and (min-width: $breakpoint-md) {
                min-width: 88%;
              }
            }
            &:last-child {
              min-width: 14%;
              @media screen and (min-width: $breakpoint-md) {
                min-width: 8%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
