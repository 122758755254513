<template>
  <div class="quote-wrap" v-if="section" :class="sectionType">
    <div class="content">
      <h1 v-if="quote" class="title" :class="titleType">{{quote}}</h1>
      <h1 v-else class="title" :class="titleType">
        <span v-if="section.name">{{section.name}}</span></h1>
      <rz-markdown class="detail-text" :class="contentWidth" v-if="showDesc && section.desc" :text="section.desc">
      </rz-markdown>
      <div v-if="showButton">
        <div class="hero-links" v-if="section.links && section.links.length">
          <template v-for="(link,index) in section.links">
            <TextButton v-if="index < section.links.length - 1" :key="link.key" :link="link" :buttonType="buttonType" />
            <TextButton class="top-space" v-else-if="showCtaBtn && (index ==  section.links.length - 1)"
              :key="link.key" :link="link" :buttonType="'rz-button cta'" />
            <TextButton v-else :key="link.key" :link="link" :buttonType="buttonType" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .quote-wrap {
    .title {
      font-size: var(--rz-title-level2-font-size);
      line-height: var(--rz-title-level2-line-height);
      font-weight: bold;
      color: var(--rz-title-color);
      padding-bottom: size(20);
      font-family: var(--rz-hero-font);
      &.hero {
        font-size: size(48);
        line-height: size(50);
        color: var(--rz-hero-color);
        font-weight: 800;
      }
      &.color-change {
        color: var(--rz-hero-color);
        font-weight: 800;
      }
      &.quote {
        font-size: var(--rz-hero-font-size);
        line-height: var(--rz-hero-line-height);
        padding-bottom: 0;
      }
      &.section {
        font-size: var(--rz-title-section-font-size);
        line-height: var(--rz-title-section-line-height);
        color: #112432;
        text-transform: uppercase;
        &.not-black {
          color: var(--rz-hero-color);
        }
      }
      &.level0-title {
        font-size: var(--rz-title-level2-font-size);
        line-height: var(--rz-title-level2-font-size);
        color: var(--rz-type-color);
        &.not-black {
          color: var(--rz-hero-color);
          text-align: center;
        }
      }
    }
    .detail-text {
      font-size: var(--rz-para-hero-font-size);
      line-height: var(--rz-para-hero-line-height);
      color: var(--rz-type-color);
      padding-bottom: size(30);
      font-family: var(--rz-para-font);
      &.max-width-800 {
        max-width: size(800);
        margin: 0 auto;
      }
    }
    .top-space {
      margin-top: size(20);
    }
    @media screen and (max-width: $breakpoint-lg) {
      padding-right: size(20);
    }
    @media screen and (min-width: $breakpoint-lg) {
      .title {
        &.hero {
          font-size: var(--rz-hero-font-size);
          line-height: var(--rz-hero-line-height);
        }
        &.quote {
          font-size: var(--rz-title-level2-font-size);
          line-height: var(--rz-title-level2-line-height);
        }
        &.level0-title {
          font-size: var(--rz-title-level0-font-size);
          line-height: var(--rz-title-level0-line-height);
        }
      }
    }
    &.tech-process-wrap {
      .title {
        font-size: var(--rz-title-level2-font-size);
        line-height: var(--rz-title-level2-line-height);
        font-weight: bold;
        color: var(--rz-title-color);
        padding-right: 0;
        font-family: var(--rz-hero-font);
        padding-bottom: 0;
      }
      .detail-text {
        padding-top: size(15);
        padding-bottom: 0;
        font-size: var(--rz-para-hero-font-size);
        line-height: var(--rz-para-hero-line-height);
        color: var(--rz-type-color);
        max-width: size(430);
        font-family: var(--rz-para-font);
      }
      @media screen and (min-width: $breakpoint-md) {
        .title {
          font-size: var(--rz-title-level0-font-size);
          line-height: var(--rz-title-level0-line-height);
        }
      }
      &.rs-page {
        &.max-width-600 {
          max-width: size(600);
          .detail-text {
            max-width: size(600);
          }
        }
        &.max-width-320 {
          .detail-text {
            max-width: initial;
          }
          padding-bottom: size(30);
          @media screen and (min-width: $breakpoint-lg) {
            max-width: size(370);
          }
        }
        &.white {
          .title, .detail-text {
            color: var(--rs-white-color);
            .p {
              color: var(--rs-white-color);
            }
          }
        }
        .title {
          font-size: var(--rs-title-level2-font-size);
          line-height: var(--rs-title-level2-line-height);
          color: var(--rs-black-color);
        }
        .detail-text {
          color: var(--rs-black-color);
          font-size: var(--rs-title-level4-font-size);
          line-height: var(--rs-title-level4-line-height);
        }
      }
    }
  }
</style>

<style lang="scss">
  .detail-text {
    &.markdown {
      em {
        font-style: italic;
        font-weight: normal;
      }
      strong {
        font-weight: bolder;
        em {
          font-weight: bolder;
        }
      }
      ul {
        list-style-type: disc;
        padding-left: size(30);
      }
    }
  }
  .quote-wrap {
    .detail-text {
      &.markdown {
        display: inline-block;
        .p, li {
          font-size: var(--rz-para-hero-font-size);
          line-height: var(--rz-para-hero-line-height);
          color: var(--rz-type-color);
          &:last-child {
            padding-bottom: 0;
          }
        }
        .p {
          padding-bottom: size(30);
          font-family: var(--rz-para-font);
        }
      }
    }
  }
  .tech-process-wrap {
    .detail-text {
      &.markdown {
        display: inline-block;
        .p {
          color: var(--rs-black-color);
          font-size: var(--rs-title-level4-font-size);
          line-height: var(--rs-title-level4-line-height);
          font-family: var(--rz-para-font);
          &:last-child {
            padding-bottom: 0;
          }
        }
        ul {
          li {
            font-size: var(--rs-title-level4-font-size);
            line-height: var(--rs-title-level4-line-height);
            color: var(--rs-black-color);
            padding-top: size(6);
            &:first-child {
              padding-top: 0;
            }
            a {
              text-decoration: underline;
            }
          }
          padding-bottom: size(30);
          &:last-child {
            padding-bottom: 0;
          }
        }
        h3 {
          padding-bottom: size(12);
        }
      }
    }
     &.white {
      .title, .detail-text {
        color: var(--rs-white-color);
        &.markdown {
          .p {
            color: var(--rs-white-color);
          }
        }
      }
    }
  }
</style>

<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  name: 'ContentSection',
  components: {
    TextButton,
  },
  props: {
    section: {
      type: Object,
    },
    showDesc: {
      type: Boolean,
      default: true,
    },
    buttonType: {
      type: String,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    titleType: {
      type: String,
    },
    quote: {
      type: String,
    },
    showCtaBtn: {
      type: Boolean,
      default: false,
    },
    contentWidth: {
      type: String,
    },
    sectionType: {
      type: String,
    },
  },
};
</script>
