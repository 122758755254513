export function licenseInfo() {
  return [
    {
      name: 'Features',
      data: [
        {
          name: 'Active access tokens per project',
          comparision: {
            rs_paygo_standard: 5000,
            rs_paygo_premium: 5000,
            rs_paygo_enterprise_a101: 500000,
          },
        },
        {
          name: 'Concurrent requests per access token',
          comparision: {
            rs_paygo_standard: 64,
            rs_paygo_premium: 64,
            rs_paygo_enterprise_a101: 128,
          },
        },
        {
          name: 'Unique accessible resources per month',
          comparision: {
            rs_paygo_standard: 1200,
            rs_paygo_premium: 1200,
            rs_paygo_enterprise_a101: 6000,
          },
        },
        {
          name: 'Apps per Roanuz Project',
          comparision: {
            rs_paygo_standard: 'One',
            rs_paygo_premium: 'One',
            rs_paygo_enterprise_a101: 'One',
          },
        },
        {
          name: 'Access to retired players & teams',
          comparision: {
            rs_paygo_standard: false,
            rs_paygo_premium: false,
            rs_paygo_enterprise_a101: false,
          },
        },
        {
          name: 'Access to completed matches & tournaments',
          comparision: {
            rs_paygo_standard: 'Up to 8 weeks',
            rs_paygo_premium: 'Up to 8 weeks',
            rs_paygo_enterprise_a101: 'Up to 72 week',
          },
        },
        {
          name: 'Attribution to Roanuz Sports',
          comparision: {
            rs_paygo_standard: 'Mandatory',
            rs_paygo_premium: 'Mandatory',
            rs_paygo_enterprise_a101: 'Not required',
          },
        },
      ],
    },
    {
      name: 'Support',
      data: [
        {
          name: 'Priority Business Support',
          comparision: {
            rs_paygo_standard: 'none',
            rs_paygo_premium: 'none',
            rs_paygo_enterprise_a101: true,
          },
        },
        {
          name: 'Expert Integration Support',
          comparision: {
            rs_paygo_standard: 'none',
            rs_paygo_premium: 'none',
            rs_paygo_enterprise_a101: true,
          },
        },
        {
          name: 'Dedicated Tech support channel on Slack',
          comparision: {
            rs_paygo_standard: 'none',
            rs_paygo_premium: 'none',
            rs_paygo_enterprise_a101: true,
          },
        },
        {
          name: 'SLA',
          comparision: {
            rs_paygo_standard: 'none',
            rs_paygo_premium: 'none',
            rs_paygo_enterprise_a101: {
              desc: 'Minimum committed use of ',
              inr: '368000',
              eur: '4261',
              usd: '5000',
            },
          },
        },
      ],
    },
    {
      name: 'DevOps',
      data: [
        {
          name: 'Data caching',
          comparision: {
            rs_paygo_standard: 'Up to 24 hours',
            rs_paygo_premium: 'Up to 24 hours',
            rs_paygo_enterprise_a101: 'Up to 24 hours',
          },
        },
        {
          name: 'Dedicated API domain',
          comparision: {
            rs_paygo_standard: 'none',
            rs_paygo_premium: 'none',
            rs_paygo_enterprise_a101: 'none',
          },
        },
        {
          name: 'Access to Beta APIs',
          comparision: {
            rs_paygo_standard: false,
            rs_paygo_premium: false,
            rs_paygo_enterprise_a101: true,
          },
        },
        {
          name: 'Custom APIs',
          comparision: {
            rs_paygo_standard: false,
            rs_paygo_premium: false,
            rs_paygo_enterprise_a101: false,
          },
        },
        {
          name: 'Access to Internal monitoring tools',
          comparision: {
            rs_paygo_standard: false,
            rs_paygo_premium: false,
            rs_paygo_enterprise_a101: true,
          },
        },
        {
          name: 'Usage anomaly detection',
          comparision: {
            rs_paygo_standard: false,
            rs_paygo_premium: false,
            rs_paygo_enterprise_a101: true,
          },
        },
        {
          name: 'Data Push via AWS Event Bridge, AWS SQS',
          comparision: {
            rs_paygo_standard: false,
            rs_paygo_premium: false,
            rs_paygo_enterprise_a101: true,
          },
        },
        {
          name: 'Data CSV push to AWS S3',
          comparision: {
            rs_paygo_standard: false,
            rs_paygo_premium: false,
            rs_paygo_enterprise_a101: true,
          },
        },
      ],
    },
  ];
}
