<template>
  <div class="pricing" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section v-if="section.static_section_id == 'banner'">
          <Banner :banner="section" :sectionType="'resource pricing'" :maxWidth="'max-width-520'"></Banner>
        </section>
        <section v-if="section.static_section_id == 'discount_banner'">
          <div class="rs-container max-width-1080">
            <ContentImageWrap :section="section.detail" :sectionType="'discount'"
              :buttonType="'rs-button yellow-cta white'" :subText="section.name"
              :infoText="formatCurrency(minAmountForDiscount[currency], currency)"/>
          </div>
        </section>
        <section v-if="section.static_section_id == 'section_1'" class="title-section">
          <div class="rs-container">
            <h4 class="pricing-title">{{section.detail.name}}</h4>
            <h4 class="pricing-title">{{section.detail.desc}}</h4>
          </div>
        </section>
        <section  v-if="section.static_section_id == 'endpoints' && resourceLoaded">
          <PlanBasedPricing :resultedResources="resultedResources" :apiUsage="api_usage"
            :resourcePricingTypes="resourcePricingTypes" :currency="currency" :priceDetails="licenseInfo"
            :volumeDiscountInfo="volumeDiscountInfo"
          />
        </section>
        <section class="rs-container table package-based"
          id="package-based"
          v-if="section.static_section_id == 'package_based' && resourceLoaded">
          <div class="content-section">
            <rz-markdown :text="section.detail.name" class="title"></rz-markdown>
            <rz-markdown class="content" :text="section.detail.desc"></rz-markdown>
          </div>
          <PackageBasedPricing :packagePlans="packagePlans" :currency="currency"
           @showPopup="showPopup($event)"/>
        </section>
        <section v-if="section.static_section_id === 'explore_other'">
          <ExploreNewPage :explorePageInfo="section" />
        </section>
        <section class="rs-support-block" v-if="section.static_section_id == 'support_block'">
          <div class="rs-container max-width-1080">
            <support-wrapper />
          </div>
        </section>
        <PaymentSignupSection
          v-if="section.static_section_id == 'singup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
    <div class="popup-wrapper" v-if="openPopup" @click.self="closePopup()">
      <div class="popup" id="popup">
        <div class="popup-header">
          <svg @click="closePopup()"><use v-bind="{'xlink:href':'#close-btn'}"></use></svg>
        </div>
       <PackagePopup :planInfo="planInfo" :currency="currency"/>
      </div>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { minAmountForDiscount, supportNumbers } from '@/store/modules/page';
import { PayGoResources, Package } from '@/store/modules/paygo';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import ExploreNewPage from '@/components/RsCricket/ExploreNewPage.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';
import SupportWrapper from '@/components/RsCricket/SupportWrapper.vue';
import ContentImageWrap from '@/components/RsCricket/ContentImageWrap.vue';
import PlanBasedPricing from '@/components/PlanBasedPricing.vue';
import PackageBasedPricing from '@/components/PackageBasedPricing.vue';
import PackagePopup from '@/components/PackagePopup.vue';
import packageBasedData from '../../../stories/PackageBasedPlans.json';

export default {
  name: 'rsCricketHome',
  components: {
    CricketLoadingPlaceholder,
    Banner,
    PaymentSignupSection,
    SupportWrapper,
    ExploreNewPage,
    ContentImageWrap,
    PlanBasedPricing,
    PackageBasedPricing,
    PackagePopup,
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
      priceDetails() {
        if (this.payGoResources && this.payGoResources.licenses) {
          return this.payGoResources.licenses;
        }
        return 'none';
      },
    }),
  },
  serverPrefetch() {
    return this.fetchData().then(() => {
      return this.fetchResoucePricing().then(() => {
        return this.fetchLicenses();
      });
    });
  },
  data() {
    return {
      resources: null,
      api_usage: null,
      resourceLoaded: false,
      resourcePricingTypes: ['MG100', 'MG101'],
      planTypes: ['standard', 'premium', 'enterprise'],
      perMonthInfo: [],
      resultedResources: {},
      minAmountForDiscount,
      plansInfo: null,
      packageBasedData,
      packagePlans: null,
      openPopup: false,
      licenseInfo: null,
      planInfo: null,
      volumeDiscountInfo: null,
      supportNumbers,
      enterprise: {
        monthly: {
          usd: 299,
          inr: 22599,
          eur: 259,
        },
        annual: {
          usd: 229,
          inr: 16999,
          eur: 199,
        },
      },
    };
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      this.fetchResoucePricing().then(() => {
        return this.fetchLicenses();
      });
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData().then(() => {
        return this.fetchResoucePricing().then(() => {
          return this.fetchLicenses();
        });
      });
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    fetchResoucePricing() {
      return PayGoResources.getResourcePricing(this.$apiInstance, this.$inMemoryCache).then((resp) => {
        this.resources = resp.groupByEndpoints;
        this.volumeDiscountInfo = resp.volumeDiscount;
        this.resourceLoaded = true;
        this.api_usage = resp.usageInfo;
        this.resultedResources = this.resources;
        this.fetchPackagePricing();
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
      });
    },
    fetchPackagePricing() {
      return Package.getPackagePricing(this.$apiInstance, this.$inMemoryCache).then((resp) => {
        this.packagePlans = resp.data.data.response.filter((plan) => plan.show_in_website && plan.active);
      }).catch((err) => {
        console.log('Error on Master Data', err.message);
      });
    },
    fetchLicenses() {
      return PayGoResources.getLicenses(this.$apiInstance).then((resp) => {
        this.licenseInfo = resp;
      }).catch((err) => {
        console.log('fetch Page error', err);
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
    showPopup(plan) {
      this.planInfo = plan;
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'hidden';
    },
    closePopup() {
      this.openPopup = !this.openPopup;
      const bodyElement = document.body;
      bodyElement.style.overflow = 'auto';
    },
  },
};
</script>
<style lang="scss" scoped>
.max-width-440 {
  max-width: size(440);
  @media screen and (min-width: $breakpoint-md) {
    &.pad-100 {
      padding-right: size(100);
    }
  }
}
.pricing {
  font-family: var(--rz-hero-font);
  --cards-border-color: #DADADA;
  .rs-container {
    max-width: size(320);
    padding-bottom: 0;
    &.max-width-1080 {
      max-width: size(1080);
    }
    &.table {
      padding: 0;
      .content {
        max-width: size(600);
        margin: 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: size(1080);
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      max-width: size(700);
    }
    @media screen and (min-width: $breakpoint-lg) {
      max-width: size(900);
    }
  }
  .strike-price {
    &.inline {
      display: block;
    }
  }
  .popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    .popup {
      position: fixed;
      z-index: 1000;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: size(2);
      width: size(300);
      height: size(450);
      overflow: scroll;
      @media screen and (min-width: $breakpoint-md) {
        width: size(680);
        height: auto;
        overflow: auto;
      }
      .popup-header {
        svg {
          position: absolute;
          right: size(20);
          top: size(20);
          width: size(10);
          height: size(10);
          cursor: pointer;
        }
      }
    }
  }
  &-title {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
    color: var(--rs-black-color);
    font-weight: bold;
    text-align: center;
    max-width: size(800);
    margin: 0 auto;
    padding-bottom: size(80);
  }
  .title-section {
    h4 {
      &:first-child {
        padding-bottom: 0;
      }
    }
  }
  &-resource {
    color: var(--ca-title-color);
    position: relative;
    &.search-list {
      &:before {
        display: none;
      }
    }
    .title {
      font-weight: bold;
      font-size: size(28);
      line-height: size(40);
      font-family: var(--hero-font);
      color: var(--ca-title-color);
      padding-bottom: size(25);
      @media screen and (min-width: $breakpoint-lg) {
        font-size: size(36);
        line-height: size(48);
      }
    }
    .duplicate {
      display: none;
    }
    .sub-heading {
      font-weight: bold;
      font-size: size(15);
      line-height: size(21);
      font-weight: bold;
      text-transform: capitalize;
      color: var(--rs-black-color);
      display: block;
      padding-bottom: size(18);
      @media screen and (min-width: $breakpoint-md) {
        border-bottom: size(1) solid var(--cards-border-color);
      }
    }
    .table-view {
      padding-bottom: size(15);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(28);
        &.no-pad {
          padding-bottom: 0;
        }
      }
      &__header, &__row {
        padding: size(24) size(16) size(24) 0;
        margin-bottom: size(25);
        div {
          @media screen and (min-width: $breakpoint-md) {
            border-right: size(1) solid var(--cards-border-color);
          }
          &:nth-child(3),  &:nth-child(4), &:nth-child(5) {
            display: inline-block;
            width: 33%;
            text-align: center;
            padding-top: 1.25rem;
            @media screen and (min-width: $breakpoint-md) {
              text-align: left;
            }
          }
          &:last-child {
            border-right: 0;
          }
        }
        .label {
          font-size: var(--rs-sm-label-font-size);
          line-height: var(--rs-sm-label-line-height);
          font-weight: normal;
        }
        @media screen and (min-width: $breakpoint-md) {
          border-bottom: size(1) solid var(--cards-border-color);
          padding: 0;
          display: flex;
          margin-bottom: 0;
          div {
            flex: 1;
            padding: size(18) size(20);
            &:first-child {
              flex: 1.4;
            }
            &:last-child {
              min-width: 14%;
            }
            &:nth-child(2), :nth-child(3),  &:nth-child(4), &:nth-child(5) {
              width: initial;
            }
          }
          .label {
            font-size: var(--rs-title-level5-font-size);
            line-height: var(--rs-title-level5-line-height);
          }
        }
      }
      &__row {
        background: var(--regular-text-inv);
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
        border-radius: size(10);
        position: relative;
        background: transparent;
        transition: all .3s ease-in-out;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          width: size(12);
          height: size(12);
          background-color: rgba(79, 55, 200, 0.2);
          border-radius: size(10);
          margin-right: size(8);
          left: size(15);
          top: size(29);
          @media screen and (min-width: $breakpoint-md) {
            display: none;
          }
        }
        display: block;
        @media screen and (min-width: $breakpoint-md) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        div {
          &:first-child {
            flex: 0 0 90%;
            padding-left: size(35);
            @media screen and (min-width: $breakpoint-md) {
              min-width: 25%;
              flex: 1.4;
              padding: size(18) 0;
              &:before {
                display: none;
              }
            }
          }
          &.endpoints {
            span {
              display: block;
              padding-top: size(6);
            }
          }
          &.standard, &.premium, &.enterprise {
            padding: size(15) size(35);
            width: 80%;
            @media screen and (min-width: $breakpoint-md) {
              width: initial;
              padding: size(18) size(20);
            }
            p {
              padding-bottom: size(8);
              &:last-child {
                padding-bottom: 0;
              }
              &.plan-name {
                text-align: left;
              }
              &:after {
                content: "";
                clear: both;
                display: block;
              }
              &.plan-name {
                display: block;
                text-transform: capitalize;
                @media screen and (min-width: $breakpoint-md) {
                  display: none;
                }
              }
              & > span {
                display: inline-block;
                &:first-child {
                  float: left;
                  color: #585757;
                  font-weight: 500;
                }
                &:last-child {
                  float: right;
                }
                &.free-card {
                  color: #00A656;
                  padding: size(1) size(8);
                  background: rgba(151, 230, 171, 0.2);
                  border-radius: size(3);
                  font-size: size(12);
                  line-height: size(17);
                  font-weight: 600;
                }
              }
            }
          }
          &.per-month {
            flex: 0 0 90%;
            padding-left: size(35);
            @media screen and (min-width: $breakpoint-md) {
              flex: 2;
              padding: size(15) 0 size(16) size(25);
            }
          }
        }
        @media screen and (min-width: $breakpoint-md) {
          box-shadow: none;
          border-radius: 0;
          justify-content: initial;
          border-bottom: size(1) solid var(--cards-border-color);
        }
      }
      .auth-section {
        .table-view {
          .table-view__inner {
            .table-view__row {
              div {
                &.standard, &.enterprise, &.premium {
                  .price-wrapper {
                    span {
                      &:first-child {
                        display: none;
                        @media screen and (min-width: $breakpoint-md) {
                          display: inline-block;
                        }
                      }
                      &:last-child {
                        float: left;
                        @media screen and (min-width: $breakpoint-md) {
                          float: right;
                        }
                      }
                    }
                  }

                }
              }
            }
          }
        }
      }
      div {
        &.hide {
          .table-view__inner {
            display: none;
          }
          .sub-heading {
            .collapse-icon {
              display: none;
            }
            .expand-icon {
              display: block;
            }
          }
        }
        .sub-heading {
          .expand-icon {
            display: none;
          }
          .collapse-icon {
            font-size: size(28);
            line-height: size(13);
          }
        }
      }
      &__header {
        display: none;
        background: var(--regular-text-inv);
        @media screen and (min-width: $breakpoint-md) {
          display: table;
          width: 100%;
          section {
            display: flex;
            div {
              flex: 1;
              &:first-child {
                flex: 1.4;
              }
              &:first-child {
                min-width: 25%;
                padding-left: 0;
              }
            }
          }
        }
        div {
          border-top: size(1) solid var(--cards-border-color);
          text-align: center;
          &:first-child {
            flex: 0 0 90%;
            padding-left: size(35);
            border-top: none;
            @media screen and (min-width: $breakpoint-md) {
              min-width: 25%;
              flex: 1.4;
              padding: size(18) 0;
              &:before {
                display: none;
              }
            }
          }
          p {
            text-align: center;
            a, span {
              font-size: size(12);
              line-height: size(17);
              font-weight: 500;
              &.name {
                font-size: size(18);
                line-height: size(25);
                color: var(--hero-text);
                font-weight: bold;
                display: block;
                width: 50%;
                margin: 0 auto;
                text-transform: capitalize;
                padding-bottom: size(6);
              }
            }
          }
          &:nth-child(2), &:nth-child(3) {
            border-right: none;
          }
          &:last-child {
            border-right: size(1) solid var(--cards-border-color);
          }
        }
      }
    }
    &.license-info {
      .table-view {
        &__row {
          .endpoints {
            p {
              padding-bottom: 0;
            }
          }
          div {
            &:last-child {
              @media screen and (min-width: $breakpoint-md) {
                border-right: size(1) solid var(--cards-border-color);
              }
            }
            &.label {
              font-size: size(15);
              color: var(--hero-text);
              @media screen and (min-width: $breakpoint-md) {
                font-weight: bold;
                color: var(--rs-light-black-color);
              }
            }
            padding: size(15) size(35);
            width: 80%;
            text-align: left;
            @media screen and (min-width: $breakpoint-md) {
              width: initial;
              padding: size(18) size(20);
            }
            &:first-child {
              padding-top: 0;
              padding-bottom: 0;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(18) 0;
              }
            }
            p {
              padding-bottom: size(8);
              &:last-child {
                padding-bottom: 0;
              }
              &:after {
                content: "";
                clear: both;
                display: block;
              }
              &.plan-name {
                display: block;
                text-transform: capitalize;
                padding-bottom: size(8);
                @media screen and (min-width: $breakpoint-md) {
                  display: none;
                }
              }
              span {
                display: inline-block;
                &:first-child {
                  float: left;
                  color: #585757;
                  font-weight: 500;
                  width: 50%;
                }
                &:last-child {
                  float: right;
                  font-weight: bold;
                }
                &.price {
                  color: #00A656;
                }
              }
            }
          }
        }
      }
    }
    .flex-item {
      padding: size(15) size(20) size(15) 0;
      .rs-sm-para {
        &.black {
          padding-bottom: size(8);
          &:before {
            content: '';
            display: inline-block;
            width: size(10);
            height: size(10);
            background: var(--rs-green-color);
            border-radius: size(15);
            margin-right: size(8);
          }
        }
      }
      @media screen and (min-width: $breakpoint-md) {
        flex: 0 0 size(430);
      }
    }
    .extra-info {
      font-size: size(13);
      line-height: size(18);
      padding: 0 0 size(28);
      max-width: 100%;
      border-bottom: size(1) solid var(--cards-border-color);
      span {
        font-weight: bold;
      }
    }
  }
}
</style>

<style lang="scss">
.pricing {
  #package-based {
    padding-top: 0;
    .title {
      .p {
        @media screen and (min-width: $breakpoint-lg) {
          font-weight: 800;
          font-size: size(80);
          line-height: size(90);
          padding-bottom: size(20)
        }
        strong {
          color: #4F37C8;
        }
      }
    }
  }
  .committed-usage {
    .rs-container {
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(100);
      }
    }
  }
  .content-section {
    max-width: size(550);
    padding: size(30) 0;
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(80);
      padding-bottom: size(40);
    }
    @media screen and (min-width: $breakpoint-lg) {
      padding-top: size(120);
      padding-bottom: size(60);
    }
    .markdown {
      .p {
        font-weight: bold;
        font-size: size(20);
        line-height: size(26);
        padding-bottom: size(10);
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(24);
          line-height: size(32);
        }
        @media screen and (min-width: $breakpoint-lg) {
          font-size: size(30);
          line-height: size(42);
        }
        a {
          color: var(--hero-text);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    .content {
      .p {
        font-size: size(16);
        line-height: size(24);
        font-weight: normal;
        padding-bottom: 0;
        @media screen and (min-width: $breakpoint-md) {
          font-size: size(18);
          line-height: size(28);
        }
        strong {
          font-weight: bold;
        }
      }
    }
    p {
      font-size: size(16);
      line-height: size(24);
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(18);
        line-height: size(28);
      }
    }
  }
}
</style>
