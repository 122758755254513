<template>
  <div class="pricing-resource price-table" v-if="resultedResources && priceDetails && volumeDiscountInfo">
    <!-- <div class="sticky-header" id="sticky-header">
      <div class="rs-container">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                <p class="rs-sm-para bold">Standard</p>
                <TextButton :buttonType="'rs-button black-cta md'" :link="licenseLink('rs_paygo_standard')" />
              </th>
              <th>
                <p class="rs-sm-para bold">Premium</p>
                <TextButton :buttonType="'rs-button black-cta md'" :link="licenseLink('rs_paygo_premium_annual')" />
              </th>
              <th>
                <p class="rs-sm-para bold">Enterprise</p>
                <TextButton :buttonType="'rs-button black-cta md'"
                  :link="licenseLink('rs_paygo_enterprise_a101_annual')" />
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div> -->
    <div class="container rs-container table pricing">
      <table class="license-comparision-table">
        <thead>
          <tr
            class="top"
          >
            <template v-for="plan in priceDetails && priceDetails.licenses_order"
             >
              <th v-if="plan === 'enterprise'"  class="license-wrapper" :class="plan" :key="plan">
                <div>
                  <p class="license-name">Pay as you go</p>
                  <div
                    class="license-amount"
                  >
                    <p v-if="priceDetails.licenses[plan].annual && priceDetails.licenses[plan].annual.price
                      && priceDetails.licenses[plan].annual.price.unit_amount">
                      <span class="annual-price">{{
                        formatCurrency(priceDetails.licenses[plan].annual.price.unit_amount[currency], currency)}}
                      </span>
                      <span>Per month when billed annually</span>
                    </p>
                    <!-- <p v-if="priceDetails.licenses[plan].monthly && priceDetails.licenses[plan].monthly.price
                      && priceDetails.licenses[plan].monthly.price.unit_amount">
                      <span class="monthly-price" :class="plan === 'standard' ? 'annual-price' : ''">{{
                        formatCurrency(priceDetails.licenses[plan].monthly.price.unit_amount[currency], currency)}}
                      </span>
                      <span v-if="plan !== 'standard'">when billed monthly</span>
                    </p> -->
                  </div>
                  <a class="license-info" href="#pricing" @click="scrollToElement('pricing', $event)">
                    Plus API Usage charges
                  </a>
                  <TextButton
                    class="license-cta"
                    :buttonType="'rs-button black-cta lg'"
                    :link="licenseLink('rs_paygo_standard')"
                  />
                </div>
              </th>
            </template>
            <th class="license-wrapper enterprise">
              <div class="image-block">
                <img src="@/assets/enterprise-img.png" />
              </div>
            </th>
          </tr>
          <tr class="bottom">
            <th>
              <TextButton :buttonType="'rs-button black-cta lg'"
                :link="licenseLink('rs_paygo_enterprise_a101_annual')" />
            </th>
            <th></th>
            <!-- <th>
              <TextButton :buttonType="'rs-button black-cta lg'" :link="licenseLink('rs_paygo_standard')" />
            </th>
            <th>
              <TextButton :buttonType="'rs-button black-cta lg'" :link="licenseLink('rs_paygo_premium_annual')" />
            </th> -->
          </tr>
        </thead>
        <tbody id="pricing">
          <tr class="body-data">
            <td class="header-name" data-label>
              <p>{{query ? 'Request' : 'API Request'}}</p>
              <span v-if="apiUsage && apiUsage.unit_label">
                {{apiUsage.unit_label}}</span>
            </td>
            <td
              v-for="(license, index) in licenseTypes"
              :key="index"
              :data-label="license.name"
            >
              <span class="price"
                :class="license.name === 'enterprise' ? 'highlighted-text': ''"
              >{{formatCurrency(apiUsage.normal_price[license.name][currency], currency)}}</span>
            </td>
          </tr>
          <template v-for="(endpoints, resource, index) in resultedResources">
            <tr class="body-header" :class="'level-'+index" v-if="resource !== 'auth'"
              :key="resource">
              <td data-label>{{ resource }} {{query ? 'Queries': 'Endpoints' }}</td>
              <td data-label></td>
              <td data-label></td>
              <td data-label></td>
            </tr>
            <template v-for="api in endpoints">
              <tr class="body-data" :key="api.name" v-if="resource">
                <td class="header-name" data-label>
                  <p class="rs-sm-para" v-if="query">
                    {{api.name}} Query
                  </p>
                  <template v-else>
                    <router-link class="rs-sm-para blue"  v-if="api.name !== 'Match Live Odds'"
                      :to="{name: 'rsResourcePage', params: { api: api.resourceKey.split('_').join('-') }}">
                      {{api.name}} API
                    </router-link>
                    <router-link class="rs-sm-para blue"  v-else
                      :to="{name: 'rsResourcePage', params: { api: 'live-match-odds' }}">
                      {{api.name}} API
                    </router-link>
                  </template>
                  <span>{{api.starts_at.unit_label}}</span>
                </td>
                <td
                  v-for="(license, index) in licenseTypes"
                  :key="index"
                  :data-label="license.name"
                >
                  <span
                    v-if="api.detail.MG100.price.normal_price[license.name] &&
                    api.detail.MG100.price.normal_price[license.name][currency] > 0"
                    class="price"
                  >
                    {{formatCurrency(api.detail.MG100.price.normal_price[license.name][currency], currency)}}
                  </span>
                  <span v-else class="free-card" :class="license.name">Free</span>
                </td>
              </tr>
            </template>
          </template>
          <tr class="body-header volume-discount">
            <td data-label colspan="4">
              <span>Volume Discounts</span>
              <p v-if="query">
                Discount on Match Query, Fantasy Match Points Query, Fantasy Match Credits Query.</p>
              <p v-else>
                Discount on
                <router-link
                  :to="{name: 'rsResourcePage', params: { api: 'match' }}">
                  Match API,
                </router-link>
                <router-link
                  :to="{name: 'rsResourcePage', params: { api: 'fantasy-match-points' }}">
                  Fantasy Match Points API,
                </router-link>
                <router-link
                  :to="{name: 'rsResourcePage', params: { api: 'fantasy-match-credits' }}">
                  Fantasy Match Credits API.
                </router-link>
                <router-link
                  :to="{name: 'rsResourcePage', params: { api: 'match-via-push' }}">
                  Match Via Push API,
                </router-link>
                <router-link
                  :to="{name: 'rsResourcePage', params: { api: 'fantasy-match-points-via-push' }}">
                  Fantasy Match Points Via Push API.
                </router-link>
              </p>
            </td>
            <td data-label></td>
            <td data-label></td>
            <td data-label></td>
          </tr>
          <template v-for="(discountInfo, index) in volumeDiscountInfo">
            <tr class="body-data volume-discount" :key="index">
              <td class="header-name" data-label>
                {{discountInfo[0]}} Matches
                <span class="unit-label">Per Month</span>
              </td>
              <td
                v-for="(license, i) in licenseTypes"
                :key="i"
                :data-label="license.name"
              >
                <span v-if="discountInfo[1][license.name]">{{discountInfo[1][license.name]}}%</span>
                <span v-else>-</span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="license-features-block">
      <div class="container rs-container table">
        <table  class="license-comparision-table">
          <tbody class="license-features">
            <template v-for="(licenseHeader, mainIndex) in licenseInfo">
              <tr class="body-header" :class="'level-'+mainIndex" :key="licenseHeader.name">
                <td data-label>{{ licenseHeader.name }}</td>
                <td data-label></td>
              </tr>
              <template v-for="(licenseAccessInfo) in licenseHeader.data">
                <tr class="body-data" :key="licenseAccessInfo.name">
                  <td class="header-name" data-label>{{ licenseAccessInfo.name }}</td>
                  <td
                    v-for="(license, index) in licenseTypes"
                    :key="index"
                    :data-label="license.name"
                    :class="{
                      'available': (licenseAccessInfo.comparision[license.id] === true),
                      'unavailable': (licenseAccessInfo.comparision[license.id] === false),
                      'none': (licenseAccessInfo.comparision[license.id] === 'none') }"
                  >
                    <span
                      v-if="licenseAccessInfo.comparision[license.id] !== true
                      && licenseAccessInfo.comparision[license.id] !== false
                      && licenseAccessInfo.comparision[license.id] !== 'none'"
                      :class="{'enterprise':
                      (license.id !== 'rs_paygo_standard')}"
                    >
                      <template v-if="licenseAccessInfo.comparision[license.id][currency]">
                        {{ licenseAccessInfo.comparision[license.id].desc }}
                        {{ formatCurrency(licenseAccessInfo.comparision[license.id][currency], currency) }}
                      </template>
                      <template v-else-if="licenseAccessInfo.comparision[license.id] >= 0">
                        {{ licenseAccessInfo.comparision[license.id].toLocaleString() }}
                      </template>
                      <template v-else>
                        {{ licenseAccessInfo.comparision[license.id]}}
                      </template>
                    </span>
                    <span class="hidden"
                      :class="{'enterprise':
                      (license.id !== 'rs_paygo_standard')}" v-else>none
                    </span>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';
import { licenseInfo } from '../../stories/LicenseInfo';


export default {
  props: {
    apiUsage: Object,
    resultedResources: Object,
    currency: String,
    query: Boolean,
    priceDetails: Object,
    volumeDiscountInfo: Array,
  },
  components: {
    TextButton,
  },
  data() {
    return {
      licenseInfo: licenseInfo(),
      licenseTypes: [
        // {
        //   id: 'rs_paygo_standard',
        //   name: 'standard',
        // },
        // {
        //   id: 'rs_paygo_premium',
        //   name: 'premium',
        // },
        {
          id: 'rs_paygo_enterprise_a101',
          name: 'enterprise',
        },
      ],
    };
  },
  methods: {
    licenseLink(licenseKey) {
      return {
        name: 'Get Started',
        routeParams: {
          name: 'projectCreation',
          query: { license: licenseKey },
        },
      };
    },
    scrollToElement(elementId, event) {
      if (event) {
        event.preventDefault();
      }
      document.getElementById(elementId).scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
<style lang="scss" scoped>
  .pricing-resource {
    --rs-license-border-color: #dadada;
    --rs-license-z-index-0: 0;
    --rs-license-z-index-1: 1;
    --rs-license-z-index-2: 2;
    --rs-license-z-index-3: 3;
    --rs-license-z-index-4: 4;
    --rs-license-black-color: #181818;
    --rs-solution-pad-horizontal: #{size(20)};
    scroll-behavior: smooth;


    color: var(--ca-title-color);
    position: relative;

    .sticky-header {
      display: none;
      @media screen and (min-width: $breakpoint-md) {
        position: sticky;
        top: size(65);
        left: 0;
        right: 0;
        background: var(--rs-white-color);
        border-bottom: size(1) solid var(--rs-license-border-color);
        text-align: center;
        .rs-container {
          padding-top: size(25);
          padding-bottom: size(5);
        }
        z-index: -1;
        display: block;
        opacity: 0;
        height: 0;
        transition: opacity 0.3s ease;
        &.add-header {
          z-index: 2;
          opacity: 1;
          height: size(130);
        }
      }
    }
    .license-features-block {
      .rs-container {
        max-width: size(720);

        .license-comparison-table {
          max-width: size(720);
          width: auto;
        }
      }
    }

    table {
      border-collapse: collapse;
      margin: 0 auto;
      width: 100%;
      max-width: size(720);
      table-layout: fixed;
      padding: 0 size(15);
      margin-bottom: size(18);
      scroll-behavior: smooth;
      thead {
        tr {
          border: none;
          display: flex;
          flex-wrap: wrap;
          @media screen and (min-width: $breakpoint-md) {
            display: table-row;
          }
          &.top {
            @media screen and (min-width: $breakpoint-md) {
              padding-bottom: 0;
              th {
                padding-bottom: 0;
              }
            }
            th {
              &:first-child {
                font-weight: bold;
              }
            }
          }
          th {
            background: var(--rs-white-color);
            z-index: var(--senior-menu-z-index);
            margin: size(20) 0;
            border: size(3) solid var(--rs-license-border-color);
            min-width: 89%;
            border-radius: size(6);
            &:first-child {
              min-width: 0;
            }
            @media screen and (min-width: $breakpoint-md) {
              margin: unset;
              border: unset;
              min-width: unset;
            }
            &:last-child {
              display: none;
              @media screen and (min-width: $breakpoint-md) {
                display: table-cell;
              }
            }
            &.license-wrapper {
              &.standard {
                .image-block {
                  background: #ED90B2;
                }
              }
              &.premium {
                .image-block {
                  background: #97E6AB;
                }
              }
              &.enterprise {
                .image-block {
                  background: #A695FF;
                }
                > div {
                  max-width: size(250);
                  & > a {
                    font-size: size(12);
                    line-height: size(17);
                    font-weight: 500;
                    margin-bottom: size(2);
                  }
                }
              }
              & > a {
                font-size: size(12);
                line-height: size(17);
                font-weight: 500;
                margin-bottom: size(2);
              }
              .license-name {
                font-weight: bold;
                text-transform: capitalize;
                margin-bottom: size(20);
              }
              .image-block {
                height: size(80);
                border-radius: size(6);
                margin-bottom: size(20);
                position: relative;
                @media screen and (min-width: $breakpoint-md) {
                  height: size(100);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  height: size(120);
                }
                img {
                  position: absolute;
                  display: inline-block;
                  height: size(120);
                  top: size(-40);
                  @media screen and (min-width: $breakpoint-md) {
                    height: size(140);
                    top: size(-40);
                    right: 0;
                  }
                  @media screen and (min-width: $breakpoint-lg) {
                    height: size(180);
                    top: size(-60);
                    right: 0;
                  }
                }
              }
            }
          }
          .license {
            &-name {
              font-size: var(--rs-title-level5-font-size);
              line-height: var(--rs-title-level5-line-height);
              color: var(--rs-light-black-color);
              margin-bottom: size(10);
            }
            &-amount {
              padding-bottom: size(10);
              @media screen and (min-width: $breakpoint-md) {
                min-height: auto;
              }
              p {
                font-size: size(12);
                line-height: size(17);
                span {
                  font-size: size(12);
                  line-height: size(17);
                  color: var(--rs-black-color);
                  &.annual-price {
                    font-size: size(20);
                    line-height: size(24);
                    color: var(--ca-title-color);
                    padding-bottom: size(4);
                    font-weight: bold;
                    display: block;
                  }
                  &.monthly-price {
                    font-weight: 600;
                  }
                }
              }
            }
            &-info {
              font-size: var(--rs-title-level5-font-size);
              line-height: var(--rs-title-level5-line-height);
              color: var(--rz-link-color);
            }
            &-alt-amount {
              font-size: var(--rs-title-level5-font-size);
              line-height: var(--rs-title-level5-line-height);
              color: var(--rs-light-black-color);
              margin-bottom: size(6);
            }
            &-c1x {
              font-size: var(--rs-title-level2-font-size);
              line-height: var(--rs-title-level2-line-height);
              color: var(--rs-light-black-color);
              font-weight: bold;
            }
            &-cta {
              display: block;
              @media screen and (min-width: $breakpoint-md) {
                display: none;
              }
            }
          }
          &.bottom {
            display: none;
            @media screen and (min-width: $breakpoint-md) {
              display: table-row;
            }
            th {
              padding-top: 0;
              padding-bottom: 0;
              > div {
                max-width: size(250);
              }
            }
          }
        }
      }
      tbody {
        z-index: var(--goto-top-z-index);
        tr {
          &:first-child {
            td {
              &:first-child {
                @media screen and (min-width: $breakpoint-md) {
                  padding-top: size(50);
                }
              }
            }
          }
          &.body-header {
            border: none;
            text-decoration: underline;
            padding: 0;
            @media screen and (min-width: $breakpoint-md) {
              border-bottom: size(1) solid var(--rs-license-border-color);
              padding: unset;
              text-decoration: none;
            }
            &.volume-discount {
              td {
                span {
                  color: #00A656;
                  font-weight: bold;
                }
                p {
                  padding-top: size(8);
                  font-weight: normal;
                  color: #585757;
                  text-transform: capitalize;
                  max-width: 100%;
                  a {
                    color: #585757;
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
            td {
              padding: 0;
              font-size: size(12);
              line-height: size(17);
              font-weight: bold;
              letter-spacing: 0.1em;
              text-transform: uppercase;
              color: #777777;
              text-align: left;
              border: none;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(40) 0 size(18);
              }
              &:not(:first-child) {
                display: none;
                @media screen and (min-width: $breakpoint-md) {
                  display: table-cell;
                }
              }
            }
            &.level-0 {
              z-index: var(--rs-license-z-index-0);
            }
            &.level-1 {
              z-index: var(--rs-license-z-index-1);
            }
            &.level-2 {
              z-index: var(--rs-license-z-index-2);
            }
            &.level-3 {
              z-index: var(--rs-license-z-index-3);
            }
            &.level-4 {
              z-index: var(--rs-license-z-index-4);
            }
          }
          &.body-data {
            &.volume-discount {
              td {
                font-weight: bold;
                span {
                  &.unit-label {
                    font-weight: normal;
                  }
                }
              }
            }
            &:last-child {
              border: size(3) solid var(--rs-license-border-color);
              border-radius: size(6);
              @media screen and (min-width: $breakpoint-md) {
                border: none;
              }
            }
            td:last-child {
              &.available,
              &.none,
              &.unavailable {
                background-position: 90% 50%;
                @media screen and (min-width: $breakpoint-md) {
                  background-position: 100%;
                }
              }
            }
            .available {
              background-image: url(../assets/rsCricket/correct.svg);
              background-repeat: no-repeat;
              background-position: 90% 50%;
              @media screen and (min-width: $breakpoint-md) {
                background-position: 100%;
              }
            }
            .unavailable {
              background-image: url(../assets/rsCricket/wrong.svg);
              background-repeat: no-repeat;
              background-position: 90% 50%;
              @media screen and (min-width: $breakpoint-md) {
                 background-position: 100%;
              }
            }
            .none {
              background-image: url(../assets/rsCricket/none.svg);
              background-repeat: no-repeat;
              background-position: 90% 50%;
              @media screen and (min-width: $breakpoint-md) {
                background-position: 100%;
              }
            }
          }
          td {
            font-size: size(15);
            line-height: size(21);
            color: #181818;
            text-align: right;
            @media screen and (min-width: $breakpoint-md) {
              text-align: left;
            }
            &.header-name {
              color: var(--rs-light-black-color);
              text-align: left;
              font-size: size(15);
              line-height: size(21);
              color: #121212;
              @media screen and (min-width: $breakpoint-md) {
                padding: size(12) 0;
                font-size: size(15);
                line-height: size(21);
                padding-right: size(20);
              }
              span {
                display: block;
                padding-top: size(4);
                font-size: size(14);
                line-height: size(18);
                color: #585757;
                max-width: size(250);
              }
            }
            .free-card {
              padding: size(1) size(8);
              border-radius: size(3);
              font-size: size(12);
              line-height: size(17);
              font-weight: 600;
            }
            .price {
              display: inline-block;
              vertical-align: middle;
              &.highlighted-text {
                color: #8A73FF;
                font-weight: bold;
              }
            }
            .standard {
              &.free-card {
                color: #de6893;
                background: rgb(237, 144, 178, 0.2);
              }
            }
            .premium {
              &.free-card {
                color: #00A656;
                background: rgba(151, 230, 171, 0.2);
              }
            }
            .enterprise {
              &.free-card {
                color: #8A73FF;
                background: rgba(166, 149, 255, 0.2);
              }
            }
            .hidden {
              visibility: hidden;
              &.enterprise {
                min-height: size(42);
                display: block;
                @media screen and (min-width: $breakpoint-md) {
                  min-height: unset;
                  display: unset;
                }
              }
            }
            .enterprise {
              min-height: unset;
            }
          }
          span {
            display: inline-block;
            max-width: size(150);
            @media screen and (min-width: $breakpoint-md) {
              display: unset;
            }
          }
        }
        .btn-group {
          border-bottom: none;
          display: none;
          @media screen and (min-width: $breakpoint-md) {
            display: table-row;
          }
        }
      }
      tr {
        border: size(3) solid var(--rs-license-border-color);
        border-radius: size(6);
        padding: size(18) 0;
        margin: size(15) 0;
        display: block;
        @media screen and (min-width: $breakpoint-md) {
          border: none;
          border-bottom: size(1) solid var(--rs-license-border-color);
          display: table-row;
        }
        th,
        td {
          text-align: left;
          padding: size(15);
          width: 90%;
          vertical-align: middle;
          @media screen and (min-width: $breakpoint-md) {
            padding: 0;
            width: 30%;
          }
          &:not(:last-child) {
            padding-right: size(15);
            @media screen and (min-width: $breakpoint-md) {
              padding-right: size(30);
            }
          }
          &:first-child {
            width: 90%;
            @media screen and (min-width: $breakpoint-md) {
              width: 70%;
            }
          }
          &:last-child {
            width: 90%;
            @media screen and (min-width: $breakpoint-md) {
              width: 30%;
              text-align: right;
            }
          }
        }
        td {
          display: block;
          width: 90%;
          &::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
            word-break: break-all;
            width: size(100);
            text-align: left;
            &:first-child {
              content: '';
            }
          }
          &:last-child {
            border-bottom: 0;
          }
          @media screen and (min-width: $breakpoint-md) {
            display: table-cell;
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .pricing {
      &.rs-container {
        padding-bottom: size(30);
      }
    }
    .license-features-block {
      background: #111111;
      .rs-container {
        padding-top: size(20);
      }
      table {
        tbody {
          tr {
            @media screen and (min-width: $breakpoint-md) {
              border-bottom: size(1) solid #3A3A3A;
            }
            td {
              color: var(--rs-white-color);
              &:not(:first-child) {
                width: 90%;
                @media screen and (min-width: $breakpoint-md) {
                  width: 30%;
                  padding-top: size(18);
                  padding-bottom: size(18);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(270);
                  padding-top: size(20);
                  padding-bottom: size(25);
                }
              }
              &:last-child {
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(210);
                }
              }
              &:first-child {
                width: 90%;
                @media screen and (min-width: $breakpoint-md) {
                  width: 85%;
                  padding-right: size(30);
                }
                @media screen and (min-width: $breakpoint-lg) {
                  width: size(270);
                  padding-right: size(215);
                }
              }
            }
            &.body-header {
              td {
                padding-top: size(40);
                padding-bottom: size(18);
                color: #777777;
              }
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
.price-table {
  .rs-button {
    &.black-cta {
      > a {
        margin-top: size(4);
      }
    }
  }
}
</style>
