<template>
  <div>
    <div v-if="blog">
      <div class="content max-width-600 button-wrapper">
        <TextButton class="blog-btn" :buttonType="'rs-button'" :link="blogRoute" />
      </div>
      <div class="more-blogs">
        <h2 class="title">More Stories</h2>
        <div class="blog-list" v-if="blogListStatus">
          <template v-for="(blog, index) in blogList.blogs">
            <div class="list-wrap" :key="index" v-if="index !== blogList.length - 1">
              <div class="list" :class="'list'+index">
                <router-link
                  :to="{
                  name: 'rsBlogDetailView',
                  params: {blogid: blog.route_value.replace('rs-cricket-', '')}
                }"
                >
                  <div class="image-wrap" v-if="blog.card_img && blog.card_img.url">
                    <rz-image-item :image="blog.card_img" />
                  </div>
                  <div class="image-wrap" v-else>
                    <img class="image-item" src="@/assets/blog-placeholder.png" alt="gallery" />
                  </div>
                  <div class="title" v-if="blog.category">{{ blog.category}}</div>
                  <div class="desc" v-if="blog.card_title">{{ blog.card_title }}</div>
                  <div
                    class="date"
                    v-if="blog.publication_date"
                  >{{ formatBlogPublicationDate(blog.publication_date)}}</div>
                </router-link>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <section class="rs-common-bottom">
      <section class="payment-wrapper">
        <div class="rs-container">
          <div class="rs-flex-box space-between">
            <h3>SECURED PAYMENT GATEWAYS</h3>
            <div class="image-wrap">
              <img src="@/assets/rsCricket/payment_gateways.png" alt="Payment Methods" />
            </div>
          </div>
        </div>
      </section>
      <section class="singup-wrapper">
        <div class="rs-container">
          <div class="rs-flex-box space-between">
            <h4>{{signupInfo.detail.name}}</h4>
            <div class="flex-item">
              <div>
                <div class="hero-links" v-if="signupInfo.detail.links.length">
                  <template v-for="(link,index) in signupInfo.detail.links">
                    <TextButton
                      :buttonType="index == 0 ? 'rs-button yellow-cta' : 'rs-button white-cta'"
                      :key="index"
                      :link="link"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>
<style lang="scss" scoped>
.rs-flex-box {
  display: flex;
  flex-wrap: wrap;
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
  @media screen and (min-width: $breakpoint-md) {
    &.no-wrap {
      flex-wrap: initial;
    }
  }
}
.max-width-600 {
  max-width: size(600);
  margin: auto;
  text-align: center;
}
.button-wrapper {
  text-align: center;
  padding: size(26) 0 size(50);
  margin: size(40) size(20) 0;
  border-top: size(1) dashed var(--rs-gray-color);
  @media screen and (min-width: $breakpoint-md) {
    margin: size(40) auto 0;
    padding: size(26) 0 size(80);
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(26) 0 size(100);
  }
}
.more-blogs {
  max-width: size(1080);
  margin: auto;
  padding: 0 size(20) 0;
  @media screen and (min-width: $breakpoint-lg) {
    padding-bottom: size(70);
  }
  .title {
    color: var(--rs-black-color)
  }
  .list-wrap {
    border: none;
  }
  & > .title {
    padding-bottom: size(20);
    border-bottom: size(1) dashed var(--rs-gray-color);
    font-size: var(--rs-solution-para-font-size);
    line-height: var(--rs-solution-para-line-height);
  }
  .list-wrap {
    & {
      @include headerColorVariant(
        '.title',
        #b02907,
        #ff931e,
        #0751b9,
      );
    }
  }
}

.rs-container {
  max-width: size(1080);
  margin: 0 auto;
  padding: size(50) size(20);

  &.pad-130 {
    padding-top: size(130);
  }
  &.pad-100 {
    padding-top: size(100);
  }
  @media screen and (min-width: $breakpoint-lg) {
    padding: size(50) 0;
  }
}

.rs-common-bottom {
  .singup-wrapper {
    background: var(--rs-dark-blue-color);
    .rs-container {
      padding: size(65) size(20);
    }
    h4 {
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
      font-weight: bold;
      color: var(--rs-white-color);
    }
    .rs-flex-box {
        flex-direction: column;
      }
    @media screen and (min-width: $breakpoint-md) {
      h4 {
        flex: 0 0 53%;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      .rs-container {
        padding: size(65) size(20);
      }
      .rs-flex-box {
        flex-direction: row;
      }
    }
  }
  .payment-wrapper {
    background: #e0e2ff;
    margin-top: size(50);
    .rs-container {
      padding: size(28) size(20);
      .image-wrap {
        max-width: size(360);
        height: size(28);
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    h3 {
      font-size: var(--rs-title-level4-font-size);
      line-height: size(30);
      font-weight: bold;
      text-transform: uppercase;
      color: var(--rs-dark-blue-color);
      padding-bottom: size(30);
      padding-top: 0;
    }
    @media screen and (min-width: $breakpoint-md) {
      h3 {
        padding-bottom: 0;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      .rs-container {
        padding: size(28) 0;
      }
    }
  }
}
</style>
<script>
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  name: 'PaymentSignupSection',
  props: {
    signupInfo: {
      type: Object,
    },
    blog: Boolean,
    blogListStatus: Boolean,
    blogList: Object,
  },
  components: {
    TextButton,
  },
  data() {
    return {
      blogRoute: {
        routeParams: { name: 'rsBlogList' },
        name: 'View All Blogs',
      },
    };
  },
};
</script>
