import { render, staticRenderFns } from "./ArticleTwoColSec.vue?vue&type=template&id=6333c3b8&scoped=true&"
import script from "./ArticleTwoColSec.vue?vue&type=script&lang=js&"
export * from "./ArticleTwoColSec.vue?vue&type=script&lang=js&"
import style0 from "./ArticleTwoColSec.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ArticleTwoColSec.vue?vue&type=style&index=1&id=6333c3b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6333c3b8",
  null
  
)

export default component.exports