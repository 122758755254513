<template>
  <div class="contact-page" v-if="pageState.status.isLoaded && page">
    <div v-if="page.sections && page.sections.length">
      <section v-for="(section, index) in page.sections" :key="index">
        <section v-if="section.static_section_id == 'banner'">
          <Banner :banner="section" :sectionType="'resource'" :maxWidth="'max-width-520'"></Banner>
        </section>
        <section v-if="section.static_section_id == 'help-text'" :class="section.static_section_id">
          <div class="container" v-if="section.detail.items">
            <h4>{{section.detail.desc}}</h4>
            <div v-for="info in section.detail.items" :key="info.key">
              <svg><use v-bind="{'xlink:href':'#check-mark-icon'}"></use></svg>
              <rz-markdown :text="info.desc"></rz-markdown>
            </div>
          </div>
          <ContactForm />
        </section>
        <div class="image-wrap" v-if="section.static_section_id == 'customer-logos'">
          <rz-image-item  v-if="section.detail.img" :image="section.detail.img" />
        </div>
        <PaymentSignupSection
          v-if="section.static_section_id == 'signup_wrapper'"
          :signupInfo="section"
        />
      </section>
    </div>
  </div>
  <div class="content placeholder" v-else>
    <CricketLoadingPlaceholder :state="pageState"/>
  </div>
</template>
<script>

import { allMetaMixins } from '@/mixin/meta';
import { mapState } from 'vuex';
import ContactForm from '@/components/ContactForm.vue';
import CricketLoadingPlaceholder from '@/components/SportsHome/CricketLoadingPlaceholder.vue';
import Banner from '@/components/RsCricket/Banner.vue';
import PaymentSignupSection from '@/components/RsCricket/PaymentSignupSection.vue';


export default {
  mixins: allMetaMixins(),

  title() {
    return this.$route.params ? this.$route.params.page : 'Roanuz Sports';
  },

  components: {
    ContactForm,
    Banner,
    PaymentSignupSection,
    CricketLoadingPlaceholder,
  },

  serverPrefetch() {
    return this.fetchData();
  },
  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      pageState: (state) => state.page.activePageState,
      page: (state) => state.page.activePage,
      currency: (state) => state.user.activeCurrency,
      rzFooterLinks: (state) => state.link.rzFooterLinks,
      rsFooterLinks: (state) => state.link.rsFooterLinks,
    }),
  },
  mounted() {
    console.log('this.dataLoaded', this.dataLoaded);
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value === `rs-${this.$route.meta.routeValue}`;
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
        return;
      }
    }

    if (!this.dataLoaded) {
      this.freshFetch();
    } else {
      this.fetchData();
    }
  },
  methods: {
    freshFetch() {
      return this.fetchData();
    },
    fetchData() {
      return this.fetchMenus().then(() => {
        const route = '/rs/pages/';
        const routeValue = `rs-${this.$route.meta.routeValue}`;
        return this.$store.dispatch('page/fetchActivePage', { route, routeValue });
      });
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.freshFetch();
    },
    fetchMenus() {
      return this.$store.dispatch('link/fetchRsFooter');
    },
  },
};
</script>
<style lang="scss">
.contact-page {
  .solution-banner {
    .container {
      @media screen and (min-width: $breakpoint-md) {
        display: inline-block;
        max-width: size(420);
        margin-left: 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        display: block;
        max-width: size(1080);
        margin-left: auto;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.contact-page {
  font-family: var(--rz-hero-font);
  color: var(--rs-black-color);
  .contact-form {
    position: absolute;
    right: 0;
    left: 0;
    top: size(310);
    margin: 0 size(20);
    @media screen and (min-width: $breakpoint-md) {
      left: auto;
      right: 0;
      transform: translateY(-80%);
      top: 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
      right: 0;
      transform: translateY(-70%);
    }
  }
  .image-wrap {
    max-width: size(1000);
    margin: 0 auto;
  }
  .help-text {
    max-width: size(1080);
    margin: 0 auto;
    margin-top: size(280);
    padding: size(20);
    @media screen and (min-width: $breakpoint-md) {
      padding: size(20);
      margin-bottom: size(70);
      margin-top: 0;
      position: relative;
    }
    .container {
      @media screen and (min-width: $breakpoint-md) {
        display: inline-block;
        width: 50%;
      }
      h4 {
        font-weight: bold;
        font-size: size(24);
        line-height: size(34);
        margin-bottom: size(20);
        color: var(--rs-black-color);
      }
      svg {
        display: inline-block;
        vertical-align: top;
        margin-right: size(10);
        margin-top: size(5);
        width: size(18);
        height: size(18);
      }
      .markdown {
        display: inline-block;
        font-weight: normal;
        width: 90%;
        font-size: size(18);
        line-height: size(28);
        padding-bottom: size(15);
        color: var(--rs-black-color);
      }
    }
  }
}
</style>
